.component--search-results {
    @extend %clear;
    margin-top: 0;

	@include respond-to(xs-max) {
		padding-top: 24px;
	}

	.empty & {
		width: 100%;
	}

	&-main {
		display: none;
	}
}

.search-results {
	padding-top: 18px;

	@include respond-to(sm-min) {
		float: left;
		width: 100%;
	}

	&__info {
		padding: 8px 0;

		@include respond-to(sm-min) {
			float: left;
			padding-top: 36px;
		}
	}
	
	&__sort {
		padding-top: 16px;

		@include respond-to(sm-min) {
			float: right;
			padding-top: 36px;
		}

		.tabs__dropdown {
			width: auto;

			select {
				border-bottom: none;
			}
		}
	}

	&__pagination {
		@include respond-to(sm-min) {
			float: left;
			width: 100%;
		}
	}

	&__other {
		@include respond-to(sm-min) {
			float: left;
			width: 100%;
		}
	}

	&__items {
		padding: $gutter-xs 0;
	}

	&__item {
		padding: ($gutter-xs / 2) 0;

		a {
			display: block;
			background-color: $color-01;
			color: $color-03;
			text-decoration: none;
			padding: 0 $gutter-xs;
			position: relative;

			&:after {
				@include respond-to(sm-min) {
	                content: '';   
	                position: absolute;
	                bottom: 0;
	                left: 0; 
	                width: 0;
	                height: 2px;
	                background-color: $color-06;
	        		@include transition(width .3s);
				}
			}

			@include respond-to(sm-min) {
				padding: $gutter-xs ($gutter-xs * 2);
			}

			&:hover,
			&:focus {
				&:after {
					@include respond-to(sm-min) {
		                width: 100%;
					}

				}
			}
		}

		span {
			font-weight: bold;
		}
	}

	&__link {
		color: $color-11;
		font-size: $font-size-mobile;
		line-height: $font-leading-mobile;
		padding: 10px 0;
		word-break: break-all;

        @include respond-to(xs-max) {
			display: none;
		}
	}

	&__title {
		padding-top: $gutter-xs;

		@include respond-to(sm-min) {
			padding: $gutter-xs 0;
		}
	}

	&__text {
		padding: $gutter-xs 0;

	}

	&__other {

		ul {
			padding: 16px 0 0 0;
			margin: 0;
			list-style-type: none;

	        @include respond-to(md-min) {
				padding: 24px 0 0 0;
			}
		}

		li {
			a {
	            font-size: $lead-mobile;
	            line-height: $lead-mobile-leading;
	            font-style: italic;
	            color: $color-03;
	            padding: 8px 0;
	            text-decoration: none;
	            display: inline-block;

		        @include respond-to(md-min) {
		            font-size: $lead-desktop;
		            line-height: $lead-desktop-leading;
		        }

		        &:hover,
		        &:focus {
			        @include respond-to(sm-min) {
			        	color: $color-05;
			        }
		        }

		        span {
		        	font-style: normal;
		        	font-weight: bold;
		        }
			}
		}
	}
	
}