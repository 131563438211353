// =============================================================================
// Grid.scss based on Bootstrap
// grid.css v3.2.0 | Free License | getbootstrap.com/customize/
// =============================================================================

// ------------------------------------
//  Variables:
// ------------------------------------

// define container widths here
$container-xs: 100%;
$container-sm: 740px;
$container-md: 960px;
$container-lg: 1140px;
$container-xxl: 1440px;

// define gutter widths here
$gutter: 10px;
$gutter-xs: 16px;
$gutter-sm: $gutter-xs / 2;

// define column widths here
$col-01: 8.33333333%;
$col-02: 16.66666667%;
$col-03: 25%;
$col-04: 33.33333333%;
$col-05: 41.66666667%;
$col-06: 50%;
$col-07: 58.33333333%;
$col-08: 66.66666667%;
$col-09: 75%;
$col-10: 83.33333333%;
$col-11: 91.66666667%;
$col-12: 100%;

// Computed widths of single columns
$col-sm-1: ($container-sm / 12);
$col-md-1: ($container-md / 12);
$col-lg-1: ($container-lg / 12);

$col-xxl-1: ($container-xxl / 12);

// ------------------------------------
// Placeholders:
// ------------------------------------

%collapse-xs {
	@include respond-to(xs-max) {
		padding-left: 0;
		padding-right: 0;
	}
}

// --------------------------------------------
// Column - common styles
// --------------------------------------------
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
	min-height: 1px; // TODO: remove?
	padding-left: $gutter-xs;
	padding-right: $gutter-xs;

	@include respond-to(sm-min) {
		padding-left: $gutter-sm;
		padding-right: $gutter-sm;
	}

	&.col-offset {
		&:first-child {
            @include respond-to(lg-min) {
                padding-right: $col-lg-1 + $gutter-sm;
            }

            @include respond-to(xxl-min) {
                padding-right: $col-xxl-1 + $gutter-sm;
            }
		}

		&:last-child {
            @include respond-to(lg-min) {
                padding-left: $col-lg-1 + $gutter-sm;
            }

            @include respond-to(xxl-min) {
                padding-left: $col-xxl-1 + $gutter-sm;
            }			
		}

		&.col-collapse {
			padding-left: 0;
			padding-right: 0;

			@include respond-to(sm-min) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&.col-collapse {
		padding-left: 0;
		padding-right: 0;

		@include respond-to(sm-min) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.component--row {
	[class^='col-sm-']:not(:first-child),
	[class^='col-md-']:not(:first-child)
	[class^='col-lg-']:not(:first-child) {
		@include respond-to(xs-max) { margin-top: $vertical-gutter-mobile; }
	}

	[class^='col-md-']:not([class^='col-sm-']):not(:first-child) {
		@include respond-to(sm-min) { margin-top: $vertical-gutter-mobile; }
	}

	[class^='col-lg-']:not([class^='col-md-']):not([class^='col-sm-']):not(:first-child) {
		@include respond-to(md-max) { margin-top: $vertical-gutter-mobile; }
	}
}

// NOTE: when .col-* is after a hidden .col-*
[class^='col-'] {

	.hidden-xs + &,
	.hidden-sm + &,
	.hidden-md + &,
	.hidden-lg + & {
		.component--row & {
			margin-top: 0;
		}
	}
}

// --------------------------------------------
// Column - extra small
// --------------------------------------------
// Mobile / Phablets

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
	float: left;
}

// specific width column
.col-xs-1 { 	width: $col-01; }
.col-xs-2 { 	width: $col-02; }
.col-xs-3 { 	width: $col-03; }
.col-xs-4 { 	width: $col-04; }
.col-xs-5 { 	width: $col-05; }
.col-xs-6 { 	width: $col-06; }
.col-xs-7 { 	width: $col-07; }
.col-xs-8 { 	width: $col-08; }
.col-xs-9 { 	width: $col-09; }
.col-xs-10 { 	width: $col-10; }
.col-xs-11 { 	width: $col-11; }
.col-xs-12 { 	width: $col-12; }

// left-side offsetted column
.col-xs-offset-0 {	margin-left: 0; }
.col-xs-offset-1 {	margin-left: $col-01; }
.col-xs-offset-2 {	margin-left: $col-02; }
.col-xs-offset-3 {	margin-left: $col-03; }
.col-xs-offset-4 {	margin-left: $col-04; }
.col-xs-offset-5 {	margin-left: $col-05; }
.col-xs-offset-6 {	margin-left: $col-06; }
.col-xs-offset-7 {	margin-left: $col-07; }
.col-xs-offset-8 {	margin-left: $col-08; }
.col-xs-offset-9 {	margin-left: $col-09; }
.col-xs-offset-10 { margin-left: $col-10; }
.col-xs-offset-11 { margin-left: $col-11; }
.col-xs-offset-12 { margin-left: $col-12; }

// left-side & right-side pulled column
.col-xs-pull-0 { 	right: auto; }
.col-xs-pull-1 { 	right: $col-01; }
.col-xs-pull-2 { 	right: $col-02; }
.col-xs-pull-3 { 	right: $col-03; }
.col-xs-pull-4 { 	right: $col-04; }
.col-xs-pull-5 { 	right: $col-05; }
.col-xs-pull-6 { 	right: $col-06; }
.col-xs-pull-7 { 	right: $col-07; }
.col-xs-pull-8 { 	right: $col-08; }
.col-xs-pull-9 { 	right: $col-09; }
.col-xs-pull-10 { 	right: $col-10; }
.col-xs-pull-11 { 	right: $col-11; }
.col-xs-pull-12 { 	right: $col-12; }

// left-side & right-side pushed column
.col-xs-push-0 { 	left: auto; }
.col-xs-push-1 { 	left: $col-01; }
.col-xs-push-2 { 	left: $col-02; }
.col-xs-push-3 { 	left: $col-03; }
.col-xs-push-4 { 	left: $col-04; }
.col-xs-push-5 { 	left: $col-05; }
.col-xs-push-6 { 	left: $col-06; }
.col-xs-push-7 { 	left: $col-07; }
.col-xs-push-8 { 	left: $col-08; }
.col-xs-push-9 { 	left: $col-09; }
.col-xs-push-10 { 	left: $col-10; }
.col-xs-push-11 { 	left: $col-11; }
.col-xs-push-12 { 	left: $col-12; }


// --------------------------------------------
// Column - small
// --------------------------------------------
// Small column are used for Tablet Portrait

@include respond-to(sm-min) {

	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}

	// specific width column
	.col-sm-1 { 	width: $col-01; }
	.col-sm-2 { 	width: $col-02; }
	.col-sm-3 { 	width: $col-03; }
	.col-sm-4 { 	width: $col-04; }
	.col-sm-5 { 	width: $col-05; }
	.col-sm-6 { 	width: $col-06; }
	.col-sm-7 { 	width: $col-07; }
	.col-sm-8 { 	width: $col-08; }
	.col-sm-9 { 	width: $col-09; }
	.col-sm-10 { 	width: $col-10; }
	.col-sm-11 { 	width: $col-11; }
	.col-sm-12 { 	width: $col-12; }

	// left-side offsetted column
	.col-sm-offset-0 { 	margin-left: 0; }
	.col-sm-offset-1 { 	margin-left: $col-01; }
	.col-sm-offset-2 { 	margin-left: $col-02; }
	.col-sm-offset-3 { 	margin-left: $col-03; }
	.col-sm-offset-4 { 	margin-left: $col-04; }
	.col-sm-offset-5 { 	margin-left: $col-05; }
	.col-sm-offset-6 { 	margin-left: $col-06; }
	.col-sm-offset-7 { 	margin-left: $col-07; }
	.col-sm-offset-8 { 	margin-left: $col-08; }
	.col-sm-offset-9 { 	margin-left: $col-09; }
	.col-sm-offset-10 { margin-left: $col-10; }
	.col-sm-offset-11 { margin-left: $col-11; }
	.col-sm-offset-12 { margin-left: $col-12; }

	// left-side & right-side pulled column
	.col-sm-pull-0 { 	right: auto; }
	.col-sm-pull-1 { 	right: $col-01; }
	.col-sm-pull-2 { 	right: $col-02; }
	.col-sm-pull-3 { 	right: $col-03; }
	.col-sm-pull-4 { 	right: $col-04; }
	.col-sm-pull-5 { 	right: $col-05; }
	.col-sm-pull-6 { 	right: $col-06; }
	.col-sm-pull-7 { 	right: $col-07; }
	.col-sm-pull-8 { 	right: $col-08; }
	.col-sm-pull-9 { 	right: $col-09; }
	.col-sm-pull-10 { 	right: $col-10; }
	.col-sm-pull-11 { 	right: $col-11; }
	.col-sm-pull-12 { 	right: $col-12; }

	// left-side & right-side pushed column
	.col-sm-push-0 { 	left: auto; }
	.col-sm-push-1 { 	left: $col-01; }
	.col-sm-push-2 { 	left: $col-02; }
	.col-sm-push-3 { 	left: $col-03; }
	.col-sm-push-4 { 	left: $col-04; }
	.col-sm-push-5 { 	left: $col-05; }
	.col-sm-push-6 { 	left: $col-06; }
	.col-sm-push-7 { 	left: $col-07; }
	.col-sm-push-8 { 	left: $col-08; }
	.col-sm-push-9 { 	left: $col-09; }
	.col-sm-push-10 { 	left: $col-10; }
	.col-sm-push-11 { 	left: $col-11; }
	.col-sm-push-12 { 	left: $col-12; }

}

// --------------------------------------------
// Column - medium
// --------------------------------------------
// Meduim column are used for Tablet Landscape / Medium Desktop Screens

@include respond-to(md-min) {

	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
		float: left;
	}

	// specific width column
	.col-md-1 { 	width: $col-01; }
	.col-md-2 { 	width: $col-02; }
	.col-md-3 { 	width: $col-03; }
	.col-md-4 { 	width: $col-04; }
	.col-md-5 { 	width: $col-05; }
	.col-md-6 { 	width: $col-06; }
	.col-md-7 { 	width: $col-07; }
	.col-md-8 { 	width: $col-08; }
	.col-md-9 { 	width: $col-09; }
	.col-md-10 { 	width: $col-10; }
	.col-md-11 { 	width: $col-11; }
	.col-md-12 { 	width: $col-12; }

	// left-side offsetted column
	.col-md-offset-0 { 	margin-left: 0%; }
	.col-md-offset-1 { 	margin-left: $col-01; }
	.col-md-offset-2 { 	margin-left: $col-02; }
	.col-md-offset-3 { 	margin-left: $col-03; }
	.col-md-offset-4 { 	margin-left: $col-04; }
	.col-md-offset-5 { 	margin-left: $col-05; }
	.col-md-offset-6 { 	margin-left: $col-06; }
	.col-md-offset-7 { 	margin-left: $col-07; }
	.col-md-offset-8 { 	margin-left: $col-08; }
	.col-md-offset-9 { 	margin-left: $col-09; }
	.col-md-offset-10 { margin-left: $col-10; }
	.col-md-offset-11 { margin-left: $col-11; }
	.col-md-offset-12 { margin-left: $col-12; }

	// left-side & right-side pulled column
	.col-md-pull-0 { 	right: auto; }
	.col-md-pull-1 { 	right: $col-01; }
	.col-md-pull-2 { 	right: $col-02; }
	.col-md-pull-3 { 	right: $col-03; }
	.col-md-pull-4 { 	right: $col-04; }
	.col-md-pull-5 { 	right: $col-05; }
	.col-md-pull-6 { 	right: $col-06; }
	.col-md-pull-7 { 	right: $col-07; }
	.col-md-pull-8 { 	right: $col-08; }
	.col-md-pull-9 { 	right: $col-09; }
	.col-md-pull-10 { 	right: $col-10; }
	.col-md-pull-11 { 	right: $col-11; }
	.col-md-pull-12 { 	right: $col-12; }

	// left-side & right-side pushed column
	.col-md-push-0 { 	left: auto; }
	.col-md-push-1 { 	left: $col-01; }
	.col-md-push-2 { 	left: $col-02; }
	.col-md-push-3 { 	left: $col-03; }
	.col-md-push-4 { 	left: $col-04; }
	.col-md-push-5 { 	left: $col-05; }
	.col-md-push-6 { 	left: $col-06; }
	.col-md-push-7 { 	left: $col-07; }
	.col-md-push-8 { 	left: $col-08; }
	.col-md-push-9 { 	left: $col-09; }
	.col-md-push-10 { 	left: $col-10; }
	.col-md-push-11 { 	left: $col-11; }
	.col-md-push-12 { 	left: $col-12; }
}

// --------------------------------------------
// Column - large
// --------------------------------------------
// Large column are used for Large Desktop Screens

@include respond-to(lg-min) {

	.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
		float: left;
	}

	// specific width column
	.col-lg-1 { 	width: $col-01; }
	.col-lg-2 { 	width: $col-02; }
	.col-lg-3 { 	width: $col-03; }
	.col-lg-4 { 	width: $col-04; }
	.col-lg-5 { 	width: $col-05; }
	.col-lg-6 { 	width: $col-06; }
	.col-lg-7 { 	width: $col-07; }
	.col-lg-8 { 	width: $col-08; }
	.col-lg-9 { 	width: $col-09; }
	.col-lg-10 { 	width: $col-10; }
	.col-lg-11 { 	width: $col-11; }
	.col-lg-12 { 	width: $col-12; }

	// left-side offsetted column
	.col-lg-offset-0 { 	margin-left: 0; }
	.col-lg-offset-1 { 	margin-left: $col-01; }
	.col-lg-offset-2 { 	margin-left: $col-02; }
	.col-lg-offset-3 { 	margin-left: $col-03; }
	.col-lg-offset-4 { 	margin-left: $col-04; }
	.col-lg-offset-5 { 	margin-left: $col-05; }
	.col-lg-offset-6 { 	margin-left: $col-06; }
	.col-lg-offset-7 { 	margin-left: $col-07; }
	.col-lg-offset-8 { 	margin-left: $col-08; }
	.col-lg-offset-9 { 	margin-left: $col-09; }
	.col-lg-offset-10 { margin-left: $col-10; }
	.col-lg-offset-11 { margin-left: $col-11; }
	.col-lg-offset-12 { margin-left: $col-12; }

	// left-side & right-side pulled column
	.col-lg-pull-0 { 	right: auto; }
	.col-lg-pull-1 { 	right: $col-01; }
	.col-lg-pull-2 { 	right: $col-02; }
	.col-lg-pull-3 { 	right: $col-03; }
	.col-lg-pull-4 { 	right: $col-04; }
	.col-lg-pull-5 { 	right: $col-05; }
	.col-lg-pull-6 { 	right: $col-06; }
	.col-lg-pull-7 { 	right: $col-07; }
	.col-lg-pull-8 { 	right: $col-08; }
	.col-lg-pull-9 { 	right: $col-09; }
	.col-lg-pull-10 { 	right: $col-10; }
	.col-lg-pull-11 { 	right: $col-11; }
	.col-lg-pull-12 { 	right: $col-12; }

	// left-side & right-side pushed column
	.col-lg-push-0 { 	left: auto; }
	.col-lg-push-1 { 	left: $col-01; }
	.col-lg-push-2 { 	left: $col-02; }
	.col-lg-push-3 { 	left: $col-03; }
	.col-lg-push-4 { 	left: $col-04; }
	.col-lg-push-5 { 	left: $col-05; }
	.col-lg-push-6 { 	left: $col-06; }
	.col-lg-push-7 { 	left: $col-07; }
	.col-lg-push-8 { 	left: $col-08; }
	.col-lg-push-9 { 	left: $col-09; }
	.col-lg-push-10 { 	left: $col-10; }
	.col-lg-push-11 { 	left: $col-11; }
	.col-lg-push-12 { 	left: $col-12;}

}

// --------------------------------------------
// Container
// --------------------------------------------
// TODO: Add position: relative to container?
.container {
	// height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: $gutter-xs;
	padding-right: $gutter-xs;
	width: $container-xs;

	@include respond-to(sm-min) {
		padding-left: $gutter-sm;
		padding-right: $gutter-sm;
		width: $container-sm;
	}

	@include respond-to(md-min) { width: $container-md; }

	@include respond-to(lg-min) { width: $container-lg; }

	@include respond-to(xxl-min) { width: $container-xxl; }

	&.collapse {
		padding-left: 0;
		padding-right: 0;

		&-xs {
			@extend %collapse-xs;
		}
	}


}

// --------------------------------------------
// Row - common styles
// --------------------------------------------
.row {
	// overflow: hidden; // TODO: remove?

	&:before,
	&:after {
		content: '';
		display: table;
	}

    &:after {
		clear: both;
    }

	// NOTE: ROW gets -ve margin only if nested inside .container or .col-*
	.container &,
	[class^='col-'] & {

		margin-left: -$gutter-xs;
		margin-right: -$gutter-xs;

		@include respond-to(sm-min) {
			margin-left: -$gutter-sm;
			margin-right: -$gutter-sm;
		}
	}

	// Table modifier
	// --------------------------------------------
	&-table {
		@include respond-to(sm-min) {
			display: table;
			height: 100%;
			table-layout: fixed;
			width: 100%;
		}

		.container & {
			@include respond-to(sm-min) {
				width: 100%;
				width: calc(100% + #{$gutter-sm * 2});
			}
		}

		// NOTE: if row is 'collapesd' don't need to use calc
		&.collapse {
			.container & {
				@include respond-to(sm-min) { width: 100%; }
			}
		}


		&-xs {
			@include respond-to(xs-max) {
				display: table;
				height: 100%;
				table-layout: fixed;
				width: 100%;
			}

			.container & {
				@include respond-to(xs-max) { width: calc(100% + #{$gutter-xs * 2}); }
			}
		}

		&-md {
			@include respond-to(md-min) {
				display: table;
				height: 100%;
				table-layout: fixed;
				width: 100%;
			}

			.container & {
				@include respond-to(md-min) { width: calc(100% + #{$gutter-sm * 2}); }
			}
		}
	}

	// Collapse: remove gutters
	// --------------------------------------------
	&.collapse {
		margin-left:  0;
		margin-right: 0;

		[class^='col-'] {
			padding-left:  0;
			padding-right: 0;

			&.col-offset {
                padding-left: $gutter-xs;
                padding-right: $gutter-xs;
                padding-top: 24px;
                padding-bottom: 24px;

	            @include respond-to(sm-min) {
	                padding-left: $gutter-xs * 2;
	                padding-right: $gutter-xs * 2;
	            }

	            @include respond-to(lg-min) {
	                padding-left: $col-lg-1 + $gutter-sm;
	                padding-right: $col-lg-1 + $gutter-sm;
	            }

	            @include respond-to(xxl-min) {
	                padding-left: $col-xxl-1 + $gutter-sm;
	                padding-right: $col-xxl-1 + $gutter-sm;
	            }	
			}

			// NOTE: if rows have collapse class
			// sibling columns won't get the margin top
			+ [class^='col-'] {
				&:not(:first-child) { // TODO: remove ':not' selector
					@include respond-to(xs-max) { margin-top: 0; }
				}
			}
		}

		&-xs {
			@include respond-to(xs-max) {
				margin-left: 0;
				margin-right: 0;
			}

			[class^='col-'] {
				@include respond-to(xs-max) {
					padding-left:  0;
					padding-right: 0;
				}
			}
		}

		&-sm {
			@include respond-to(sm-min) {
				margin-left: 0;
				margin-right: 0;
			}

			[class^='col-'] {
				@include respond-to(sm-min) {
					padding-left:  0;
					padding-right: 0;
				}
			}
		}

		&-md {
			@include respond-to(md-min) {
				margin-left: 0;
				margin-right: 0;
			}

			[class^='col-'] {
				@include respond-to(md-min) {
					padding-left:  0;
					padding-right: 0;
				}
			}
		}

		&-lg {
			@include respond-to(lg-min) {
				margin-left: 0;
				margin-right: 0;
			}

			[class^='col-'] {
				@include respond-to(lg-min) {
					padding-left:  0;
					padding-right: 0;
				}
			}
		}

		&-xl {
			@include respond-to(xl-min) {
				margin-left: 0;
				margin-right: 0;
			}

			[class^='col-'] {
				@include respond-to(xl-min) {
					padding-left:  0;
					padding-right: 0;
				}
			}
		}
	}

	// Uncollapse: add gutters
	// --------------------------------------------
	&.uncollapse {

		&-xs {
			@include respond-to(xs-max) {
				margin-left:  -$gutter-xs;
				margin-right: -$gutter-xs;
			}

			[class^='col-'] {
				@include respond-to(xs-max) {
					padding-left:  $gutter-xs;
					padding-right: $gutter-xs;
				}
			}
		}

		&-sm {
			@include respond-to(sm-min) {
				margin-left:  -$gutter-sm;
				margin-right: -$gutter-sm;
			}

			[class^='col-'] {
				@include respond-to(sm-min) {
					padding-left:  $gutter-sm;
					padding-right: $gutter-sm;
				}
			}
		}

		&-md {
			@include respond-to(md-min) {
				margin-left:  -$gutter-sm;
				margin-right: -$gutter-sm;
			}

			[class^='col-'] {
				@include respond-to(md-min) {
					padding-left:  $gutter-sm;
					padding-right: $gutter-sm;
				}
			}
		}

		&-lg {
			@include respond-to(lg-min) {
				margin-left:  -$gutter-sm;
				margin-right: -$gutter-sm;
			}

			[class^='col-'] {
				@include respond-to(lg-min) {
					padding-left:  $gutter-sm;
					padding-right: $gutter-sm;
				}
			}
		}

		&-xl {
			@include respond-to(xl-min) {
				margin-left:  -$gutter-sm;
				margin-right: -$gutter-sm;
			}

			[class^='col-'] {
				@include respond-to(xl-min) {
					padding-left:  $gutter-sm;
					padding-right: $gutter-sm;
				}
			}
		}
	}
}

// Table modifier column alignemnt
// --------------------------------------------
.col-top,
.col-middle,
.col-bottom {
	.row-table & {
		@include respond-to(sm-min) {
			float: none;
			display: table-cell;
			height: 100%;
		}
	}

	.row-table-xs & {
		&[class^='col-xs-'] {
			@include respond-to(xs-max) {
				float: none;
				display: table-cell;
				height: 100%;
			}
		}
	}

	.row-table-md & {
		@include respond-to(md-min) {
			float: none;
			display: table-cell;
		}
	}
}

.col-top 	{ vertical-align: top; }
.col-middle { vertical-align: middle; }
.col-bottom { vertical-align: bottom; }


// --------------------------------------------
// Images
// --------------------------------------------
img {
	[class^='col-'] & {
		display: block;
		max-width: 100%;
	}
}
