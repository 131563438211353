// =============================================================================
// Video
// =============================================================================

$vid-btn: 96px;
$vid-btn-mobile: 72px;

$vid-caption-height: $font-leading-mobile + 16px;


.general-overlay {
    @include respond-to(md-min) {
        overflow: hidden;
        padding: 32px;
        // width: 32.791519%; // NOTE: had made an assumption that the overlay would be a %, BUT, the width of the video in Zeplin is not correct, thus..
        width: calc(50% - #{$vid-btn});

        position: absolute;
        right: 32px;
        bottom: 32px + $vid-caption-height;
    }

    @include respond-to(xxl-min) {
        width: 464px;

        right: 64px;
        bottom: 64px + $vid-caption-height;
    }

    @include respond-to(sm-max) {
        width: 100%;
    }

    &__title {
        font-size: $h2-desktop;
        line-height: $h2-desktop-leading;

        @include respond-to(sm-max) {
            margin-top: 24px;
        }

        .general-overlay--quote & {
            @extend %font-secondary;
        }

        .general-overlay--message & {
            @extend %font-primary-medium;
        }
    }

    &__subtitle {
        margin-top: 16px;

        .general-overlay--quote & {
            font-size: $font-size-mobile;
            line-height: $font-leading-mobile;
        }

        .general-overlay--message & {
            font-size: $h2-mobile;
            line-height: $h2-mobile-leading;
        }
    }

    &__content {
        font-family: $font-family-secondary;

        @include respond-to(md-min) {
            font-size: $h2-mobile;
            line-height: $h2-mobile-leading;
        }

        .general-overlay--quote & {
            margin: 16px 0 0;

            @include respond-to(md-min) {
                margin: 48px 0 0;
            }

            &:before { content: open-quote; }

            &:after { content: close-quote; }
        }

        .general-overlay--message & {
            margin: 24px 0 0;

            @include respond-to(md-min) {
                margin: 32px 0 0;
            }
        }
    }

    &--quote {
        @include respond-to(md-min) {
            background: $color-04;
        }
    }

    &.bg--crimson {
        @include respond-to(md-min) {
            color: $color-01;
        }

        @include respond-to(sm-max) {
            background-color: transparent;
        }
    }
}

.video {
    position: relative;
    margin: 0;

    &__contents {
        position: relative;
        height: 0;
        padding-bottom: 56.25%; // NOTE: 16:9 ratio

        .template--news-detail & {
            @include respond-to(xs-max) {
                margin-left: -$gutter-xs;
                margin-right: -$gutter-xs;
            }
        }
    }

    &__frame,
    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 0;
    }

    &__poster {
        background-size: cover;
        background-position: center;
    }

    &__play {
        background-color: rgba($color-03, .8);
        border: 0;
        // border-radius: 50%;
        height: $vid-btn-mobile;
        margin-top: -($vid-btn-mobile / 2);
        margin-left: -($vid-btn-mobile / 2);
        padding: 0;
        width: $vid-btn-mobile;

        position: absolute;
        top: 50%;
        left: 50%;

        @include respond-to(sm-min) {
            height: $vid-btn;
            margin-top: -($vid-btn / 2);
            margin-left: -($vid-btn / 2);
            width: $vid-btn;
        }

        .video__contents:hover &,
        &:hover {
            background-color: $color-06;

            &:after {
                border-left-color: $color-03;
            }
        }

        // @include transform(translate(-50%, -50%));

        .video__poster--you-tube {
            opacity: 0;
        }

        &:after {
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 18px solid $color-01;
            content: '';
            display: block;
            height: 0;
            // margin-left: 3px;
            width: 0;

            position: absolute;
            left: 50%;
            top: 50%;

            @include transform(translate(-50%, -50%));
        }
    }

    &__play {
        @include transition(all .3s);
    }

    &__frame,
    &__frame--you-tube.b-lazy.b-loaded {
        opacity: 0;
        visibility: hidden;
        @include transition(opacity 0.3s);
    }

    &__caption {
        font-size: $font-size-mobile;
        line-height: $font-leading-mobile;
        margin: 8px 0 0;
        max-width: $container-lg;

        @include respond-to(md-min) {
            margin: 16px 0 0;
        }

        .component--row & {
            color: $color-11;
            @include respond-to(sm-min) { padding: 0; }
        }
    }

    &__overlay {
        .container & {
            @include respond-to(sm-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    // State modifiers.
    &--playing {
        .video__frame--native,
        .video__frame--you-tube.b-lazy.b-loaded {
            opacity: 1;
        }
    }

    &--ready,
    &--playing {
        .video__frame--native,
        .video__frame--you-tube.b-lazy.b-loaded {
            visibility: visible;
        }

        .video__play {
            opacity: 1;
        }
    }

    &--buffering,
    &--playing {
        .video__play {
            margin-top: -100px;
            opacity: 0;
            @include transform-origin(50% 50%);
            @include transform(translate(-50%, -50%) rotateZ(18deg));
        }

        .video__overlay {
            @include respond-to(md-min) {
                opacity: 0;
            }
        }
    }
}
