.page-404 {
	background-color: $color-03;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&__wrapper {
		background: rgba($color-03, .7);
		height: 100vh;
		width: 100%;
		min-height: 250px;
	}

	.container {
		position: relative;
		height: 100vh;
	}

	.row {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 250px;
		margin: -125px 0 0 0;
	}

	&__message{
		@extend %font-primary-light;
		font-size: $h3-mobile;
		line-height: $h3-mobile-leading;
		color: $color-01;

		@include respond-to(sm-min){
			font-size: $h3-desktop;
			line-height: $h3-desktop-leading;
		}

	    a {
	        display: inline-block;
	        text-decoration: underline;
	        position: relative;
	        color: $color-06;

	        &:visited {
	            color: $color-06;
	        }

	        &:focus,
	        &:hover {
	            color: $color-01;

	            &:after {
	                width: 100%;
	            }
	        }

	        span {
	            position: relative;
	            z-index: 2;
	        }

	        &:after {
	            @extend %hover;
	            background-color: $color-06;
	            content: '';
	            height: 100%;
	            width: 0;
	            position: absolute;
	            z-index: 1;
	            left: 0;
	            top: 0;
	        }
	    }


		h1 {
			margin: 0;
			@extend %font-primary-light;
			font-size: $h3-mobile;
			line-height: $h3-mobile-leading;
			color: $color-01;
			text-transform: none;

			@include respond-to(sm-min){
				font-size: $h3-desktop;
				line-height: $h3-desktop-leading;
			}
		}

		p {
			margin: 0;

			@include respond-to(xs-max){
				padding-top: $vertical-gutter-mobile;
			}
		}
	}

	&__search{
		padding-top: $vertical-gutter-mobile * 3;

		input{
			&[type='text']{
				background: transparent;
				border: none;
				border-bottom: 4px solid $color-01;
				font-family: $font-family-secondary;
				font-size: $h2-desktop;
				line-height: $h2-desktop-leading;
				width: 100%;
				height: $h2-desktop-leading + 20;
				color: $color-01;
				padding: 8px ($h2-desktop-leading + 20) 8px 0;

				@include respond-to(sm-min){
					font-size: $h1-desktop;
					line-height: $h1-desktop-leading;
					padding: 16px ($h1-desktop-leading + 36) 16px 0;
					height: $h1-desktop-leading + 36;
				}
			}

			&[type='submit']{
				position: absolute;
				bottom: 0;
				right: 0;
				background: transparent;
				border: none;
				width: $h2-desktop-leading + 20;
				height: $h2-desktop-leading + 20;
				-webkit-appearance: none;
			    -moz-appearance: none;
				appearance: none;  
				text-indent: -9999px;
				padding: 0; 

				@include respond-to(sm-min){
					width: $h1-desktop-leading + 36;
					height: $h1-desktop-leading + 36;
				}
			}
		}

		.fc {
			&__label {
				top: 32px;
				left: 0;
				font-family: $font-family-secondary;
				font-size: $h2-desktop;
				line-height: $h2-desktop-leading;
				color: $color-01;

				@include respond-to(sm-min){
					top: 40px;
					font-size: $h1-desktop;
					line-height: $h1-desktop-leading;
					height: $h1-desktop-leading;
				}
			}

			&--focus,
			&--active {
				.fc__label {
					font-family: $font-family-primary;
				    color: $color-01;
				    font-size: 14px;
				    line-height: 16px;
				    text-transform: uppercase;
				    top: 0;
				}
			}

			&__submit {
				position: relative;

				&:before {
					position: absolute;
					bottom: 0;
					right: 0;
					font-size: 24px;
					line-height: $h2-desktop-leading + 20;
					color: $color-01;

					@include respond-to(sm-min){
						font-size: 48px;
						line-height: $h1-desktop-leading + 36;
					}
				}
			}
		}



	}
}