// =============================================================================
// Facilities Detail
// =============================================================================

// fd shortform used for 'facilities detail' class name to keep it small
.fd {

    &__title,
    &__address,
    &__phone {
        @extend %has-icon;
        border-top: 1px solid $color-09;
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;
    }

    &__title {
        font-weight: normal;
        margin: 0;
    }

    &__hours {
        margin-bottom: 20px;
        line-height: $lead-desktop-leading;
        width: 100%;

        tr {
            &.fd__row--current {
                font-weight: bold;
            }
        }

        td {
            &:nth-child(1) {
                padding-left: 25px;
                width: 55%;
            }

            &:nth-child(2) {
                text-align: right;
                width: 45%;
            }
        }
    }

    &__address {
        font-style: normal;
    }

    &__phone {
        color: $color-03;

        a {
            color: $color-03;

            &:hover {
                color: $color-05;
                text-decoration: none;
            }
        }
    }
}

