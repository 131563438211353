// =============================================================================
// Main Banner
// =============================================================================

$main-banner-spacing: 32px;
$main-banner-height-mobile: 460px;
$main-banner-height-desktop: 560px;
$main-banner-height-large-desktop: 660px;

.main-banner {
    position: relative;

    &__item {
        width: 100%;
        height: calc(100vh - 57px);
        min-height: $main-banner-height-mobile;
        position: relative;
        overflow: hidden;
            
        @include respond-to(md-min) {
            min-height: $main-banner-height-desktop;
            height: calc(100vh - 113px);
        }

        .main-banner-75 & {
            height: calc(75vh - 57px);
                
            @include respond-to(md-min) {
                height: calc(75vh - 113px);
            }            
        }

        .main-banner-fixed & {
            height: $main-banner-height-mobile;
                
            @include respond-to(md-min) {
                height: $main-banner-height-desktop;
            }             
                    
            @include respond-to(lg-min) {
                height: $main-banner-height-large-desktop;
            }        
        }        

        &-video {
            background: $color-03;
            position: absolute;
            top: 0; 
            right: 0; 
            bottom: 0; 
            left: 0;
            pointer-events: none;
            overflow: hidden;
                
            @include respond-to(xs-max) {
                display: none;
            }   
                
            @include respond-to(lg-max) {
                 .touch-device & {
                    display: none;
                }
            }   

            &-wrapper {
                pointer-events: none;
                position: absolute;
                margin: auto;
                min-width: 100vw;
                min-height: 56vw;
                width: calc((100vh - 57px) * 1.8);
                height: calc(100vh - 57px);
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                    
                @include respond-to(md-min) {
                    width: calc((100vh - 113px) * 1.8);
                    height: calc(100vh - 113px);
                }

                .main-banner-75 & {
                    width: calc((75vh - 57px) * 1.8);
                    height: calc(75vh - 57px);
                        
                    @include respond-to(md-min) {
                        width: calc((75vh - 113px) * 1.8);
                        height: calc(75vh - 113px);
                    }            
                }

                .main-banner-fixed & {
                    width: $main-banner-height-mobile * 1.8;
                    height: $main-banner-height-mobile;
                        
                    @include respond-to(md-min) {
                        height: $main-banner-height-desktop * 1.8;
                        height: $main-banner-height-desktop;
                    }    
                        
                    @include respond-to(lg-min) {
                        height: $main-banner-height-large-desktop * 1.8;
                        height: $main-banner-height-large-desktop;
                    }           
                }    
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            .touch-device & {
                display: none;
            }
        }

        &-background {
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-wrapper {
            background-color: rgba($color-03, 0.7);
            width: 100%;
            min-height: $main-banner-height-mobile;
            height: calc(100vh - 57px);
            position: relative;
            overflow: hidden;
            
            @include respond-to(md-min) {
                min-height: $main-banner-height-desktop;
                height: calc(100vh - 113px);
            }

            .main-banner-75 & {
                height: calc(75vh - 57px);
                    
                @include respond-to(md-min) {
                    height: calc(75vh - 113px);
                }            
            }

            .main-banner-fixed & {
                height: $main-banner-height-mobile;
                    
                @include respond-to(md-min) {
                    height: $main-banner-height-desktop;
                }          
                    
                @include respond-to(lg-min) {
                    height: $main-banner-height-large-desktop;
                }       
            }   
        }

        &-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            color: $color-01;
            min-height: $main-banner-height-mobile;
            height: calc(100vh - 57px);
            @include transition(all .3s);
            
            @include respond-to(sm-min) {
                width: $container-sm;
                margin: 0 auto;
                padding: 0 $gutter-xs / 2;
            }
            
            @include respond-to(md-min) {
                min-height: $main-banner-height-desktop;
                height: calc(100vh - 113px);
                width: $container-md;
            }
            
            @include respond-to(lg-min) {
                width: $container-lg;
            }
            
            @include respond-to(xxl-min) {
                width: $container-xxl;
            }

            &.active {
                min-height: 60px;
                height: 60px;

                .search {
                    padding-top: 0;
                }

                .h1 {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .main-banner-75 & { 
                min-height: $main-banner-height-mobile - 48;
                height: calc(75vh - 57px);
                    
                @include respond-to(md-min) {
                    min-height: $main-banner-height-desktop - 48;
                    height: calc(75vh - 113px);
                }            
            }
            
            .main-banner-fixed & {
                height: $main-banner-height-mobile;
                    
                @include respond-to(md-min) {
                    height: $main-banner-height-desktop;
                }          
                    
                @include respond-to(lg-min) {
                    height: $main-banner-height-large-desktop;
                }           
            }   
        }

        &-search {
            width: 100%;
            padding: 0 $gutter-xs;
            padding-top: $main-banner-spacing;
            @include transition(padding-top .3s);

            @include respond-to(xxxs-max) {
                padding-top: ($main-banner-spacing / 2);
            }
                    
            @include respond-to(sm-min) {
                padding: 0;
                padding-top: $main-banner-spacing * 2
            }   
        }

        .h1 {
            text-align: center;
            padding: 0 $gutter-xs;
            font-size: $h1-desktop;
            line-height: $h1-desktop-leading;
            @include transition(opacity .3s, visibility .3s);

            @include respond-to(xxxs-max) {
                font-size: $h1-mobile;
                line-height: $h1-mobile-leading;
            }

            @include respond-to(sm-min) {
                max-width: $col-sm-1 * 8;
                padding: 0;
            }

            @include respond-to(md-min) {
                max-width: $col-md-1 * 8;
            }

            @include respond-to(lg-min) {
                font-size: $h0-desktop;
                line-height: 104px;
                max-width: $col-lg-1 * 8;
            }

            @include respond-to(xxl-min) {
                max-width: $col-xxl-1 * 8;
            }
        }

        .h3 {
            text-align: center;
            font-weight: 300;
            margin-top: $main-banner-spacing;
            padding: 0 $gutter-xs;

            @include respond-to(xxxs-max) {
                font-size: $h4-mobile;
                line-height: $h4-mobile-leading;
                margin-top: ($main-banner-spacing / 2);
            }

            @include respond-to(sm-min) {
                max-width: $col-sm-1 * 6;
                padding: 0;
            }

            @include respond-to(md-min) {
                max-width: $col-md-1 * 6;
                font-size: $h3-mobile;
                line-height: $h3-mobile-leading;
            }

            @include respond-to(lg-min) {
                margin-top: $main-banner-spacing * 2;
                max-width: $col-lg-1 * 6;
                font-size: $h3-desktop;
                line-height: $h3-desktop-leading;
            }

            @include respond-to(xxl-min) {
                max-width: $col-xxl-1 * 6;
            }
        }

        .button {
            margin-top: $main-banner-spacing;
            margin-left: $gutter-xs;
            margin-right: $gutter-xs;

            @include respond-to(xxxs-max) {
                margin-top: ($main-banner-spacing / 2);
            }

            @include respond-to(sm-min) {
                margin-left: 0;
                margin-right: 0;
            }

            @include respond-to(lg-min) {
                margin-top: $main-banner-spacing * 2;
            }

            &__wrap {
                padding: 0;
            }
        }

        .component {
            margin: 0;
        }

        &-left {
            .main-banner__item-content {
                align-items: stretch;
            }

            .h1,
            .h3 {
                text-align: left;
            }   
        }
    }

    &__scroll {
        position: absolute;
        width: 48px;
        height: 48px;
        bottom: 0;
        left: 50%;
        margin-left: -24px;

        a {
            display: block;
            width: 48px;
            height: 48px;
            padding-bottom: 24px;
            text-align: center;
            text-decoration: none;
            color: $color-01;
            font-size: 24px;

            &:hover {
                color: $color-06;
            }
        }

        .main-banner-75 & {
            display: none;           
        }
    }

    .slick-dots {
        position: absolute;
        padding: 0;
        margin: 0;
        bottom: $main-banner-spacing;
        width: 100%;
        text-align: center;

        li {
            padding: 0 3px;
            margin: 0;
            list-style-type: none;
            width: 18px;
            height: 12px;
            display: inline-block;

            &.slick-active {
                button {
                    background-color: $color-06;
                    border-color: $color-06;
                }
            }

            button {
                @extend %clear-default-appearance;
                text-indent: -99999px;
                width: 10px;
                height: 10px;
                border: 2px solid $color-01;
                border-radius: 50%;
                padding: 0;
                background-color: transparent;

                &:hover {
                    background-color: $color-06;
                    border-color: $color-06;                    
                }
            }
        }
    }
}
