// =============================================================================
// Pagination list
// =============================================================================

.component--pagination-list {
}


.pagination-list {
   ul {
        @extend %clear;
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: relative;

        li {
            padding: 0;
            margin: 0 0 0 ($gutter-sm / 2);
            float: left;

            &:first-child {
                margin: 0;
            }

            &.prev { 
                @include respond-to(md-max) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }  

                a {
                    position: relative;

                    &:before {
                        @extend %icon; 
                        content: "\e901";   
                        position: absolute;
                        top: 50%;
                        left: 50%; 
                        margin: -12px 0 0 -12px;
                        font-size: 20px;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                    }   

                    @include respond-to(md-max) {
                        min-width: 72px;
                    }                    
                }
            }

            &.next {
                @include respond-to(md-max) {
                    position: absolute;
                    top: 0;
                    right: 0;
                }  

                a {
                    position: relative;

                    &:before {
                        @extend %icon; 
                        content: "\e902";   
                        position: absolute;
                        top: 50%;
                        left: 50%; 
                        margin: -12px 0 0 -12px;
                        font-size: 20px;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                    }

                    @include respond-to(md-max) {
                        min-width: 72px;
                    }
                }
            }

            &.page {
                @include respond-to(md-max) {
                    display: none;
                }
            }

            &.info {
                @include respond-to(md-max) {
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    float: none;
                }

                @include respond-to(lg-min) {
                    display: none;
                }
            }

            &.disabled {
                a,
                span {
                    background-color: transparent;
                    border-color: $color-09;
                    color: $color-09;

                    &:hover {
                        background-color: transparent;
                        border-color: $color-09;
                    }
                }
            }

            &.active {
                a {
                    background-color: $color-06;
                    border-color: $color-06;
                }

            }

            a,
            >span {
                display: block;
                min-width: 56px;
                min-height: 56px;
                padding: ($gutter-xs - 1) $gutter-xs;
                color: $color-03;
                text-decoration: none;
                text-align: center;
                font-weight: 500;
            }

            a {
                border: 1px solid $color-03;

                &:hover {
                    background-color: $color-06;
                    border-color: $color-06;
                }
            }
        }
   }
}

