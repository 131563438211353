// =============================================================================
// Carousel: Tile
// =============================================================================
$tile-carousel-item: 584px;
$tile-padding-mobile: 16px;
$tile-padding: 24px;

.component--tile-carousel {
    position: relative;
    margin: 0 (-$gutter-xs);
    padding: $vertical-gutter-mobile 0;
    
    @include respond-to(sm-min) {
        margin: 0 (-$gutter-xs/2);
        padding: 0;
        height: $tile-carousel-item + $ic-btn-height + 5;
    }

    &-wrapper {
        position: relative;
        
        @include respond-to(sm-min) {
            position: absolute;
            left: ($gutter-xs/2);
            top: $vertical-gutter-desktop;
            right: 0;
        }
        
        @include respond-to(lg-min) {
            top: 0;
        }

    }

    &-section {
        .component--title {
            padding-top: $vg-medium-mobile;

            @include respond-to(lg-min) {
                padding-top: 0;
            }
        }
    }

    &-single {

    
        @include respond-to(sm-min) {
            height: $tile-carousel-item + 4;
            margin: 0;
        }

        .component--tile-carousel-wrapper {
            @include respond-to(sm-min) {
                left: 0;
            }
        }

        .tile-carousel {
            padding-bottom: 0;

            &:after {
                display: none;
            }

            &__item {
                margin: 0 $gutter-xs;

                @include respond-to(sm-min) {
                    margin: 0;
                    width: 100%;
                    height: $tile-carousel-item + 4;
                }

                a {
                    @include respond-to(sm-min) {
                        position: relative;
                        width: 100%;
                        height: $tile-carousel-item;
                        display: block;
                        overflow: hidden;
                    }

                }

                &-static {
                    overflow: hidden;
                }
            }

            &__img {
                @include respond-to(sm-min) {
                    position: absolute;
                    top: -9999px;
                    right: -9999px;
                    bottom: -9999px;
                    left: -9999px;
                    margin: auto;
                    min-width: 100%;
                    min-height: 100%;
                }
            }

            &__counter {
                display: none;
            }
        }
    }
    @media print {
    .component--tile-carousel-wrapper {
        right: 0px !important;
      }
      .slick-slide {
          display: block !important;
          opacity: 1;
          min-height: 400px !important;
          height: auto !important;
          width: 45% !important;
          padding-bottom: 10%;
          img {
              display: none;
          }
      }

      // Reset adaptiveHeight
      .slick-list {
          height: auto !important;
          overflow: visible !important;
      }

      // Remove Scrollbars
      .slick-track {
          width: auto !important;
          height: auto !important;
          transform: none !important;
      }
      .tile-carousel__text {
        position: relative;
      }
      .slick-arrow
      {
        display: none !important;
      }
    }
}

.tile-carousel-side {
    @include respond-to(lg-min) {
        padding-right: $col-lg-1;
    }

    @include respond-to(xxl-min) {
        padding-right: $col-xxl-1;
    }
}

.tile-carousel {
    position: relative;
    @include transition((opacity .6s));
    opacity: 0;
    padding-bottom: $ic-btn-height;

    &:after {
        @include respond-to(sm-min) {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: $tile-carousel-item;
            height: 1px;
            background-color: $color-09;
        }
    }

    &.active {
        opacity: 1;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    &__item {
        position: relative;
        border-top: 4px solid $color-03;
        margin: 0 ($gutter-xs/2);

        @include respond-to(sm-min) {
            width: $tile-carousel-item;
        }
    }

    &__img {
        max-width: 100%;
        width: 100%;

        @include respond-to(sm-min) {
            width: $tile-carousel-item;
        }
    }

    &__content {
        position: relative;
        background-color: $color-04;
        color: $color-03;
        overflow: hidden;

        @include respond-to(sm-min) {
            position: absolute;
            bottom: $gutter-xs * 2;
            right: 0;
            width: $tile-carousel-item - $gutter-xs * 2;
            padding: 0;
            border-top: 4px solid $color-03;
        }

        &-only {
            @include respond-to(sm-min) {
                top: 0;
                left: 0;
                bottom: 0;
                border: none;
            }
        }
    }

    .component--tags {
        position: absolute;
        top: $tile-padding-mobile;
        left: $tile-padding-mobile;

        @include respond-to(sm-min) {
            top: $tile-padding;
            left: $tile-padding;
        }
    }

    &__text {
        padding: 70px $tile-padding-mobile $tile-padding-mobile;

        @include respond-to(sm-min) {
            overflow: hidden;
            padding: 80px $tile-padding $tile-padding;
        }

        .detached & {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__caption {
        @extend %font-secondary;
        font-size: $h1-mobile;
        line-height: $h1-mobile-leading;
        margin: 0;

        @include respond-to(sm-min) {
            font-size: $h2-desktop;
            line-height: $h2-desktop-leading;
        }

    }

    &__description {
        padding-top: $tile-padding-mobile;

        &:before,
        &:after {
            content: '';
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &__counter {
        @extend %font-primary-bold;
        @extend %ic-btn;

        background-color: $color-01;
        color: $color-11;
        display: block;
        font-size: $font-size-fine;
        text-align: center;

        position: absolute;
        left: 50%;
        margin-left: -28px;
        bottom: 0;

        @include respond-to(sm-min) {
            margin: 0;
            left: ($tile-carousel-item - 64);
        }

        .carousel--single-slide & {
            display: none;
        }
    }

    &__current-slide {
        color: $color-03;
    }

    &.active {
        opacity: 1;
    }

    p {
        margin: 0;
        padding-top: $tile-padding-mobile / 2;

        &:first-child {
            padding-top: 0;
        }
    }

    dl {
        font-family: $font-family-primary;
        margin: 0;
        padding-top: $tile-padding / 2;

        @include respond-to(sm-min) {
            padding-top: 0;
            padding-left: $tile-padding * 2;
            float: left;
        }

        &:first-child {
            padding-top: 0;

            @include respond-to(sm-min) {
                padding-left: 0;
            }
        }

        dt {
            font-size: $font-size-fine;
            line-height: $font-leading-mobile;
            color: $color-11;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;

            @include respond-to(sm-min) {
                font-size: $font-size-mobile;
            }
        }

        dd {
            font-size: $h4-desktop;
            line-height: $h4-desktop-leading;
            margin: 0;
        }
    }

    .slick-current {
        a {
            &:hover {
                .tile-carousel__content {
                    @include respond-to(sm-min) {
                        width: $tile-carousel-item - $gutter-xs;
                        bottom: $gutter-xs;
                    }        

                    &-only {
                        @include respond-to(sm-min) {
                            width: $tile-carousel-item - $gutter-xs * 2;
                            bottom: $gutter-xs * 2;      
                        }               
                    }        
                }

                .tile-carousel__text {
                    @include respond-to(sm-min) {
                        padding-right: $tile-padding + $gutter-xs;
                    }

                    &-only {
                        @include respond-to(sm-min) {
                            padding-right: $tile-padding;  
                        }               
                    }  
                }
            }

            .tile-carousel__content {
                @include respond-to(sm-min) {
                    @include transition((width .3s, bottom .3s));
                }                
            }

            .tile-carousel__text {
                @include respond-to(sm-min) {
                    @include transition((padding .3s));
                }
            }
        }        
    }
}

// slick JS elements:
.slick {
    &-track {
        .component--tile-carousel & {
            @include respond-to(xs-max) {
                left: 32px!important;
            }
        }
    }

    &-arrow {
        .component--tile-carousel & {
            @extend %ic-btn;
            display: none;
            background-color: $color-01;
            border: 0;
            font-size: 0;
            padding: 0;

            position: absolute;

            @include respond-to(sm-min) {
                bottom: 0;
            }

            &:before {
                font-size: $base-font-size;

                position: relative;
                z-index: 7;
            }

            &:after {
                @include respond-to(sm-min) {
                    background-color: $color-06;
                    content: '';
                    height: $ic-btn-height;
                    width: 0;

                    position: absolute;
                    z-index: 6;
                    top: -(($ic-btn-height - $ic-btn-height) / 2);
                    left: 0;
                    @include transition((width .3s));
                }
            }

            &:hover {
                &:after {
                    @include respond-to(sm-min) {
                        width: $ic-btn-height;
                    }
                }
            }
        }
    }

    &-prev {
        .component--tile-carousel & {
            z-index: 7;

            @include respond-to(xs-max) {
                right: $ic-btn-height-mobile * 2;
                bottom: 0;
                display: none!important;
            }

            @include respond-to(sm-min) {
                left: ($tile-carousel-item - 192);
            }
        }
    }

    &-next {
        .component--tile-carousel & {
            @include respond-to(xs-max) {
                right: $ic-btn-height-mobile;
                bottom: 0;
                display: none!important;
            }

            @include respond-to(sm-min) {
                border-right: 1px solid rgba($color-01, .8);
                left: ($tile-carousel-item - 128);
            }
        }
    }

    &-disabled { opacity: .3; }


    &-slide {
        .component--tile-carousel & {
            @include transition((opacity .6s));
            opacity: 0.3;
        }
    }

    &-current {
        .component--tile-carousel & {
            opacity: 1;
        }
    }
}