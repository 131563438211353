.component--pagination {
    @extend %cf-dashboard-nav;

    margin-top: 0;
}

.pagination {
    @extend %has-dashboard-nav;

    padding: 28px 0;

    &__direction-btn {
        position: relative;
        min-height: 38px;

        &:hover,
        &:focus {
            text-decoration: none;

            .pagination__item-title {
                color: $color-05;
            }

            .pagination__label {
                color: $color-05;

                @include respond-to(sm-min) {
                    color: $color-03;
                }
            }
        }

        &:before {
            font-size: 20px;
            position: absolute;
            bottom: 0;
        }
    }

    &__prev-btn {
        float: left;
        padding-left: 32px;

        &:before {
            left: 0;
        }

        .pagination__label,
        .pagination__item-title {
            display: none;

            @include respond-to(sm-min) {
                display: block;
            }
        }
    }

    &__next-btn {
        float: right;
        padding-right: 32px;
        text-align: right;

        &:before {
            right: 0;
        }
    }

    &__label {
        color: $color-03;
        font-size: 12px;
        line-height: 14px;
    }

    &__item-title {
        color: $color-03;
        font-size: 16px;
        font-weight: bold;
        transition: color .3s;
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}
