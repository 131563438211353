// =============================================================================
// Mixins
// =============================================================================

// Animation
@mixin animation($args...) {
    -webkit-animation: $args;
       -moz-animation: $args;
        -ms-animation: $args;
         -o-animation: $args;
            animation: $args;
}
// usage: .className { @include animation(animationName 0.40s forwards ); }
// time can be in s or ms (0.40s OR 400ms)
// ------------------------------------

// Gradient backgrounds (left to right)
// @mixin gradient-ltr($left, $right) {
//     background: $args;
//     background: $args;
//     background: $args;
//     background: $args;
//     background: $args;
// }
// usage: .className { @include animation(animationName 0.40s forwards ); }
// time can be in s or ms (0.40s OR 400ms)
// ------------------------------------




// Gradient
// with fallback for older browsers that don't support gradient
@mixin gradient($degree, $fromColor, $toColor) {
    background: $toColor;
    background: -webkit-linear-gradient($degree, $fromColor, $toColor);
    background:    -moz-linear-gradient($degree, $fromColor, $toColor);
    background:     -ms-linear-gradient($degree, $fromColor, $toColor);
    background:         linear-gradient($degree, $fromColor, $toColor);
}
// usage: .className { @include gradient(180deg, $red, $black); }
// ------------------------------------

// Headings
// define font size for desktop
// mobile sizes are 50% of the defined styles
@mixin heading($font-size, $padding) {

    @if $font-size > 24 {
        font-size: ($font-size / 2) + px;
        padding: (($padding / 2) + px) 0;
    } @else {
        font-size: $font-size + px;
        padding: $padding + px 0;
   }

    @include respond-to(md-min) {
        font-size: $font-size + px;
        padding: $padding + px 0;
    }
}
// usage: .className { @include heading(24, 5); }
// ------------------------------------

// Opacity (Cross browser)
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
// usage: .className { @include opacity(0.8); }
// ------------------------------------

// Raduis
@mixin radius($size) {
   -webkit-border-radius: $size + px;
      -moz-border-radius: $size + px;
       -ms-border-radius: $size + px;
        -o-border-radius: $size + px;
           border-radius: $size + px;
}
// usage: .className { @include radius(3); }
// ------------------------------------

// Transparency Background Color
// with fallback for older browsers that don't support RGBA
@mixin rgba-background($hexcolor, $opacity) {
    background-color: $hexcolor;
    background-color: rgba($hexcolor, $opacity);
}
// usage: .className { @include rgba-background($color, 0.7); }
// ------------------------------------

// Transparency Color
// with fallback for older browsers that don't support RGBA
@mixin rgba-color($hexcolor, $opacity) {
    color: $hexcolor;
    color: rgba($hexcolor, $opacity);
}
// usage: .className { @include rgba-color($color, 0.7); }
// ------------------------------------

// Retina
@mixin retina($val) {
    width: ($val / 2) + px;
}
// usage: .className { @include retina(40); }
// ------------------------------------

// Filter
@mixin filter($args...) {
    -webkit-filter: $args;
       -moz-filter: $args;
        -ms-filter: $args;
         -o-filter: $args;
            filter: $args;
}
// usage: @include filter(grayscale(20)); }
// ------------------------------------


// Transform
@mixin transform($transforms) {
    -webkit-transform: $transforms;
       -moz-transform: $transforms;
        -ms-transform: $transforms;
         -o-transform: $transforms;
            transform: $transforms;
}
// usage: @include transform(calledMixinName); }
// ------------------------------------

// Transform Origin
@mixin transform-origin($args...) {
    -webkit-transform-origin: $args;
       -moz-transform-origin: $args;
            transform-origin: $args;
}
// usage: @include transform-origin(2.86px center); }
// ------------------------------------

// Rotate
@mixin rotate($deg) {
    @include transform(rotate($deg + deg));
}
// usage: @include rotate(-45); }
// ------------------------------------

// Transitions
@mixin transition($args...) {
    -webkit-transition: $args;
       -moz-transition: $args;
        -ms-transition: $args;
         -o-transition: $args;
            transition: $args;
}
// usage: .className { @include transition(all, 0.25s, ease-in-out); }
// ------------------------------------

// Transition Property
@mixin transition-property($args...) {
    -webkit-transition-property: $args;
       -moz-transition-property: $args;
        -ms-transition-property: $args;
         -o-transition-property: $args;
            transition-property: $args;
}
// usage: .className { @include transition-property(transform, opacity); }
// ------------------------------------

// Transitions Delays
@mixin transition-delay($delay) {
    -webkit-transition-delay: $delay;
       -moz-transition-delay: $delay;
        -ms-transition-delay: $delay;
         -o-transition-delay: $delay;
            transition-delay: $delay;
}

@mixin flex() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// usage: .className { @include transition-delay(2s OR 200ms); }
// ------------------------------------

// Emboss
@mixin box-emboss($opacity, $opacity2){
  box-shadow: white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}
// usage: .className { @include box-emboss(0.8, 0.05); }
// ------------------------------------

// Cricle
@mixin circle($color, $size, $dustyGray: transparent, $borderWidth: 0) {
    @if $dustyGray != transparent and $borderWidth != 0 {
        border-color: $dustyGray;
        border-width: $borderWidth + px;
        border-style: solid;
    }

    background-color: $color;
    border-radius: ($size / 2) + px;
    //display: block;
    height: $size + px;
    width: $size + px;
}
// usage: .className { @include circle(#fff, 20, #000, 2); }
// usage: .className { @include circle(#fff, 20); }
// ------------------------------------


// Clear .col-* css
// NOTE: used when @extending .col-* styles
@mixin clear-col-css($bp: null) {
    @if $bp != null {
        @include respond-to($bp) {
            float: none;
            padding-left: 0;
            padding-right: 0;
        }
    } @else {
        float: none;
        padding-left: 0;
        padding-right: 0;
    }
}
// usage: .className { @include clear-col-css(sm); }
// ------------------------------------


// Scale pixel value
@function scale-pixel-value($value, $base: $base-font-size) {
    @return #{(to-number($value) / to-number($base))}rem;
}

// Scale typography
@mixin scale-typography($font-size: $base-font-size, $line-height: $base-leading, $base: $base-font-size) {
    font-size: scale-pixel-value($font-size, $base);
    line-height: scale-pixel-value($line-height, $base);
}