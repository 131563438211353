$ab-btn: 72px;

.component--action-bar {
    margin: 0;
}

.ab {
    @include respond-to(md-min) {
        margin-top: -(($ab-btn * 3) / 2);
        position: fixed;
        z-index: 8;
        top: 50%;
        right: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: none;
        margin: 0;
        padding: 0;

        &.ab__item {
            @include respond-to(md-min) {
                display: block;
            }            
        }

        &.ab__item-favourite {
            margin-top: 1px;

            @include respond-to(sm-max) {
                position: fixed;
                bottom: 0;
                right: 0;
            }

            @include respond-to(md-min) {
                display: none;
            }  
        }
    }

    a,
    button {
        background-color: $color-03;
        border: 0;
        border-radius: 0;
        color: $color-01;
        display: block;
        font-size: $h3-desktop;
        height: 56px;
        line-height: 56px;
        padding: 0;
        text-align: center;
        text-decoration: none;
        width: 56px;

        position: relative;

        @include respond-to(md-min) {
            height: 72px;
            line-height: 72px;
            width: 72px;
        }

        &:after {
            @extend %hover;

            background-color: $color-06;
            content: '';
            height: 100%;
            width: 0;

            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
        }

        &:hover {
            color: $color-01;
            text-decoration: none;

            .non-touch-device & {
                &:after {
                    width: 100%;
                }
            }
        }

        &:focus {
            color: $color-01;
            text-decoration: none;
        }

        i,
        img {
            position: relative;
            z-index: 2;
        }

        img {
            width: 24px;
        }
    }
}

.compare-bar {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    background-color: $color-03;
    height: 56px;
    line-height: 56px;
    color: $color-01;
    font-size: 14px;
    z-index: 8;
    overflow: hidden;

    @include respond-to(sm-min) {
        width: $container-sm;
        margin-left: - ($container-sm / 2);
    }

    @include respond-to(md-min) {
        width: $container-md;
        margin-left: - ($container-md / 2);
        height: 72px;
        line-height: 72px;
    }

    @include respond-to(lg-min) {
        width: $container-lg;
        margin-left: - ($container-lg / 2);
    }

    @include respond-to(xxl-min) {
        width: $container-xxl;
        margin-left: - ($container-xxl / 2);
    }

    &__text {
        float: left;
        padding-left: 16px;

        @include respond-to(md-min) {
            padding-left: 32px;
        }
    }

    &__link {
        float: right;
        padding-right: 16px;

        @include respond-to(md-min) {
            padding-right: 32px;
        }

        a {
            color: $color-06;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                color: $color-01;
                text-decoration: none;
            }
        }
    }

}
