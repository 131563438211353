.aem-AuthorLayer-Preview,
.aem-AuthorLayer-Edit {
    // banner
    // ------------------------------------
    .banner.banner--fullscreen {
        height: 800px;
    }

    // global nav
    // ------------------------------------

    .gn {
        &__wrap {
            margin: 0 auto;
            width: $gn-width;

            position: relative;
            top: auto;
        }

        &__inner {
            min-height: 600px;
        }

        &__item {
            opacity: 1;
            transform: translateY(0);
        }

        &__footer {
            position: relative;
        }
    }

}