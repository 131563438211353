// =============================================================================
// Unit Selection Guide
// =============================================================================

// usg shortform used for 'unit selection guide' class name to keep it short
$usg-btn-width: 98px;

$usg-padding-top: (($btn-height - $base-leading - ($btn-border * 2)) / 2);
$usg-padding-left: (($gutter * 2) - $btn-border);

.usg {
    &__title {
        padding-bottom: 10px;
    }

    &__label {
        @extend .h6;

        display: block;
        font-weight: bold;
        padding: ($usg-padding-top + $btn-border) 0;
    }

    &__search {
        @extend .cf;

        font-size: 0;
        line-height: 0; // NOTE: to remove space above

        position: relative;

        &-bar,
        &-btn {
            @extend %reset-font;

            display: inline-block;
            padding-top: $usg-padding-top;
            padding-bottom: $usg-padding-top;
            vertical-align: text-top;
        }

        &-bar {
            @extend %clear-default-appearance;
            border: $btn-border solid $color-09;
            border-radius: 0;
            display: block;
            font-size: $base-font-size;
            line-height: $base-leading;
            padding-left: $usg-padding-left;
            padding-right: $btn-height + $gutter;
            width: 100%;

            // Remove clear field button from IE
            &::-ms-clear {
                display: none;
            }

        }

        &-btn {
            background-color: $color-05;
            border: $btn-border solid $color-05;
            color: $color-01;
            margin: 0;
            text-align: center;
            width: $usg-btn-width;

            position: absolute;
            top: 0;
            right: 0;
        }

        &-clear {
            background-color: $color-01;
            border: 0;
            color: $color-03;
            display: none;
            height: $btn-height - $gutter;
            margin: 0;
            padding: 0;
            width: $btn-height - $gutter;

            position: absolute;
            right: $btn-border;
            top: 50%;

            @include transform(translateY(-50%));

            &:before {
                content: '\00d7';
                display: block;
                font-size: 25px;
            }
        }
    }

    &__show {
        @include respond-to(xs-max) { width: 100%; }
    }

    &__select-shell {
        border: $btn-border solid $color-09;
        overflow: hidden;

        position: relative;

        &:before {
            color: $color-05;

            position: absolute;
            top: $usg-padding-top;
            right: $gutter * 2;
        }

        select {
            border: 0;
            padding: $usg-padding-top 35px $usg-padding-top $usg-padding-left;

            // Remove default dropdown arrow from IE
            &::-ms-expand {
                display: none;
            }

            option {
                :disabled {
                    color: rgba($color-03, .25);
                }
            }

        }
    }

    &__course-name,
    &__course-code {
        padding: ($usg-padding-top + $btn-border) 0;
    }

    &__course-code {
        font-weight: bold;
    }

    &__row {
        margin-bottom: 20px;

        &:nth-last-child(2) { margin-bottom: 40px; }
        &:last-child { margin-bottom: 0; }

        &-course-name,
        &-course-code,
        &-campus,
        &-specialisation-1,
        &-specialisation-2 {
            display: none;
        }

        &-title {
            padding: ($usg-padding-top + $btn-border) 0;
        }
    }

    &__bottom {
        margin-top: $gutter * 2;
    }

    &__a-units,
    &__b-units {
        display: none;
    }

    &__table {
        width: 100%;

        &-row {
            border-bottom: 1px solid $color-09;

            &:last-child {
                border-bottom: 0;
            }

            td {
                &:nth-child(1) {
                    font-weight: bold;
                    width: 10%;

                    padding: 15px 20px 15px 0;

                    @include respond-to(sm-min) {
                        padding: 15px 30px;
                    }
                }

                &:nth-child(2) {
                    padding: 15px 0;

                    @include respond-to(sm-min) {
                        padding: 15px 45px;
                    }
                }
            }
        }
    }
}

// TypeAhead styles:
// ------------------------------------
.twitter-typeahead {
    @extend %reset-font;

    vertical-align: text-top;
    width: 100%;
}

.tt {
    &-menu {
        background-color: $color-01;
        border-right: $btn-border solid $color-09;
        border-bottom: $btn-border solid $color-09;
        border-left: $btn-border solid $color-09;
        max-height: 200px;
        overflow-y: auto;
        width: 100%;
    }

    &-suggestion {
        padding: $usg-padding-top $usg-padding-left;

        &.tt-cursor,
        &:hover {
            background-color: $color-04;
        }
    }
}
