@media print {
  @page {
    size: A4 portrait ;
  }
  //show link href attribute value
  p {
    a {
      &:after {
        content: " (Links to: " attr(href) ")";
        font-size: 0.8em !important;
        font-weight: normal !important;
        white-space: nowrap !important;
        position: relative !important;
        background-color: transparent !important;
      }
    }

  }
  // All hidden elements
   nav, aside, video, footer, .icon, .ab,.tile-carousel__counter {
      display: none !important;
   }

   body, article {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      background: transparent !important;
   }

   //ensure that articles always start on a fresh page
  article {
     page-break-before: always !important;
  }
  // remove sticky header otherwise it will print on every page
header {
  .main-nav {
    position: relative !important;

    &__accessibility,
    &__toggle,
    &__navigation,
    &__toolbar {
      display: none !important;
    }
  }
}
  .slick-slider {
       img {
          display: none !important;
       }

      .slick-arrow {
        display: none !important;
      }  
  }

  
}