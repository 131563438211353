.sidepanel{
	padding: 48px 16px 32px 16px;
	border-top: 4px solid $color-03;

	&-content{
		margin-bottom: 40px;
		font-size: 18px;
	}

	.component{
		margin-top: -20px;
	}
}