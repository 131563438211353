.bc {
    @extend .cf;

    display: none;

    @include respond-to(sm-min) {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        float: left;

        &:first-child {
            &:before {
                display: none;
            }
        }

        &:before {
            content: '>';
            display: inline-block;
            text-align: center;
            width: 16px;
        }
    }

    &__link {
        color: $color-03;
        display: inline-block;
        font-size: $font-size-mobile;
        text-decoration: underline;
    }
}
