.fc {
    @extend .cf;

    &__radiowrap {
        float: left;
        width: 50%;

        position: relative;
    }

    &__radiolabel:before,
    &__radiolabel:after,
    &__radio {
        position: absolute;
    }

    &__radiolabel:before,
    &__radio {
        height: $fc-radio-size;
        width: $fc-radio-size;

        top: $fc-radio-offset;
        left: $fc-radio-offset;
    }

    &__radio {
        border-radius: 50%;

        // &:after {
        //     background-color: rgba($color-06, .3);
        //     border-radius: 50%;
        //     content: '';
        //     height: $fc-radio-size;
        //     transform: translate(-50%, -50%) scale(0);
        //     width: $fc-radio-size;
        //
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        // }

        &:focus {
            // option #1: ouline
            // outline: 1px dotted $color-06;

            // option #2: shadow
            outline: 0;

            + .fc__radiolabel {
                &:before {
                    box-shadow: 0 0 8px rgba($color-06, .8);
                }
            }
        }

        &:checked {
            // &:after {
            //     animation-name: ripple;
            //     // animation-duration: .65s;
            //     animation-duration: .5s;
            //     animation-delay: 0s;
            //     animation-iteration-count: 1;
            //     animation-direction: normal;
            //     animation-timing-function: cubic-bezier(.25, .8, .25, 1); // or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2)
            //
            // }
            &:focus {
                + .fc__radiolabel {
                    &:before {
                        box-shadow: 0 0 8px rgba($color-03, .8);
                    }
                }
            }
        }
    }

    &__radiolabel {
        cursor: pointer;
        display: inline-block;
        padding-left: $fc-padding-for-radio;

        &:before,
        &:after {
            content: '';
            border-radius: 50%;
        }

        &:before {
            background-color: $color-01;
            border: 2px solid $color-03;

            // height: $fc-radio-size - 4px;
            // width: $fc-radio-size - 4px;
        }

        &:after {
            background-color: $color-06;
            height: $fc-radio-dot-size;
            width: $fc-radio-dot-size;
            transform: scale(0);

            top: ($base-leading - $fc-radio-dot-size) / 2;
            left: ($base-leading - $fc-radio-dot-size) / 2;

            transition: transform .35s;

            input[type='radio']:checked + & {
                transform: scale(1);
            }
        }
    }

    &__ripple {
        .fc__radiowrap & {
            @include ripple($color-06, $fc-radio-size);

            top: $fc-radio-offset;
            left: $fc-radio-offset;
        }

        input[type='radio']:checked ~ & {
            .fc__radiowrap & {
                animation-name: ripple;
                animation-duration: .5s;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-timing-function: cubic-bezier(.25, .8, .25, 1);
            }
        }
    }
}
