// =============================================================================
// Rich Text (wysiwyg)
// =============================================================================

.component--wysiwyg {
    @include background-context-dark { color: $color-01; }

    @extend %inpage-link;

    // TODO: write rules around the columns?

    .template--news-detail .col-sm-9 &,
    .template--news-detail .col-md-9 & {
        @include respond-to(md-min) {
            margin-right: $col-md-1;
        }

        @include respond-to(lg-min) {
            margin-right: $col-lg-1;
        }

        @include respond-to(xxl-min) {
            margin-right: $col-xxl-1;
        }
    }

    .template--event-detail .col-sm-8 &,
    .template--event-detail .col-md-8 & {
        @include respond-to(md-min) {
            // margin-right: $col-02;
            margin-right: $col-lg-1 * 2;
        }

        @include respond-to(xxl-min) {
            margin-right: $col-xxl-1 * 2;
        }
    }
}


