.fc {
    &__textarea {
        @extend %fc-input;

        background-color: $color-04;
        display: inline-block;
        min-height: ($base-leading * 4) + 1px;
        padding: (($fc-input-height - $fc-input-border - $base-leading) / 2) 8px;
        vertical-align: bottom;
    }
}
