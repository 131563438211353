@mixin background-context-dark {
    .bg--black &,
    .bg--crimson &,
    .bg--dark-grey &,
    .bg--purple &,
    .bg--red &,
    .bg--teal & {
        @content;
    }
}

@mixin background-context-match {
    .bg--black & { background-color: $color-03; }
    .bg--crimson & { background-color: $color-02; }
    .bg--dark-grey & { background-color: $color-12; }
    .bg--light-grey & { background-color: $color-08; }
    .bg--mid-grey & { background-color: $color-10; }
    .bg--off-white & { background-color: $color-04; }
    .bg--orange & { background-color: $color-06; }
    .bg--purple & { background-color: $color-13; }
    .bg--teal & { background-color: $color-14; }
}

@mixin background-context-light {
    .bg--white &,
    .bg--light-grey &,
    .bg--mid-grey &,
    .bg--off-white &,
    .bg--red &,
    .bg--orange & {
        @content;
    }
}
