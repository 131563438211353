%fc-check {
    height: $fc-check-size;
    width: $fc-check-size;

    top: $fc-check-offset;
}

%fc-check-left {
    left: $fc-check-offset;
}

%fc-check-right {
    right: $fc-check-offset;
}

%fc-toggle {
    height: $fc-toggle-handle-size;
    width: $fc-toggle-handle-size;

    top: $fc-toggle-handle-offset;
    right: $fc-toggle-handle-size;

    transition: background-color .35s, right .35s;
}

%fc-toggle-checked {
    right: 0;
}

.fc {
    &__checkwrap {
        position: relative;
        margin-top: 8px;
    }

    // NOTE: common styles hence grouped together
    &__checkbox,
    &__checklabel:before,
    &__checklabel:after {
        position: absolute;

        .fc__check--left & {
            @extend %fc-check;
            @extend %fc-check-left;
        }

        .fc__check--right & {
            @extend %fc-check;
            @extend %fc-check-right;
        }
    }

    &__checkbox,
    &__checklabel:after {
        @extend %fc-toggle;
    }

    &__checklabel {
        // background-color: rgba(#fc0, .2); // TODO: remove
        cursor: pointer;
        display: inline-block;

        .fc__check--left & {
            padding-left: $fc-padding-for-check;
        }

        .fc__check--right & {
            padding-right: $fc-padding-for-check;
        }

        .fc__check--toggle & {
            padding-right: 50px;
        }

        input[type='checkbox']:disabled + & {
            color: $color-09;
            cursor: default;
        }

        &:before {
            content: '';

            .fc__check--left &,
            .fc__check--right & {
                background-color: $color-01;
                border: 2px solid $color-03;
                border-radius: 2px;

                transition: background-color .35s, border-color .35s;
            }

            .fc__check--toggle & {
                background-color: $color-09;
                border-radius: $fc-toggle-track-height / 2;
                height: $fc-toggle-track-height;
                width: $fc-toggle-track-width;

                top: $fc-toggle-track-offset-top;
                right: $fc-toggle-track-offset-right;

                transition: background-color .35s;
            }

            input[type='checkbox']:checked + & {
                .fc__check--left &,
                .fc__check--right & {
                    background-color: $color-06;
                    border-color: $color-06;
                }

                .fc__check--toggle & {
                    background-color: rgba($color-06, .5);
                }
            }

            input[type='checkbox']:disabled + & {
                .fc__check--left &,
                .fc__check--right & {
                    border-color: $color-09;
                }
            }
        }

        &:after {
            color: $color-01;
            font-size: $fc-check-size;
            line-height: 100%;
            text-align: center;

            .fc__check--left &,
            .fc__check--right & {
                @extend %icon;
                content: '\e90a';
                transform: scale(0);

                transition: transform .35s;
            }

            .fc__check--toggle & {
                background-color: $color-04;
                background-color: #f2f2f2;
                border-radius: $fc-toggle-handle-size / 2;
                box-shadow: 0 1px 3px 0 rgba($color-03, .2), 0 1px 1px 0 rgba($color-03, .14), 0 2px 1px -1px rgba($color-03, .12);
                content: '';
            }

            input[type='checkbox']:checked + & {
                .fc__check--left &,
                .fc__check--right & {
                    transform: scale(1);
                }

                .fc__check--toggle & {
                    @extend %fc-toggle-checked;

                    background-color: $color-06;
                }
            }
        }
    }

    &__checkbox {
        @extend %clear-default-appearance;
        border: 0;

        .fc__check--toggle & {
            transform: scale(.7);
        }

        &:focus {
            .fc__check--left &,
            .fc__check--right & {
                // option #1: outline on checkbox itself
                // outline: $color-06 dotted 1px;

                // option #2: glow
                outline: 0;

                + .fc__checklabel {
                    &:before {
                        box-shadow: 0 0 8px rgba($color-06, .8);
                    }
                }
            }

            .fc__check--toggle & {
                outline: 0;

                + .fc__checklabel {
                    &:after {
                        // option #1: border on 'handle'
                        // border: 1px dotted $color-06;

                        // option #2: shadow
                        box-shadow: 0 0 8px rgba($color-06, .8);
                    }
                }
            }
        }

        &:checked {
            .fc__check--toggle & {
                @extend %fc-toggle-checked;
            }

            &:focus {
                .fc__check--left &,
                .fc__check--right & {
                    // option #1: outline on checkbox itself
                    // outline: $color-03 dotted 1px;

                    + .fc__checklabel {
                        &:before {
                            box-shadow: 0 0 8px rgba($color-03, .8);
                        }
                    }
                }

                .fc__check--toggle & {
                    + .fc__checklabel {
                        &:after {
                            // option #1: border on 'handle'
                            // border: 1px dotted $color-03;

                            // option #2: shadow
                            box-shadow: 0 0 8px rgba($color-03, .8);
                        }
                    }
                }
            }
        }
    }

    &__ripple {
        .fc__check--left &,
        .fc__check--right & {
            @include ripple($color-06, $fc-check-size);

            top: $fc-check-offset;
        }

        .fc__check--toggle & {
            @include ripple($color-06, $fc-toggle-handle-size);

            // top: 0;
            // right: 0;

            // transform: translate(- $fc-toggle-handle-size, $fc-toggle-handle-offset) scale(0);

            top: $fc-toggle-handle-offset;
            //right: $fc-toggle-handle-size;
            right: 0;

            // transition: background-color .35s, opacity .35s, right .35s;
        }

        .fc__check--left & {
            left: $fc-check-offset;
        }

        .fc__check--right & {
            right: $fc-check-offset;
        }

        input[type='checkbox']:checked ~ & {
            // animation-duration: .65s;
            animation-duration: .5s;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-timing-function: cubic-bezier(.25, .8, .25, 1);

            .fc__check--left &,
            .fc__check--right & {
                animation-name: ripple;
            }

            .fc__check--toggle & {
                animation-name: ripple;
                animation-delay: .05s;
                // animation-name: ripple-move;

                // transform: translate(0, $fc-toggle-handle-offset) scale(0);
                // right: 0;
            }
        }
    }
}
