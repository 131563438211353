// Main navigation
$nav-mobile: 56px;
$nav-primary-mobile: 64px;
$nav-primary-lvl3-mobile: 48px;
$nav-secondary-mobile: 40px;

$nav-primary: 72px;
$nav-secondary: 40px;


html {
    @include respond-to(sm-max) {
      /*  @media only screen and (min-width: 480px) and (orientation: landscape) {
            overflow: hidden;
            position: relative;
            height: 100%;
        }*/
    }

    &.main-nav-open {
        @include respond-to(sm-max) {
            overflow: hidden;
            position: relative;
            height: 100%;
        }

        body {
            @include respond-to(sm-max) {
                overflow: hidden;
                position: relative;
                height: 100%;
            }
        }
    }

    &.main-nav-search-open {
        overflow: hidden;
        position: relative;
        height: 100%;

        body {
            overflow: hidden;
            position: relative;
            height: 100%;

            @include respond-to(sm-max) {
                background-color: $color-03;
            }
        }
    }

    /*body {
        @include respond-to(sm-max) {
            @media only screen and (min-width: 480px) and (orientation: landscape) {
                overflow: hidden;
                position: relative;
                height: 100%;
            }
        }        
    }*/
}

.header {
    min-height: $nav-mobile + 1;

    @include respond-to(md-min) {
        min-height: $nav-primary + $nav-secondary + 1;
    }
}

.main-nav {
    width: 100%;
    background-color: $color-01;
    color: $color-03;
    border-bottom: 1px solid $color-09;
    z-index: 99;
    height: $nav-mobile + 1;
    position: fixed;
    top: 0;
    left: 0;

    @include respond-to(md-min) {
        height: $nav-primary + $nav-secondary + 1;
        width: 100%;
        padding-top: $nav-secondary;
    }

    &:before {
        @include respond-to(md-min) {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: $nav-secondary;
            background-color: $color-12;
        }
    }

    &.scrolled { 
        @include transition((top .3s)); 
    }

    &.compact {         
        @include respond-to(md-min) {
            margin-top: - ($nav-secondary);
        }
    }

    &.retracted {
        top: - ($nav-mobile + 1);
        
        @include respond-to(md-min) {
            top: - ($nav-primary + 1);
        }
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }

    a {
        color: $color-11;
        display: block;
        text-decoration: none;
        @include transition((none)); 
    }

    &__wrapper {
        @extend %clear;

        @include respond-to(md-min) {
            width: $container-md;
            margin: 0 auto;
        }

        @include respond-to(lg-min) {
            width: $container-lg;
        }

        @include respond-to(xxl-min) {
            width: $container-xxl;
        }

    }

    &__accessibility {
        position: absolute;
        top: 0;
        left: 50%;
        width: $container-md;
        margin-left: - ($container-md / 2);

        @include respond-to(sm-max) {
            display: none;
        }

        @include respond-to(lg-min) {
            width: $container-lg;
            margin-left: - ($container-lg / 2);
        }

        @include respond-to(xxl-min) {
            width: $container-xxl;
            margin-left: - ($container-xxl / 2);
        }

        div {
            text-transform: uppercase;
            font-size: 14px;
            line-height: $nav-secondary;
            font-weight: 500;
            letter-spacing: 1px;
            color: $color-01;

            a {
                padding: 0 16px;
                color: $color-01;
                opacity: 0;
                @include transition((opacity .3s)); 
                z-index: 9;
                float: left;
                position: relative;
                background-color: $color-12;

                &:focus {
                    opacity: 1;
                }
            }

            span {
                padding: 0 16px;
                opacity: 0;
                @include transition((visibility .3s)); 
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
                background-color: $color-12;

                &.active {
                    opacity: 1;
                }
            }

        }

    }

    &__logo {
        float: left;
        width: $nav-mobile * 2;
        height: $nav-mobile;
        padding-left: $nav-mobile;

        @include respond-to(md-min) {
            width: $nav-primary;
            height: $nav-primary;
            padding: 0;
        }

        @include respond-to(lg-min) {
            width: 219px;
        }

        a {
            display: block;
            padding: (($nav-mobile - 30) / 2) (($nav-mobile - 24) / 2);

            @include respond-to(md-min) {
                padding: (($nav-primary - 40) / 2) (($nav-primary - 32) / 2);
            }

            @include respond-to(lg-min) {
                padding: (($nav-primary - 30) / 2) 8px;
            }
        }

        &-wrapper {
            width: 24px;
            height: 30px;
            overflow: hidden;

            @include respond-to(md-min) {
                width: 32px;
                height: 40px;
            }

            @include respond-to(lg-min) {
                width: 203px;
                height: 30px;
            }
            @media print {
                width: 203px !important;
                height: 30px !important;
            }
        }

        img {
            display: block;
            height: 30px;

            @include respond-to(md-min) {
                height: 40px;
            }

            @include respond-to(lg-min) {
                height: 30px;
            }
        }
    }

    &__toolbar {

        float: right;
        height: $nav-mobile;

        @include respond-to(md-min) {
            height: $nav-primary;
        }

        &-item {
            float: left;
            font-size: 24px;

            a {
                display: block;
                width: $nav-mobile;
                height: $nav-mobile;
                line-height: $nav-mobile;
                color: $color-03;
                background-color: $color-01;
                text-align: center;
                position: relative;

                @include respond-to(md-min) {
                    width: $nav-primary;
                    height: $nav-primary;
                    line-height: $nav-primary;
                }

                &:before {
                    position: relative;
                    z-index: 2;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    z-index: 1;
                    @include transition((width .3s, color .3s));   
                    background-color: $color-06;
                }

                &:hover {
                    &:after {
                        @include respond-to(md-min) {
                            width: 100%;
                        }
                    }
                }
            }

            &-compare {
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 3;
                font-family: $font-family-primary !important;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: $color-06;
            }
        }

        @media print {
            display: none !important;
        }
    }

    &__toggle {
        width: $nav-mobile;
        height: $nav-mobile;
        position: absolute;
        top: 0;
        left: 0;

        a {
            width: $nav-mobile;
            height: $nav-mobile;
        }


        @include respond-to(md-min) {
            display: none;
        }

        &.active {
            .main-nav__toggle-icon {
                span {
                    &:nth-child(1) {
                      top: 5px;
                      width: 0%;
                      left: 50%;
                    }

                    &:nth-child(2) {
                        @include transform(rotate(45deg));
                    }

                    &:nth-child(3) {
                        @include transform(rotate(-45deg));
                    }

                    &:nth-child(4) {
                      top: 5px;
                      width: 0%;
                      left: 50%;
                    }
                }
            }
        }

        &-icon {
            position: absolute;
            z-index: 2;
            width: 18px;
            height: 12px;
            top: 50%;
            left: 50%;
            margin: -6px 0 0 -9px;
            @include transition((.3s ease-in-out));   
            @include transform(rotate(0deg));

            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background-color: $color-03;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                @include transition((.15s ease-in-out));   
                @include transform(rotate(0deg));

                &:nth-child(1) {
                  top: 0;
                }

                &:nth-child(2),
                &:nth-child(3) {
                  top: 5px;
                }

                &:nth-child(4) {
                  top: 10px;
                }
            }
        }

    }

    &__navigation {
        position: fixed;
        top: $nav-mobile + 1;
        left: -100%;
        width: 100%;
        bottom: 0;
        background-color: $color-01;
        z-index: 99;

        @include respond-to(sm-max) {
            @include transition((left .3s, visibility .3s)); 
            overflow-x: hidden;
            overflow-y: auto;
            visibility: hidden;
        }

        @include respond-to(md-min) {
            position: static;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            float: left;
            width: auto;
        }

        @include respond-to(lg-min) {
            padding-left: 24px;
        }

        &.active {
            @include respond-to(sm-max) {
                left: 0;
                visibility: visible;
            }
        }

        &.shifted {
            @include respond-to(sm-max) {
                overflow-y: hidden;
            }
            .main-nav__navigation-wrapper {
                @include respond-to(sm-max) {
                    left: -100%;
                    bottom: 0;
                }
            }
        }

        &-wrapper {
            @include respond-to(sm-max) {
                position: absolute;
                top: 0;
                left: 0;
                width: 200%;
                min-height: 100%;
                @include transition((left .3s)); 
            }
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        width: $nav-primary-mobile;
        height: $nav-primary-mobile;
        color: $color-03;
        background-color: $color-04;
        border: none;
        -webkit-appearance: none;
        font-size: 20px;
        line-height: $nav-primary-mobile;
        
        @include respond-to(md-min) {
            display: none;
        }

        &-open {
            &:before {
                @extend %icon;    
                content: "\e902";   
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;             
            }
        }

        &-close {
            background-color: $color-06;
            &:before {
                @extend %icon;   
                content: "\e901";   
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;            
            }
        }

        &-toggle {
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: $color-03;
                @include transition((opacity .3s, transform .3s));   

                &-active & {
                    @include transform(rotate(90deg));
                }   
            }

            &:before {
                width: 2px;
                height: 14px;
                margin-left: -1px;
                margin-top: -7px;
            }

            &:after {
                width: 14px;
                height: 2px;
                margin-left: -7px;
                margin-top: -1px;
            }

            &.active {
                &:before {
                    @include transform(rotate(90deg));
                }

                &:after {
                    @include transform(rotate(90deg));
                    opacity: 0;
                }
            }
        }
    }

    &__content {
        @include respond-to(md-min) {
            float: left;
            width: $col-md-1 * 4;
            padding-right: 16px;
        }

        @include respond-to(lg-min) {
            width: $col-lg-1 * 4;
            padding-right: $col-lg-1;
        }

        @include respond-to(xxl-min) {
            width: $col-xxl-1 * 4;
            padding-right: $col-xxl-1;
        }

        h2 {
            position: relative;
            border-bottom: 1px solid $color-09;
            margin: 0;

            @include respond-to(md-min) {
                padding: 0;
            }

            a {
                position: relative;
                font-family: $font-family-secondary;
                font-size: 20px;
                line-height: $nav-primary-mobile;
                padding: 0 16px;
                background-color: $color-04;

                @include respond-to(md-min) {
                    color: $color-03;
                    line-height: $nav-primary;
                    padding: 2px 0;
                    margin: 0;
                    font-size: $h2-desktop;
                    line-height: $h2-desktop-leading;
                    background-color: transparent;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-06;
                    @include transition((width .3s));
                    z-index: 9;
                }

                &:hover {
                    @include respond-to(md-min) {
                        color: $color-03;
                    }

                    &:after {
                        @include respond-to(md-min) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-text {
            display: none;

            @include respond-to(md-min) {
                display: block;
                margin-top: 20px;
                font-size: $lead-mobile;
                line-height: $lead-mobile-leading;
            }

        }
    }

    &__primary {
        @include respond-to(sm-max) {
            width: 50%;
        }

        @include respond-to(md-min) {
            max-width: $container-md - ($nav-primary * 4);
        }

        @include respond-to(lg-min) {
            max-width: $container-lg - 243 - ($nav-primary * 3);
        }

        @include respond-to(xxl-min) {
            max-width: $container-xxl - 243 - ($nav-primary * 3);
        }

        .main-nav {
            &__level-1 {
                @extend %clear;

                >li {
                    @include respond-to(md-min) {
                        float: left;
                    }

                    .non-touch-device & {
                        &:hover {
                            .main-nav__level-1-link {
                                a {
                                    @include respond-to(md-min) {
                                        color: $color-03;
                                    }

                                    &:after {
                                        @include respond-to(md-min) {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .main-nav__level-1-wrapper {
                                @include respond-to(md-min) {
                                    display: block;
                                }
                            }
                        }
                    }

                    .main-nav__level-1-link {
                        a {
                            &.highlighted {
                                    @include respond-to(sm-max) {
                                        background-color: $color-04;
                                    }

                                    + button {
                                        background-color: $color-09;
                                    }

                                    &:after {
                                        @include respond-to(md-min) {
                                            @include transition((none));
                                            width: 100%;
                                        }
                                    }

                            }
                        }
                    }
                }

                &-link {
                    position: relative;
                    border-bottom: 1px solid $color-09;

                    @include respond-to(md-min) {
                        border: none;
                        float: left;
                    }

                    a {
                        position: relative;
                        font-family: $font-family-secondary;
                        font-size: 20px;
                        line-height: $nav-primary-mobile;
                        padding: 0 16px;

                        @include respond-to(md-min) {
                            font-size: 18px;
                            line-height: $nav-primary;
                            padding: 0;
                            margin: 0 24px;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: 0;
                            height: 2px;
                            background-color: $color-06;
                            @include transition((width .3s));
                            z-index: 9;
                            
                            @include respond-to(md-min) {
                                z-index: 101;
                            }
                        }

                        &.active,
                        &:hover {
                            @include respond-to(md-min) {
                                color: $color-03;
                            }

                            &:after {
                                @include respond-to(md-min) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &-wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    width: 50%;
                    background-color: $color-01;
                    z-index: 100;

                    @include respond-to(sm-max) {
                        opacity: 0;
                        visibility: hidden;
                        @include transition((left .3s)); 
                        overflow-y: auto;
                    }

                    @include respond-to(md-min) {
                        position: absolute;
                        top: $nav-secondary + $nav-primary;  
                        left: 0;
                        bottom: auto;
                        padding: 48px 0;
                        border-top: 1px solid $color-09;
                        border-bottom: 1px solid $color-09;
                        width: 100%;
                        display: none;
                    }

                    &.active {
                        @include respond-to(sm-max) {
                            left: 50%;
                            opacity: 1;
                            visibility: visible;
                        }

                        @include respond-to(md-min) {
                            display: block;
                        }
                    }

                    &-inner {
                        @extend %clear;

                        @include respond-to(md-min) {
                            width: $container-md;
                            margin: 0 auto;
                        }

                        @include respond-to(lg-min) {
                            width: $container-lg;
                        }

                        @include respond-to(xxl-min) {
                            width: $container-xxl;
                        }
                    }

                }
            }

            &__level-2 {
                @extend %clear;

                @include respond-to(md-min) {
                    width: 67%;
                    float: left;
                }

                @include respond-to(md-min) {
                    position: relative;
                    float: left;
                    width: $col-md-1 * 8;
                }

                @include respond-to(lg-min) {
                    width: $col-lg-1 * 8;
                }

                @include respond-to(xxl-min) {
                    width: $col-xxl-1 * 8;
                }

                >li {
                    @include respond-to(md-min) {
                        width: ($col-md-1 * 4) - 8;
                    }

                    @include respond-to(lg-min) {
                        width: $col-lg-1 * 3;
                    }

                    @include respond-to(xxl-min) {
                        width: $col-xxl-1 * 3;
                    }

                    &:hover {
                        .non-touch-device & {
                            .main-nav__level-2-link {
                                a {
                                    &:after {
                                        @include respond-to(md-min) {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .main-nav__level-3 {
                                @include respond-to(md-min) {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &-acc {
                    a {
                        &:before {
                            @extend %icon;  
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: $nav-mobile; 
                            line-height: $nav-mobile;

                            @include respond-to(md-min) {
                                content: "\e902";
                                line-height: 44px;
                            }                
                        }
                    }
                }

                &-link {
                    position: relative;
                    border-bottom: 1px solid $color-09;

                    a {
                        position: relative;
                        font-family: $font-family-secondary;
                        font-size: 18px;
                        line-height: $nav-primary-mobile;
                        padding: 0 16px;

                        @include respond-to(md-min) {
                            font-size: 16px;
                            line-height: 44px;
                            padding: 0;
                            color: $color-03;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: 0;
                            height: 2px;
                            background-color: $color-06;
                            @include transition((width .3s));
                            z-index: 9;
                        }

                        &:hover {
                            @include respond-to(md-min) {
                                color: $color-03;
                            }

                            &:after {
                                @include respond-to(md-min) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            &__level-3 {
                @extend %clear;
                padding: 16px 0;
                border-bottom: 1px solid $color-09;
                display: none;

                @include respond-to(md-min) {
                    position: absolute;
                    top: 0;
                    left: ($col-md-1 * 4) - 8;
                    width: ($col-md-1 * 4) + 8;
                    padding: 0 0 0 16px;
                    border: none;
                }

                @include respond-to(lg-min) {
                    left: $col-lg-1 * 3;
                    width: $col-lg-1 * 4;
                    padding-left: $col-lg-1;
                }

                @include respond-to(xxl-min) {
                    left: $col-xxl-1 * 3;
                    width: $col-xxl-1 * 4;
                    padding-left: $col-xxl-1;
                }

                &.active {
                    display: block;
                }

                a {
                    position: relative;
                    font-family: $font-family-secondary;
                    font-size: 16px;
                    line-height: 48px;
                    padding: 0 16px 0 56px;

                    @include respond-to(md-min) {
                        line-height: 44px;
                        padding: 0;
                        color: $color-03;
                        border-bottom: 1px solid $color-09;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 0;
                        height: 2px;
                        background-color: $color-06;
                        @include transition((width .3s));
                        z-index: 9;
                    }

                    &:hover {
                        @include respond-to(md-min) {
                            color: $color-03;
                        }

                        &:after {
                            @include respond-to(md-min) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &__secondary {
        @include respond-to(md-min) {
            max-height: 0;
        }

        &-wrapper {
            background-color: $color-12;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;

            @include respond-to(md-min) {
                top: 0;
                bottom: auto;
                left: 50%;
                width: $container-md;
                margin-left: - ($container-md / 2);
            }

            @include respond-to(lg-min) {
                width: $container-lg;
                margin-left: - ($container-lg / 2);
            }

            @include respond-to(xxl-min) {
                width: $container-xxl;
                margin-left: - ($container-xxl / 2);
            }
        }

        ul {
            @include respond-to(md-min) {
                float: right;
            }

            li {
                @include respond-to(md-min) {
                    float: left;
                }

                a {
                    color: $color-09;
                    text-transform: uppercase;
                    font-size: 14px;
                    line-height: $nav-secondary-mobile;
                    font-weight: 500;
                    letter-spacing: 1px;
                    padding: 0 16px;

                    @include respond-to(md-min) {
                        line-height: $nav-secondary;
                    }

                    &:hover {
                        @include respond-to(md-min) {
                            background-color: $color-03;
                            color: $color-01;
                        }
                    }
                }
            }
        }
    }

    &__search {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-03;
        z-index: 9999;
        color: $color-01;
        overflow-y: auto;
        visibility: hidden;
        opacity: 0;
        @include transition(visibility .3s, opacity .3s);

        &.active {
            visibility: visible;
            opacity: 1;
        }

        &-wrapper {
            margin: 0 $gutter-xs;
            position: relative;
            height: 100%;

            @include respond-to(sm-min) {
                width: $container-sm;
                margin: 0 auto;
                height: 100vh;
            }

            @include respond-to(md-min) {
                width: $container-md;
            }

            @include respond-to(lg-min) {
                width: $container-lg;
            }

            @include respond-to(xxl-min) {
                width: $container-xxl;
            }


            &.active {
                .main-nav__search-text {
                    @include respond-to(xs-max) {
                        opacity: 0;
                    }
                    
                }

                .main-nav__search-component {
                    @include respond-to(xs-max) {
                        top: 112px;
                        margin-top: 0;
                    }
                }
            }
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 99;

            a {
                display: block;
                width: 46px;
                height: 46px;
                color: $color-01;
                font-size: 24px;
                line-height: 46px;
                text-align: center;
                padding: $gutter-xs;
                text-decoration: none;

                @include respond-to(sm-min) {
                    width: 74px;
                    height: 74px;
                    line-height: 74px;
                }

                &:before {
                    @extend %icon;   
                    content: "\e916";   
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;            
                }
            }
        }

        &-text {
            font-size: $h3-mobile;
            line-height: $h3-mobile-leading;
            opacity: 1;
            @include transition((opacity .3s)); 
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin-top: -110px;
            padding-bottom: 110px;

            @include respond-to(sm-min) {
                position: absolute;
                top: 40%;
                margin-top: -150px;
            }

            @include respond-to(md-min) {
                font-size: $h3-desktop;
                line-height: $h3-desktop-leading;
            }
        }

        &-component {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin-top: -29px;
            background-color: $color-03;
            @include transition((top .3s, margin-top .3s)); 

            @include respond-to(sm-min) {
                position: absolute;
                top: 40%;
                margin-top: -54px;
            }
        }
    }
}

.device-rotation {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    background-color: $color-03;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;        
    text-align: center;
    @include transition((opacity .3s, visibility .3s)); 

    @include respond-to(xs-max) {
        @media only screen and (min-width: 480px) and (orientation: landscape) {
            opacity: 1;
            visibility: visible;
        }
    }

    @include respond-to(sm-min) {
        display: none;
    }

    &__text {
        color: $color-01;
        font-size: $h2-mobile;
        line-height: $h2-mobile-leading;
        padding-top: 96px;
        background-image: url('../images/rotate.png');
        background-position: top center;
        background-size: 64px;
        background-repeat: no-repeat;
    }
}

/* Overwriting live chat z-index */
#livechat-compact-container {
    z-index: 10!important;
}