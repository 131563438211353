// =============================================================================
// Respond-To (Mixin for Media Queries) - calculates "FROM" set min-breakpoint
// =============================================================================

// define screen breakpoints here
$xxs-min: 340px;
$xs-min: 544px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1220px;
$xl-min: 1440px;
$xxl-min: 1920px;

$ipad-landscape-min: 1024px;
$ipad-max: 1025px;



// Breakpoints passed into 'respond-to' @mixin.
$breakpoints: (
    'min': (
        'xxs-min': null,
        'xs-min': $xs-min,
        'sm-min': $sm-min,
        'md-min': $md-min,
        'lg-min': $lg-min,
        'xl-min': $xl-min,
        'xxl-min': $xxl-min,
        'ipad-min': $sm-min
    ),
    'max': (
        'xxxs-max': $xxs-min,
        'xxs-max': $xs-min,
        'xs-max': $sm-min,
        'sm-max': $md-min,
        'md-max': $lg-min,
        'lg-max': $xl-min,
        'xl-max': $xxl-min,
        'xxl-max': null,
        'ipad-max': $ipad-max
    ),
    'min-max': (
        'xxs': (
            min: null,
            max: $xs-min,
        ),
        'xs': (
            min: $xs-min,
            max: $sm-min
        ),
        'sm': (
            min: $sm-min,
            max: $md-min
        ),
        'md': (
            min: $md-min,
            max: $lg-min
        ),
        'lg': (
            min: $lg-min,
            max: $xl-min
        ),
        'xl': (
            min: $xl-min,
            max: $xxl-min
        ),
        'xxl': (
            min: $xxl-min,
            max: null
        ),
        'ipad': (
            min: $sm-min,
            max: $ipad-max
        )
    )
);

$min-map: map-get($breakpoints, min);
$max-map: map-get($breakpoints, max);
$min-max-map: map-get($breakpoints, min-max);

// Respond to
// @access public
// @param {String} $breakpoint - Breakpoint
// @requires $breakpoints
@mixin respond-to($breakpoint) {
    @if map-has-key($min-map, $breakpoint) {
        // @media (min-width: **)
        $min-val: map-get($min-map, $breakpoint);

        @if $min-val != null {
            @media screen and (min-width: $min-val) {
                @content;
            }
        }
    } @else if map-has-key($max-map, $breakpoint) {
        // @media (max-width: **)
        $max-val: map-get($max-map, $breakpoint);
        @if $max-val != null {
            @media screen and (max-width: $max-val - 1px) {
                @content;
            }
        }
    } @else if map-has-key($min-max-map, $breakpoint) {
        // @media (min-width: **) and (max-width: **)
        $range-min-val: map-get(map-get($min-max-map, $breakpoint), min);
        $range-max-val: map-get(map-get($min-max-map, $breakpoint), max);

        @if $range-min-val != null and $range-max-val != null {
            @if $breakpoint == 'ipad' {
                @media screen and (min-device-width: $range-min-val) and (max-device-width: $range-max-val) {
                    @content;
                }
            } @else {
                @media screen and (min-width: $range-min-val) and (max-width: $range-max-val - 1px) {
                    @content;
                }
            }
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
    }
}
