// =============================================================================
// Slick: base styles required for slick js slick-slider
// NOTE: this is not a theme
// =============================================================================
.slick-slider {
    box-sizing: border-box;
    display: block;

    position: relative;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -khtml-user-select: none;

    -webkit-touch-callout: none;

    -ms-touch-action: pan-y;
    touch-action: pan-y;

    -webkit-tap-highlight-color: transparent;

    .slick-track,
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;

    position: relative;

    &:focus { outline: none; }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;

    position: relative;
    top: 0;
    left: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & { visibility: hidden; }
}

.slick-slide {
    display: none;
    float: left;
    // height: 100%;
    // min-height: 1px;

    img { display: block; }

    &.slick-loading {
        img { display: none; }
    }

    &.dragging {
        img { pointer-events: none; }
    }

    [dir='rtl'] & {
        float: right;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        // height: auto;
    }
}

.slick-arrow {
    &.slick-hidden { display: none; }
}
