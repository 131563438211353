// =============================================================================
// Event detail(s)
// =============================================================================

// ed shortform used for 'event detail(s)' class name to keep it small
.ed {
    &__top {

    }

    &__date,
    &__tag {
        @include respond-to(xs-max) {
            margin-top: $vertical-gutter-mobile;
        }
    }

    &__date {
        @include respond-to(sm-min) {
            text-align: right;
        }
    }

    &__date-time {
        @extend %has-icon;
    }

    &__bottom {
        border-top: 1px solid $color-09;
        border-bottom: 1px solid $color-09;
        padding-top: $vertical-gutter-mobile;
        padding-bottom: $vertical-gutter-mobile;

        @include respond-to(sm-min) {
            padding-top: $vertical-gutter-desktop;
            padding-bottom: $vertical-gutter-desktop;
        }
    }

    &__item,
    &__btn {
        @include respond-to(xs-max) {
            margin-top: $vertical-gutter-mobile;
        }
    }

    &__item {
        @extend %has-icon;
        // max-width: 170px;
    }

    &__location-name,
    &__location-address {
        display: block;
    }

    &__btn {
        width: 100%;
    }
}
