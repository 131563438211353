// =============================================================================
// Form Controls (inputs, labels etc.)
// =============================================================================

// general (all inputs):
$fc-input-height: 40px;
$fc-input-border: 1px;

// checkbox:
$fc-check-size: 18px;
$fc-check-offset: ($base-leading - $fc-check-size) / 2;
$fc-padding-for-check: $fc-check-size + ($fc-check-offset * 2) + 8px;

$fc-toggle-handle-size: 20px;
$fc-toggle-handle-offset: ($base-leading - $fc-toggle-handle-size) / 2;

$fc-toggle-track-height: 14px;
$fc-toggle-track-width: 34px;
$fc-toggle-track-offset-top: ($base-leading - $fc-toggle-track-height) / 2;
$fc-toggle-track-offset-right: (($fc-toggle-handle-size * 2) - $fc-toggle-track-width) / 2;


// radio:
$fc-radio-size: 20px;
$fc-radio-offset: ($base-leading - $fc-radio-size) / 2;
$fc-padding-for-radio: $fc-radio-size + ($fc-radio-offset * 2) + 8px;

$fc-radio-dot-size: 10px;

%fc {
    margin-top: 24px;
    overflow: visible;

    position: relative;
}

%fc--field {
    &:before {
        background-color: $color-06;
        content: '';
        height: 4px;
        width: 0;

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        @include transition(width .3s);
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    &.fc--focus {
        &:before {
            width: 100%;
        }
    }
}

%fc-input {
    background-color: $color-01;
    border: 0;
    border-bottom: $fc-input-border solid $color-03;
    border-radius: 0;
    margin-top: 24px;
    width: 100%;

    position: relative;
}

%fc-text-padding {
    padding: (($fc-input-height - $fc-input-border - $base-leading) / 2) 0;
}

%fc-tooltip {
    background-color: $color-04;
    opacity: 0;
    padding: 8px;
    width: 100%;

    position: absolute;
    left: 0;
    top: 100%;

    transition: opacity .35s;

    .fc--focus:not(.fc--error) .fc__text + &,
    .fc--focus:not(.fc--error) .fc__number + &,
    .fc--focus:not(.fc--error) .fc__tel + &,
    .fc--focus:not(.fc--error) .fc__select + &,
    .fc--focus:not(.fc--error) .fc__email + &,
    .fc--focus:not(.fc--error) .fc__textarea + & {
        opacity: 1;
        z-index: 4;
    }
}

%fc-error {
    color: $color-18;
    font-size: $font-size-fine;
    line-height: $base-font-size;
    opacity: 0;
    padding-top: 8px;

    position: absolute;
    z-index: 4;
    left: 0;
    top: 100%;

    transition: opacity .35s;

    .fc--error .fc__text ~ &,
    .fc--error .fc__number ~ &,
    .fc--error .fc__tel ~ &,
    .fc--error .fc__email ~ &,
    .fc--error .fc__radiowrap ~ &,
    .fc--error .fc__select ~ &,
    .fc--error .fc__textarea ~ & {
        opacity: 1;
    }
}

@mixin ripple($bgColor, $size) {
    background-color: rgba($bgColor, .3);
    border-radius: 50%;
    height: $size;
    width: $size;

    transform: scale(0);

    position: absolute;
}

select {
    @extend %clear-default-appearance;

    border: 0;
    text-transform: none;
    width: 100%;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

input {
    &[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
}

button,
select {
    text-transform: none;
}

// NOTE: fc = form control
.fc {
    @extend %fc;

    &--field {
        @extend %fc--field;
    }

    &__tooltip {
        @extend %fc-tooltip;
    }

    &__error {
        @extend %fc-error;
    }

    .title__text {
        padding-bottom: 8px;
    }
}

@import './label';
@import './input';
@import './input-textarea';
@import './select';
@import './input-checkbox';
@import './input-radio';
@import './slider';
@import './date-range-picker';
@import './button';
