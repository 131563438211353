// =============================================================================
// List component
// =============================================================================
.component--list {
    .template--event-detail & {
        @include respond-to(md-min) {
            margin-right: $col-02;
        }
    }
}

.list {
    &--unordered,
    &--ordered,
    &--linked,
    &--icon {
        @extend %list;
    }

    &--unordered,
    &--ordered,
    &--icon {
        li {
            @extend %list-item;
            @extend %has-icon;

            @extend %inpage-link;
        }
    }

    &--unordered {
        @extend %list--ul;
    }

    &--ordered {
        @extend %list--ol;
    }

    &--linked {

        li {
            @extend %link-with-icon;
            padding-top: 0;
            padding-bottom: 0;

            a {
                @extend %text-btn;
                @include background-context-dark {
                    color: $color-01;

                    &:before {
                        background-color: $color-01;
                    }
                }

                &:focus {
                    outline: 0;
                }
            }
        }
    }

    &--icon {
        li {
            &:before {
                @include background-context-dark { color: $color-01; }
                color: $color-03;
            }
        }
    }
}
