$pg-height-desktop: 625px;

$pg-caption-height-desktop: 392px; // NOTE: 24 (padding-top) + 24 (bread crumb) + 24 (padding-top) + 32 (tag) + 224 (title 4 lines) + 64 (extra space)
$pg-caption-height-tablet: 328px; // NOTE: 24 (padding-top) + 24 (bread crumb) + 24 (padding-top) + 32 (tag) + 160 (title 4 lines) + 64 (extra space)
$pg-caption-height-mobile: 232px; // NOTE: 16 (padding-top) + 32 (tag) + 24 (padding-top) + 160 (title 5 lines)

.pb {
    padding-top: calc(56px + 10%);

    @include respond-to(sm-min) {
        padding-top: 56px + 80px;
    }

    @include respond-to(md-min) {
        padding-top: 56px + 108px;
    }

    @include respond-to(xxl-min) {
        padding-top: 0;
        height: $pg-height-desktop;
    }

    &.default,
    &.college,
    &.undergraduate {
        background-color: $color-02;
    }

    &.postgraduate { background-color: $color-14; }
    &.research { background-color: $color-13; }

    &--image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__container {
        height: 100%;

        position: relative;
    }

    &__caption {
        background-color: $color-01;
        padding: 16px 16px 0;
        width: 90%;

        @include respond-to(sm-min) {
            padding: 24px 24px 0 $gutter-sm; // Option #1
            width: $col-08;
        }

        @include respond-to(md-min) {
            width: $col-05;
        }

        @include respond-to(xxl-min) {
            padding-left: 0;

            position: absolute;
            bottom: 0;
            left: 8px;
        }

        &:before {
            background-color: $color-01;
            content: '';
            height: 100%;
            width: 50vw;

            position: absolute;
            left: -50vw;
            top: 0;
        }

        &-inner {
            padding: 16px;

            @include respond-to(sm-min) {
                padding: 24px 24px 0 0;
            }
        }
    }

    &__content {
        display: table;
        height: $pg-caption-height-mobile - 16px; // NOTE: changed min-hight to height as FF doesn't support min-height with display: table

        @include respond-to(sm-min) {
            height: $pg-caption-height-tablet - (24px * 2); // NOTE: changed min-hight to height as FF doesn't support min-height with display: table
        }

        @include respond-to(xxl-min) {
            height: $pg-caption-height-desktop - (24px * 2); // NOTE: changed min-hight to height as FF doesn't support min-height with display: table
        }

        &-inner {
            display: table-cell;
            vertical-align: middle; // NOTE: was bottom
        }
    }

    &__description {
        margin-top: 24px;
    }
    //PRIT STYLES
    @media print {
        padding-top: 0px;
    }
}
