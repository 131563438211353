// =============================================================================
// 	Base
// =============================================================================

// ------------------------------------
//  Reset
// ------------------------------------
figure { margin: 0; } // NOTE: remove?

// ------------------------------------
//  Base start
// ------------------------------------
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // remove tap highlight from iOS
}

// below required for sticky footer -------|
html {
    min-height: 100%;
    position: relative;
}

.wrapper {
    height: 100%;
    // overflow: hidden;
    width: 100%;
}
// above required for sticky footer -------|

// below prevents 'overscrolling' on iOS -------|
html {
    &.no-scroll {
        height: 100%;
        overflow: hidden;

        body {
            height: 100%;
            overflow: auto;

            .wrapper {
                overflow: hidden;

                .content {
                    position: relative;
                }
            }
        }
    }
}
// above prevents 'overscrolling' on iOS -------|


// ------------------------------------
//  Content
// ------------------------------------
// height set in content for dorp down menu to show if no other content on the page
.content {
    // min-height: 300px;
    min-height: 100%;


    @extend .cf;
}
