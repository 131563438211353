// =============================================================================
//  Utilities
//  Name space: .u-name {}
//  NOTE: these utility classes should only have one attribute each
// =============================================================================

// clear floats
// ------------------------------------
.cf {
    &:after {
      content: '';
      clear: both;
      display: block;
    }
}

// display:
// ------------------------------------
.u-display--inline-block { display: inline-block !important; }

.u-display--table { display: table; }

.u-display--table-cell { display: table-cell; }

.u-display--v-align-top { vertical-align: top; }
.u-display--v-align-middle { vertical-align: middle; }
.u-display--v-align-bottom { vertical-align: bottom; }

// text:
// ------------------------------------
.u-text--bold { font-weight: bold !important; }
.u-text--caps { text-transform: uppercase !important; }
.u-text--shadow { text-shadow: 0 1px 2px rgba(0,0,0,0.35); }

.u-text--left 	{ text-align: left !important; }
.u-text--center { text-align: center !important; }
.u-text--right 	{ text-align: right !important; }

.u-text--left-sm {
	@include respond-to(sm-min) { text-align: left !important; }
}

.u-text--center-sm {
	@include respond-to(sm-min) { text-align: center !important; }
}

.u-text--right-sm {
	@include respond-to(sm-min) { text-align: right !important; }
}

.u-text--left-md {
	@include respond-to(md-min) { text-align: left !important; }
}

.u-text--center-md {
	@include respond-to(md-min) { text-align: center !important; }
}

.u-text--right-md {
	@include respond-to(md-min) { text-align: right !important; }
}

.u-text--left-lg {
	@include respond-to(lg-min) { text-align: left !important; }
}

.u-text--center-lg {
	@include respond-to(lg-min) { text-align: center !important; }
}

.u-text--right-lg {
	@include respond-to(lg-min) { text-align: right !important; }
}

// overflow:
// ------------------------------------
.u--overflow-show { overflow: visible !important; }

// postion:
// ------------------------------------
.u--relative { position: relative !important; }
.u--absolute { position: absolute !important; }

// hidden:
// ------------------------------------
.u--hidden { display: none !important; }

// hidden except for screen reader:
// ------------------------------------
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

// heights:
// ------------------------------------
.u--height-100 { height: 100%; }

// margins:
// ------------------------------------
// push = +ve
.u-push--top-25 { margin-top: 25px !important; }

.u-push--bottom-60 { margin-bottom: 60px !important; }