.dashboard {
    &__card-container {
        position: relative;
        float: left;
        overflow: hidden;
        width: 100%;

        @include respond-to(sm-min) {
            padding-top: 50%;
            width: percentage(1 / 3);
        }

        @include respond-to(xs-max) {
            padding-top: 150vw;
            min-height: 100vh;
        }

        @include respond-to(xxs-max) {
            padding-top: 0;
        }

        // @include respond-to(xxl-min) {
        //     width: percentage(1 / 6);
        //     padding-top: 25%;
        // }

        .iphone & {
            @include respond-to(xxs-max) {
                min-height: calc(100vh - #{$iphone-safari-nav-height});
            }
        }

        &:nth-child(10n + 1),
        &:nth-child(10n + 7) {
            @include respond-to(sm-min) {
                width: percentage(2 / 3);
            }

            // @include respond-to(xxl-min) {
            //     width: percentage(2 / 6);
            // }
        }
    }
}
