$sibling-nav-box-shadow-color: rgba($color-03, .5);

.component--sibling-nav {
    background-color: $color-01;
    box-shadow: 0 2px 5px 0 $sibling-nav-box-shadow-color;
    height: 100vh;
    margin-top: 0;
    padding-top: $nav-trigger;
    max-width: 460px;
    overflow-y: auto;
    position: fixed;
    z-index: 99;
    right: -460px;
    top: 0;
    width: 100%;

    @include transition(right .3s ease-in-out);

    &.panel--expanded {
        right: 0;
    }
}

.sibling-nav {

    .iphone & {
        @include respond-to(xs-max) {
            padding-bottom: $iphone-safari-nav-height;
        }
    }

    &__trigger {
        background: transparent;
        border: 0;
        height: scale-pixel-value($nav-trigger);
        padding: scale-pixel-value($nav-trigger-pad-top) scale-pixel-value(($nav-trigger - $nav-trigger-width) / 2);
        width: scale-pixel-value($nav-trigger);

        position: absolute;
        z-index: 98;
        top: 0;
        right: 0;

        @include respond-to(sm-min) {
            top: $dash-border;
            right: $dash-border;
        }

        &:hover
        // ,&:focus
        {
            outline: 0;
        }

        span {
            &,
            &:before,
            &:after {
                content: '';
                background: $color-01;
                border-radius: 100%;
                height: $nav-trigger-line * 2;
                width: $nav-trigger-line * 2;
                float: left;
                font-size: 0;
            }

            & {
                position: relative;
                top: 0;
            }

            &:before,
            &:after {
                position: absolute;
                left: 0;
    		}

            &:before { top: - ($nav-trigger-line-distance + $nav-trigger-line); }
            &:after  { top: ($nav-trigger-line-distance + $nav-trigger-line); }
        }
    }

    &__close {
        background: transparent;
        border: 0;
        position: absolute;
        right: 11px;
        top: 13px;

        &:before {
            content: '\00d7';
            display: block;
            font-size: 25px;
        }
    }

    &__header {
        padding: 20px;
        position: relative;

        &:before {
            background-color: $color-09;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
        }

        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }

    &__header-title {
        float: left;
        font-size: 13px;
        letter-spacing: 1.6px;
        line-height: 18px;
        margin-bottom: 0;
    }

    &__header-value {
        color: $color-02;
        float: right;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 18px;
    }

    &__header-bar {
        clear: both;
        background-color: $color-05;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
    }

    &__navigation-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__navigation-item {
        position: relative;

        // @include transition(all .35s linear);

        &--selected {
            &:before {
                background-color: $color-05;
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                width: 3px;

                @include respond-to(sm-min) {
                    width: 5px;
                }
            }
        }

        // &:hover {
        //     background-color: $color-04;
        //
        //     @include transition(all .35s linear);
        // }
    }

    &__item-content {
        display: table;
        color: inherit;
        text-decoration: none;
        width: 100%;

        @include transition(all .35s linear);

        &:hover,
        &:focus {
            background-color: $color-04;
            color: inherit;
            outline: 0;
            text-decoration: none;


            @include transition(all .35s linear);
        }
    }

    &__value-col,
    &__meta-col {
        display: table-cell;
        vertical-align: top;
    }

    // Event item styles
    &__navigation-item--event {
        .sibling-nav__item-content {
            min-height: 123px;
            padding-top: 35px;

            @include respond-to(sm-min) {
                min-height: 140px;
            }
        }

        .sibling-nav__value-col {
            text-align: center;
            width: 27%;
        }

        .sibling-nav__day {
            // @extend %font-primary-bold;
            color: $color-11;
            font-size: 12px;
            line-height: 18px;
        }

        .sibling-nav__date {
            color: $color-05;
            font-size: 32px;
            line-height: 39px;
        }

        .sibling-nav__meta-col {
            padding-bottom: 7px;
            position: relative;
            width: 73%;

            &:after {
                bottom: 0;
                background-color: $color-04;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        .sibling-nav__title {
            font-size: 18px;
            min-height: 48px;
            line-height: 24px;
            padding-right: 20%;

            @include respond-to(sm-min) {
                font-size: 20px;
                min-height: 52px;
                line-height: 26px;
            }
        }

        .sibling-nav__times,
        .sibling-nav__location {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: .3px;

            &:before {
                vertical-align: middle;
            }
        }

        .sibling-nav__times {
            // @extend %font-primary-bold;
            color: $color-11;
            margin-top: 9px;
        }

        .sibling-nav__location {
            color: $color-05;
            text-transform: uppercase;
        }
    }

    // News item styles
    &__navigation-item--news {
        .sibling-nav__item-content {
            @include respond-to(sm-min) {
                min-height: 160px;
            }

            &:after {
                bottom: 0;
                background-color: $color-04;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        .sibling-nav__value-col {
            position: relative;
            width: 48%;
            padding: 20px;
        }

        .sibling-nav__image {
            background-size: cover;
            padding-top: percentage(2 / 3);
        }

        .sibling-nav__meta-col {
            padding: 20px 0;
            position: relative;

            @include respond-to(sm-min) {
                padding: 0;
                vertical-align: middle;
            }
        }

        .sibling-nav__date {
            color: $color-11;
            font-size: 12px;
            letter-spacing: .3px;
            line-height: 15px;

            @include respond-to(sm-min) {
                left: 0;
                position: absolute;
                top: 20px;
            }
        }

        .sibling-nav__title {
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            margin-top: 3px;
            padding-right: 20px;

            @include respond-to(sm-min) {
                margin-top: 0;
                padding-right: 25%;
            }
        }
    }

    // Enrolment/Guide item styles
    &__navigation-item--guide {
        .sibling-nav__item-content {
            height: 120px;
        }

        .sibling-nav__value-col {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 33%;
                bottom: 0;
                top: 0;
                width: 1px;
                background-color: $color-09;
            }
        }

        &:first-child {
            .sibling-nav__value-col {
                &:before {
                    top: 50%;
                }
            }
        }

        &:last-child {
            .sibling-nav__value-col {
                &:before {
                    bottom: 50%;
                }
            }
        }

        .sibling-nav__step-status-icon {
            border-radius: 50%;
            height: 25px;
            left: 33%;
            margin-left: -12px;
            margin-top: -12px;
            position: absolute;
            top: 50%;
            width: 25px;

            @include respond-to(sm-min) {
                height: 31px;
                margin-left: -15px;
                margin-top: -15px;
                width: 31px;
            }
        }

        .sibling-nav__step-status-value {
            color: $color-11;
            font-size: 10px;
            left: 33%;
            letter-spacing: .2px;
            line-height: 12px;
            margin-left: 27px;
            margin-top: -6px;
            position: absolute;
            top: 50%;
        }

        &.sibling-nav__navigation-item--complete {
            .sibling-nav__step-status-icon {
                background-color: $color-05;
                color: $color-01;
                line-height: 25px;
                text-align: center;

                @include respond-to(sm-min) {
                    line-height: 31px;
                }
            }
        }

        &.sibling-nav__navigation-item--in-progress {
            .sibling-nav__step-status-icon {
                $desaturated-border: rgba($color-05, .5);
                background-color: $color-01;
                border: 2px solid $desaturated-border;

                &:before {
                    background-color: $color-05;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    height: 8px;
                    left: 50%;
                    margin-top: -4px;
                    margin-left: -4px;
                    position: absolute;
                    top: 50%;
                    width: 8px;

                    @include respond-to(sm-min) {
                        height: 10px;
                        margin-top: -5px;
                        margin-left: -5px;
                        width: 10px;
                    }
                }
            }
        }

        &.sibling-nav__navigation-item--not-complete {
            .sibling-nav__step-status-icon {
                background-color: $color-01;
                border: 2px solid $color-09;
                height: 21px;
                margin-left: -10px;
                margin-top: -10px;
                width: 21px;
            }
        }

        .sibling-nav__meta-col {
            padding-top: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
            position: relative;
            vertical-align: middle;
            width: 67%;

            @include respond-to(sm-min) {
                padding-right: 45px;
            }

            &:after {
                bottom: 0;
                background-color: $color-04;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        .sibling-nav__title {
            font-size: 14px;
            line-height: 20px;
        }

        &.sibling-nav__navigation-item--selected {
            .sibling-nav__title {
                font-size: 14px;
                font-weight: bold;

                @include respond-to(sm-min) {
                    font-size: 16px;
                }
            }
        }

        .sibling-nav__notification {
            color: $color-07;
            font-size: 12px;
            line-height: 20px;
        }

        .sibling-nav__action-icon {
            position: absolute;
            top: 50%;
            right: 21px;
            margin-top: -6px;
            font-size: 12px;
            line-height: 12px;

            @include respond-to(sm-min) {
                right: 25px;
            }

            &.icon-chevron-down {
                &:before {
                    @include transform(rotate(-90deg) translate(0, -50%));
                    position: absolute;
                }
            }
        }
    }

    // Notification item styles
    &__navigation-item--notification {
        border-bottom: 1px solid $color-04;
        // padding: $gutter * 2;

        .sibling-nav__item-content {
            // padding-left: ($gutter * 3);
            // padding-right: ($gutter * 5);
            padding: ($gutter * 2) ($gutter * 7) ($gutter * 2) ($gutter * 5);
            height: 100px;

            // position: relative;

            // &:before {
            //     position: absolute;
            //     right: 0;
            //     top: 50%;
            //
            //     @include transform(translateY(-50%) rotate(-90deg));
            // }
        }

        .sibling-nav__title {
            font-size: $base-font-size;
            line-height: $h5-mobile-leading;
            @extend %font-primary-bold;
            margin-bottom: $gutter;
        }
    }

    // Event item styles
    &__navigation-item--facilities {
        .sibling-nav__item-content {
            @include respond-to(sm-min) {
                min-height: 160px;
            }

            &:after {
                bottom: 0;
                background-color: $color-04;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        .sibling-nav__value-col {
            position: relative;
            width: 48%;
            padding: 20px;
        }

        .sibling-nav__image {
            background-size: cover;
            padding-top: percentage(2 / 3);
        }

        .sibling-nav__meta-col {
            font-size: 0;
            padding: 20px 0;
        }

        .sibling-nav__tag {
            font-size: 10px;
            letter-spacing: 1.5px;
            line-height: 10px;
            padding: 3px;
        }

        .sibling-nav__title {
            font-size: $lead-mobile;
            line-height: $lead-mobile-leading;

            @include respond-to(sm-min) {
                font-size: $lead-desktop;
                line-height: $lead-desktop-leading;
            }

            @include respond-to(xs-max) {
                margin-top: $gutter / 2;
            }
        }

        .sibling-nav__opening-hours,
        .sibling-nav__address {
            @extend %has-icon;
            color: $color-11;
            font-size: $font-size-fine;

            &:before {
                font-size: $base-font-size;
            }

            @include respond-to(xs-max) {
                line-height: $lead-mobile;
            }
        }

        .sibling-nav__opening-hours {
            margin-top: $gutter / 2;

            @include respond-to(sm-min) {
                margin-top: $gutter;
            }
        }
    }
}
