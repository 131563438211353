%gallery-figure-tint-hover {
    &:after {
        opacity: 1;
    }
}

%z-index-transition {
    transition: opacity .35s ease, z-index 0s ease;
    transition-delay: 0s;
}


%gallery-img-hover {
    @include respond-to(md-min) {
        z-index: 7;
    }
}

%gallery-nth-2-img-hover {
    .gallery__img {
        @include respond-to(md-min) {
            left: -100%;
        }
    }
}

%gallery-nth-3-img-hover {
    .gallery__img {
        @include respond-to(md-min) {
            left: -200%;
        }
    }
}

%gallery-nth-4-img-hover {
    .gallery__img {
        @include respond-to(md-min) {
            left: -300%;
        }
    }
}

%gallery-caption-hover {
    background-color: $color-06;
}

%gallery-description-hover {
    opacity: 0;
}

%gallery-link-hover {
    margin-left: -80px;
    opacity: 1;
    visibility: visible;

    left: 40%;
}

%gallery-nth-hover-img {
    .gallery__img {
        @include respond-to(md-min) {
            width: 400%;
        }
    }
}

%gallery-nth {
    .gallery {
        &__img {
            @include respond-to(md-min) {
                transition: background-position .35s;
            }
        }
    }

    &.hover {
        @extend %gallery-nth-hover-img;
    }

    .non-touch-device & {
        &:hover {
            @extend %gallery-nth-hover-img;
        }
    }
}

%gallery-nth-1-img {
    @include respond-to(md-min) {
        background-position: left center;
    }
}

%gallery-nth-1 {
    @extend %gallery-nth;

    .gallery__img {
        @extend %gallery-nth-1-img;
    }
}

%gallery-nth-2 {
    @extend %gallery-nth;

    .gallery__img {
        @include respond-to(md-min) {
            background-position: left 33.333333% center;
            // background-position: left 25% center; // NM likey :)
        }
    }

    &.hover {
        @extend %gallery-nth-2-img-hover;
    }

    .non-touch-device & {
        &:hover {
            @extend %gallery-nth-2-img-hover;
        }
    }
}

%gallery-nth-3 {
    @extend %gallery-nth;

    .gallery__img {
        @include respond-to(md-min) {
            background-position: left 66.666667% center;
        }
    }

    &.hover {
        @extend %gallery-nth-3-img-hover;
    }

    .non-touch-device & {
        &:hover {
            @extend %gallery-nth-3-img-hover;
        }
    }
}

%gallery-nth-4 {
    @extend %gallery-nth;

    &.hover {
        @extend %gallery-nth-4-img-hover;
    }

    .non-touch-device & {
        &:hover {
            @extend %gallery-nth-4-img-hover;
        }
    }
}

%slick-active-hover {
    @extend %gallery-img-hover;

    .gallery {
        &__figure {
            @extend %gallery-figure-tint-hover;
        }

        &__caption {
            background-color: $color-06;
        }

        &__description {
            @extend %gallery-description-hover;
        }

        &__link {
            @extend %gallery-link-hover;
        }
    }
}

.component--gallery {
    overflow: hidden;
}

.gallery {
    @extend .cf;

    &__item {

        @include respond-to(sm-max) {
            display: none;
            margin-left: 8px;
            margin-right: 8px;
            width: calc(100vw - 64px);
        }

        .no-slick & {
            // @extend %gallery-nth;

            float: left;
            width: 100%;

            @include respond-to(md-min) {
                // display: block;
                width: 25%;
            }

            // one item
            &:nth-child(1):nth-last-child(1) {
                @include respond-to(md-min) {
                    width: 100%;
                }

                .gallery__figure {
                    @include respond-to(md-min) {
                        padding-top: 37.5%;
                    }
                }

                .gallery__img {
                    background-position: center;
                    background-size: cover;
                }

                &.hover,
                &:hover {
                    .gallery__img {
                        @include respond-to(md-min) {
                            width: 100%;
                        }
                    }
                }
            }

            // two items
            &:nth-child(1):nth-last-child(2),
            &:nth-child(2):nth-last-child(1) {
                @include respond-to(md-min) {
                    width: 50%;
                }

                .gallery__figure {
                    @include respond-to(md-min) {
                        padding-top: 75%;
                    }
                }

                &.hover,
                &:hover {
                    .gallery__img {
                        @include respond-to(md-min) {
                            width: 200%;
                        }
                    }
                }

                &:nth-child(2) {
                    .gallery__img {
                        @include respond-to(md-min) {
                            background-position: left 100% center;
                        }
                    }

                &.hover,
                    &:hover {
                        .gallery__img {
                            @include respond-to(md-min) {
                                left: -100%;
                            }
                        }
                    }
                }
            }

            // three items
            &:nth-child(1):nth-last-child(3),
            &:nth-child(2):nth-last-child(2),
            &:nth-child(3):nth-last-child(1) {
                @include respond-to(md-min) {
                    width: 33.3333%;
                }

                .gallery__figure {
                    @include respond-to(md-min) {
                        padding-top: 112.5%;
                    }
                }

                &.hover,
                &:hover {
                    .gallery__img {
                        @include respond-to(md-min) {
                            width: 300%;
                        }
                    }
                }

                &:nth-child(1) {
                    .gallery__img {
                        @include respond-to(md-min) {
                            background-position: left 0 center;
                        }
                    }
                }

                &:nth-child(2) {
                    .gallery__img {
                        @include respond-to(md-min) {
                            background-position: left 50% center;
                        }
                    }

                    &.hover,
                    &:hover {
                        .gallery__img {
                            @include respond-to(md-min) {
                                left: -200%;
                            }
                        }

                    &.hover,
                        &:hover {
                            .gallery__img {
                                @include respond-to(md-min) {
                                    left: -100%;
                                }
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    &.hover,
                    &:hover {
                        .gallery__img {
                            @include respond-to(md-min) {
                                left: -200%;
                            }
                        }
                    }
                }
            }

            // four items
            &:nth-child(1):nth-last-child(4),
            &:nth-child(2):nth-last-child(3),
            &:nth-child(3):nth-last-child(2),
            &:nth-child(4):nth-last-child(1) {
                &:nth-child(1) {
                    @extend %gallery-nth-1;
                }

                &:nth-child(2) {
                    @extend %gallery-nth-2;
                }

                &:nth-child(3) {
                    @extend %gallery-nth-3;
                }

                &:nth-child(4) {
                    @extend %gallery-nth-4;
                }
            }

            // gallery item hover
            &.hover,
            &:hover {
                .gallery {
                    &__figure {
                        @extend %gallery-figure-tint-hover;
                    }

                    &__img {
                        @extend %z-index-transition;

                        @extend %gallery-img-hover;
                    }

                    &__caption {
                        @extend %gallery-caption-hover;
                    }

                    &__description {
                        @extend %gallery-description-hover;
                    }

                    &__link {
                        @extend %gallery-link-hover;
                    }
                }
            }
        }

        // if slick initialized, then hide slides
        .slick-slider & {
            display: none;
        }
    }

    &__figure {
        padding-top: 75%;
        width: 100%;

        position: relative;

        @include respond-to(md-min) {
            padding-top: 150%;
        }

        @include respond-to(sm-max) {
            border-top: 4px solid $color-03;
        }

        .slick-slider & {
            z-index: 6;
        }

        &:after {
            /*background: rgba($color-06, .6);*/
            content: '';
            opacity: 0;
            height: 100%;
            width: 100%;
            z-index: 8;

            position: absolute;
            top: 0;
            left: 0;

            transition: opacity .35s;
        }
    }

    &__img {
        background-color: $color-03;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;

        // transition: all .35s;


        @include respond-to(md-min) {
            background-position: left 100% center;
            background-size: 944px 354px;
        }

        @include respond-to(lg-min) {
            background-size: 1124px 422px;
        }

        @include respond-to(xxl-min) {
            background-size: 1424px 534px;
        }

        .was-active & {
            @extend %gallery-nth-1-img;
        }
    }

    &__caption {
        background-color: $color-01;
        overflow: hidden;
        padding: 16px;

        position: relative;

        transition: background-color .35s;
    }

    &__title {
        margin-bottom: 16px;
        min-height: $h3-mobile-leading * 2;

        @include respond-to(md-min) {
            min-height: $h3-desktop-leading * 2;
        }

        @include respond-to(xxl-min) {
            font-size: $h2-desktop;
            line-height: $h2-desktop-leading;
            min-height: $h2-desktop-leading * 2;
        }
    }

    &__description {
        min-height: 120px;
        max-height: 120px;
        margin-bottom: 0;
        overflow: hidden;
        opacity: 1;

        transition: opacity .35s;
    }

    &__link {
        @extend %h6;
        @extend %font-primary-medium;

        // background-color: #ccc; // NOTE: for debugging
        color: $color-03;
        opacity: 0;
        padding-left: 30px;
        visibility: hidden;
        width: 200px;

        position: absolute;
        bottom: 64px;
        left: 10%;

        transition: .35s;

        .non-touch-device & {
            visibility: visible;
        }

        &:hover,
        &:focus {
            color: $color-03;
            text-decoration: none;
        }

        i {
            font-size: 24px;

            position: absolute;
            left: 0;
        }
    }

    &__counter {
        @extend %font-primary-bold;

        font-size: $font-size-fine;
        line-height: $font-leading-mobile;
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;

        @include respond-to(md-min) {
            display: none;
        }
    }

    &__dash,
    &__total {
        opacity: .5;
    }

    // When slick is initialized,,
    // more than 4 items
    .slick {
        &-list {
            overflow: visible;
        }

        &-slide {
            opacity: .3;

            // transition: .35s;
            @extend %z-index-transition;
        }

        &-arrow {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            height: 100%;
            width: 50vw;

            position: absolute;
            z-index: 7;
            top: 0;
        }

        &-disabled {
            cursor: default;
        }

        &-prev {
            text-align: left;

            left: -50vw;
        }

        &-next {
            text-align: right;

            right: -50vw;
        }

        &-active {
            opacity: 1;

            position: relative;
            z-index: 6;

            .non-touch-device & {
                &:hover {
                    @extend %slick-active-hover;
                }
            }

            &.hover {
                @extend %slick-active-hover;
            }
        }

        &-current {
            opacity: 1;

            .non-touch-device & {
                &:hover {
                    @extend %slick-active-hover;
                }
            }

            &.hover {
                @extend %slick-active-hover;
            }
        }
    }

    //START PRINT STYLES
    @media print {
        // Show Only Active Slide
      .slick-slide {
          display: block !important;
          opacity: 1 !important;
          padding-bottom: 40px !important;
      }

      // Reset adaptiveHeight
      .slick-list {
          height: auto !important;
      }

      // Remove Scrollbars
      .slick-track {
          width: auto !important;
          height: auto !important;
          transform: none !important;
      }

      // set images in gallery to display none
      //_gallery.scss: 398
      &__figure {
        display: none !important;
      }
      &__item
      {
          width: 30% !important;
          border-top: 5px solid #000 !important;
          margin-right: 10px !important;
          padding-bottom: 40px !important;
      }
      &__counter {
          display: none !important;
      }
    }
}


.active {
    &-1 { @extend %gallery-nth-1; }
    &-2 { @extend %gallery-nth-2; }
    &-3 { @extend %gallery-nth-3; }
    &-4 { @extend %gallery-nth-4; }
}
