.template {
    @extend %cf-dashboard-nav;

    &__content {
        @extend %has-dashboard-nav;
        @extend .cf;

        // NOTE: using before for tint as after is used
        //    to clear floats
        &:before {
            background-color: rgba(0, 0, 0, .4);
            content: '';
            opacity: 0;
            visibility: hidden;

            position: absolute;
            z-index: 98;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            @include transition(opacity .45s ease-in-out);
        }

        &--tinted {
            // height: 100vh;
            overflow: hidden;

            &:before {
                opacity: 1;
                visibility: visible;

                @include transition(opacity .45s ease-in-out);
            }
        }
    }

    &--guide {
        .component--row {
            .col-sm-12 {
                @include respond-to(sm-min) {
                    margin-left: calc(#{$col-04} + #{$gutter-sm});
                    width: $col-07;
                }

                @include respond-to(md-min) {
                    width: $col-06;
                }

                @include respond-to(lg-min) {
                    width: $col-06;
                }
            }
        }
    }
}