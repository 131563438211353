// =============================================================================
// Map
// =============================================================================

$zoom-control: 32px;

.map {
    background: $color-03;
    padding-top: 75%;

    position: relative;

    @include respond-to(sm-min) {
        padding-top: 46.09375%;
    }

    > div {
        top: 0;
    }

    [class^='col-'] & {
        @include respond-to(sm-min) {
            padding-top: 75%;
        }
    }
}


// InfoBox:
.ib {
    background-color: $color-01;

    &__content {
        padding: $gutter;
    }

    &__text {
        margin: 0 0 $gutter;
    }

    &:after {
        content: '';
        border-left: $gutter solid transparent;
        border-right: $gutter solid transparent;
        border-top: $gutter solid $color-01;
        margin-left: -$gutter;
        position: absolute;
        bottom: -$gutter;
        left: 50%;
    }
}

// Zoom Controls:
.gm {
    &__zoom {
        margin-right: 8px;
        margin-bottom: 8px;
        width: $zoom-control;

        &-in,
        &-out {
            border: 0;
            border-radius: 0;
            background-color: $color-01;
            font-size: 0;
            padding: 0;
            height: $zoom-control;
            width: $zoom-control;

            position: relative;

            &:before {
                font-size: $lead-desktop;
                line-height: $zoom-control;
                text-align: center;
                width: 100%;

                position: relative;
                z-index: 6;
            }

            &:after {
                background-color: $color-06;
                content: '';
                height: 100%;
                width: 0;

                position: absolute;
                z-index: 5;
                top: 0;
                left: 0;

                transition: width .3s;
            }

            &:focus,
            &:hover {
                &:after {
                    width: 100%;
                }
            }

            &:disabled {
                opacity: .6;

                &:focus,
                &:hover {
                    &:after {
                        width: 0;
                    }
                }
            }
        }

        &-in {
            &:before { content: '+'; }
        }

        &-out {
            &:before { content: '-'; }
        }
    }
}

