// =============================================================================
//  COMMON (SASS Place Holders & Mixins for repeated CSS)
// =============================================================================

// ------------------------------------
//  Placeholder
// ------------------------------------

// clear appearance iOS
// ------------------------------------
%clear-default-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

// Typography:
// ------------------------------------

%reset-font {
    font-size: $base-font-size;
    line-height: $base-leading;
}

// Gotham Narrow Book (Primary font)
%font-primary {
    font-family: $font-family-primary;
    font-style: normal;

    &-light {
        font-weight: 300;
    }

    &-book,
    &-normal {
        font-weight: 400;
    }

    &-medium {
        font-weight: 500;
    }

    &-bold {
        font-weight: 700;
    }

    &-black {
        font-weight: 800;
    }
}

// Chronicle Text Roman
%font-secondary {
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: 400; // NOTE: this is here as there is now other variant of the font
}

// heading (default)
%heading-default {
    @extend %font-primary;
}

%heading-uppercase {
    text-transform: uppercase;
}

%heading-lowercase {
    text-transform: none;
}

%typography-last {
    &:last-child {
        margin-bottom: 0 !important;
    }
}

%h0 {
    font-size: $h0-mobile;
    line-height: $h0-mobile-leading;
    margin: 0 0 16px;
    padding: 0;

    @include respond-to(md-min) {
        font-size: $h0-desktop;
        line-height: $h0-desktop-leading;
        margin: 0 0 32px;
    }
}

%h1 {
    font-size: $h1-mobile;
    line-height: $h1-mobile-leading;
    margin: 0 0 16px;
    padding: 0;

    @include respond-to(md-min) {
        font-size: $h1-desktop;
        line-height: $h1-desktop-leading;
        margin: 0 0 32px;
    }
}

%h2 {
    font-size: $h2-mobile;
    line-height: $h2-mobile-leading;
    margin: 0 0 16px;
    padding: 0;

    @include respond-to(md-min) {
        font-size: $h2-desktop;
        line-height: $h2-desktop-leading;
        margin: 0 0 32px;
    }
}

%h3 {
    font-size: $h3-mobile;
    line-height: $h3-mobile-leading;
    margin: 0 0 16px;
    padding: 0;

    @include respond-to(md-min) {
        font-size: $h3-desktop;
        line-height: $h3-desktop-leading;
        margin: 0 0 32px;
    }
}


%h4 {
    @extend %font-primary-book;
    font-size: $h4-mobile;
    line-height: $h4-mobile-leading;
    margin: 0 0 10px;
    padding: 0;

    @include respond-to(md-min) {
        font-size: $h4-desktop;
        line-height: $h4-desktop-leading;
        margin: 0 0 16px;
    }
}

%h6 {
    font-size: $h6-mobile;
    line-height: $h6-mobile-leading;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 10px;

    @include respond-to(md-min) {
        font-size: $h6-desktop;
        line-height: $h6-desktop-leading;
        margin: 0 0 16px;
    }
}

%blockquote {
    @extend %h3;
    text-transform: none;

    border-top: 1px solid $color-08;
    border-bottom: 1px solid $color-08;
    color: $color-02;
    display: block;
    font-style: italic;
    margin: 0 0 33px;
    padding: 34px ($gutter * 2) 38px;

    @include respond-to(sm-min) {
        margin-left: 9.836066%;
        margin-right: 9.836066%;
    }
}

//Layout


%clear {
    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

// Lists
%list {
    @extend %typography-last;
    @include background-context-dark { color: $color-01; }

    list-style: none;
    margin: 0 0 32px; // calc: 40px (Zeplin) - 7px (line height bottom current li)
    padding: 0;

    li {
        @extend %typography-last;

        // margin: 0 0 8px; // calc: 20px (Zeplin) - 7px (line height bottom current li) - 5px (line height top next li)
        margin: 0;
        // padding: ($base-leading / 2) 0;

        &:before {
            display: inline-block;
            font-size: $h5-mobile;
            font-weight: bold;
            text-align: center;
            width: $icon-spacing;
        }

        ul,
        ol {
            list-style: none;
            padding-left: $icon-spacing;
        }
    }
}

%list-item {
    padding: ((56px - $base-leading) / 2) 0;
}

%list--ol {
    // REF: https://css-tricks.com/numbering-in-style/
    counter-reset: number-counter;


    ol { counter-reset: number-counter; } // This is for nested sublist

    li {
        &:before {
            content: counter(number-counter);
            counter-increment: number-counter;
        }
    }
}

%list--ul {
    li {
        &:before {
            content: '\2022'; // NOTE: this is ISO (in CSS content) for '.' REF: https://css-tricks.com/snippets/html/glyphs/
        }
    }
}

// Icon:
// ------------------------------------
%has-icon {
    padding-left: $icon-spacing;
    // padding: 0 0 0 $icon-spacing;

    position: relative;

    &:before {
        position: absolute;
        left: 0;
    }
}

%has-icon-after {
    @extend %list-item;

    padding-right: $icon-spacing * 2;
    // padding: 0 ($icon-spacing * 2) 0 0;

    position: relative;

    &:before {
        // background-color: rgba(#fc0, .2);
        margin-top: -($base-leading / 2);

        position: absolute;
        top: 50%;
        right: 16px;

        @include transition(right .25s ease);
    }

    &:focus,
    &:hover {
        &:before {
            right: 0;

            // @include transition(right .25s ease);
        }
    }
}

%link-with-icon {
    @extend %list-item;
    position: relative;

    i {
        margin-top: -($base-leading / 2);
        position: absolute;
        top: 50%;
        right: 16px;

        @include transition(right .25s ease);
    }

    a {
        padding-right: $icon-spacing * 2;

        &:focus,
        &:hover {
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.05); //fixing a safari artifacts bug by forcing entire link to re-draw

            + i {
                right: 0;
            }
        }
    }
}

// Buttons:
// ------------------------------------
%text-btn {
    @extend %list-item;

    color: $color-03;
    display: block;
    text-decoration: none;

    position: relative;

    &:focus,
    &:hover {
        color: $color-03;
        text-decoration: none;

        &:after {
            // width: calc(100% + #{$icon-spacing * 2});
            width: 100%;
        }
    }

    &:before,
    &:after {
        content: '';
        height: 1px;

        position: absolute;
        bottom: 0;
        left: 0;
    }

    &:before {
        background-color: $color-09;
        // width: calc(100% + #{$icon-spacing * 2});
        width: 100%;
    }

    &:after {
        background-color: $color-06;
        height: 2px;
        width: 0;

        transition: width .3s;

        .bg--red &,
        .bg--orange & {
            background-color: $color-01;
        }
    }
}

// Dropdowns:
// ------------------------------------
%drop-down-caret {
    &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: $fc-chevron-height solid $color-03;
        content: '';
        margin-top: -$fc-chevron-height;
        pointer-events: none;

        position: absolute;
        top: 50%;
        right: 8px;
    }
}


// Has Dashboard nav:
// ------------------------------------
%has-dashboard-nav {
    .component--global-nav ~ .template &,
    .component--global-nav ~ .component--footer &,
    .component--global-nav ~ .component--pagination & {
        @include respond-to(xl-min) { // NOTE: was lg
            float: right;
            width: calc(100% - #{$gn-width});
        }

        @include respond-to(xxl-min) {
            float: right;
            width: calc(100% - #{$gn-width-hd});
        }
    }
}

// Clear float in the 'xl' breakpont,
// to accomodate visible dashboard/global nav
%cf-dashboard-nav {
    &:after {
        @include respond-to(xl-min) {
            content: '';
            clear: both;
            display: block;
        }
    }
}


//  link, button hover
// ------------------------------------
%hover {
    // transition: all .4s ease-in;
    transition: all .3s;
}

%inpage-link {
    a {
        @include background-context-dark { color: $color-01; }
        display: inline-block;
        text-decoration: underline;

        position: relative;

        &:focus,
        &:hover {
            color: $color-03;

            &:after {
                width: 100%;
            }
        }

        &:visited {
            color: $color-02;

            &:focus,
            &:hover {
                color: $color-03;
            }
        }

        span {
            position: relative;
            z-index: 2;
        }

        i {
            font-size: 0.8em;
        }

        &:after {
            @extend %hover;

            background-color: $color-06;
            content: '';
            height: 100%;
            width: 0;

            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;

            .bg--orange &,
            .bg--red & {
                background-color: $color-01;
            }
        }
    }
}

// ------------------------------------
//  Mixins for repetitive CSS
// ------------------------------------
// @mixin normal { font-weight: $wt-normal; }
// @mixin semi-bold { font-weight: $wt-semi-bold; }
// @mixin bold { font-weight: $wt-bold; }
