// =============================================================================
// 	WSU Theme
// =============================================================================

// List of colours used:
// NOTE: to prevent colour from being picked up by the pigments plugin,
//    use '/' before the name
$color-01: #fff; // /White
$color-02: #903; // /Crimson
$color-03: #262223; // /Black
$color-04: #f2f2f2; // Off /White
$color-05: #ed0033; // /Red
$color-06: #ff5c5e; // /Orange
$color-07: #c0392b; // /Orange 2 (error and alerts states)
$color-08: #c4c2c3; // Light /Grey
$color-09: #d3d2d2; // Light /Grey 2
$color-10: #898687; // Mid /Grey
$color-11: #666; // Mid /Grey 2
$color-12: #3a3537; // Dark /Grey
$color-13: #639; // /Purple
$color-14: #069; // /Teal
$color-15: #f66; // Button Hover ?
$color-16: #f3dfdd; // Notification bg

$color-17: #007a4b; // Status - complete
$color-18: #d91e18; // Status - overdue

// family:
$font-family-primary: 'Gotham Narrow A', 'Gotham Narrow B';
$font-family-secondary: 'Chronicle Text G1 A', 'Chronicle Text G1 B';

// weights: wt prefix used for all weight variables
// $wt-normal: 400;
// $wt-semi-bold: 600;
// $wt-bold: 800;

// sizes:
// NOTE: line height = base line height / base font
//		 1.5 = 24 / 16

// --fine:
$font-size-fine: 12px; // used for labels

// --base: base prefix used for all base variables
// NOTE: Same for Desktop & Mobile
$base-font-size: 16px;
$base-leading: 24px;

$base-computed-leading: $base-leading / $base-font-size; // TODO: remove?

$font-size-mobile: 14px;
$font-leading-mobile: 16px;

// --h0:
$h0-desktop: 96px;
$h0-desktop-leading: 88px;
$h0-mobile: 56px;
$h0-mobile-leading: 48px;

// --h1:
$h1-desktop: 48px;
$h1-desktop-leading: 56px;
$h1-mobile: 32px;
$h1-mobile-leading: 40px;

// --h2:
$h2-desktop: 32px;
$h2-desktop-leading: 40px;
$h2-mobile: 24px;
$h2-mobile-leading: 32px;

// --h3:
$h3-desktop: 24px;
$h3-desktop-leading: 32px;
$h3-mobile: 20px;
$h3-mobile-leading: 24px;

// --h4: TODO: needs to be updated for Phase II
$h4-desktop: 16px;
$h4-desktop-leading: 20px;
$h4-mobile: 16px;
$h4-mobile-leading: 20px;

// --h5: TODO: needs to be updated for Phase II
$h5-desktop: 14px;
$h5-desktop-leading: 16px;
$h5-mobile: 14px;
$h5-mobile-leading: 16px;

// --h6: TODO: needs to be updated for Phase II
$h6-desktop: 14px;
$h6-desktop-leading: 16px;
$h6-mobile: 14px;
$h6-mobile-leading: 16px;

// Featured text
$featured-desktop: 28px;
$featured-desktop-leading: 38px;
$featured-mobile: 22px;
$featured-mobile-leading: 30px;

// Intro/Lead text
$lead-desktop: 20px;
$lead-desktop-leading: 32px;
$lead-mobile: 18px;
$lead-mobile-leading: 24px;

// Buttons TODO: remove below ------|
$button-desktop: 12px;
$button-desktop-leading: 16px;
$button-mobile: 12px;
$button-mobile-leading: 16px;
// Buttons TODO: remove above ------|

// NOTE: LINE HEIGHT SPACING
// ---------------------------
// fine top: 4px <
// fine bottom: 3px <

// base top: 5px (Based on Chrome & IE, 6px in FF)
// base bottom: 7px (Based on Chrome & IE, 6px in FF)

// h1 mobile top: 10px
// h1 mobile bottom: 10px
// h1 desktop top: 18px
// h1 desktop bottom: 18px

// h2 top: 6px
// h2 bottom: 5px
// h2 desktop top: 9px
// h2 desktop bottom: 8px

// h3 top: 6px <
// h3 bottom: 7px <

// ICON
// ---------------------------
$icon-spacing: 32px;

// GUTTERS
// ---------------------------
$vertical-gutter-mobile: 20px;
$vertical-gutter-desktop: $vertical-gutter-mobile * 2;

// DASHBOARD
// ---------------------------
// $dash prefix used for all dashboard variables
$dash-border: .5px;

// GLOBAL NAV
// ---------------------------
// $gn prefix used for all global nav variables
$gn-width: 260px;
$gn-width-hd: 260px;

// iPHONE
// ---------------------------
$iphone-safari-nav-height: 69px; // NOTE: 44px is supposed to be the height, but actual is

// Nav Button / Trigger metrics
// ---------------------------
$nav-trigger: 50px;
$nav-trigger-width: 20px;
$nav-trigger-line: 2px;
$nav-trigger-line-distance: 6px;
$nav-trigger-pad-top: ($nav-trigger - ($nav-trigger-line * 3) - ($nav-trigger-line-distance * 2)) / 2;

// FORM CONTROL CARET
// ---------------------------
$fc-chevron-height: 5px;


//footer
$icon-social-width: 56px;
$icon-social-height: 56px;


// swiper for mini-cards
//$mini-card-height: 224px;
//$mini-cards-box-width: 3 * ($mini-card-height + 16px);
//$mini-card-scroll-bar-height: 1px;

