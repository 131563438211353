@import 'components/core/variables';
@import 'components/core/respond-to';
@import 'components/core/background-context';
@import 'components/helpers/mixins';
@import 'components/core/grid';

@import 'components/helpers/sassy-cast';

@import 'components/core/placeholders';
@import 'components/core/animations';
@import 'components/helpers/normalize';
@import 'components/responsive-manager/responsive-manager';

@import 'components/core/background';
@import 'components/core/colors';
@import 'components/core/component';
@import 'components/core/vertical-gutter';

@import 'components/icomoon/icomoon';

@import 'components/core/core';
@import 'components/core/typography';
@import 'components/core/typography-shared';

// form element
@import 'components/form-controls/form-controls';

@import 'components/accordion/accordion';
@import 'components/acc-nav/acc-nav';
@import 'components/anchor-target/anchor-target';
@import 'components/author/author';
@import 'components/banner/banner';
@import 'components/button/button';
@import 'components/carousel/carousel';
@import 'components/checklist/checklist';
@import 'components/contact/contact';
@import 'components/course-compare/course-compare';
@import 'components/dashboard/dashboard-card';
@import 'components/dashboard/dashboard-template';
@import 'components/divider/divider';
@import 'components/event-detail/event-detail';
@import 'components/filter-tabs/filter-tabs';
@import 'components/facility-detail/facility-detail';
@import 'components/footer/footer';
@import 'components/hero/hero';
@import 'components/list/list';
@import 'components/main-banner/main-banner';
@import 'components/main-nav/main-nav';
@import 'components/map/map';
@import 'components/nav/global-nav';
@import 'components/nav/sibling-nav';
@import 'components/notification/notification';
@import 'components/pagination/pagination';
@import 'components/rich-text/rich-text';
@import 'components/search/search';
@import 'components/step-status/step-status';
@import 'components/search-results/search-results';
@import 'components/tags/tags';
@import 'components/tile/tile';
@import 'components/tile-carousel/tile-carousel';
@import 'components/tile-listing/tile-listing';
@import 'components/timetable/timetable-template';
@import 'components/title/title';
@import 'components/unit-selection-guide/unit-selection-guide';
@import 'components/video/video';
@import 'components/page-banner/page-banner';
@import 'components/pagination-list/pagination-list';
@import 'components/breadcrumb/breadcrumb';
@import 'components/tabs/tabs';
@import 'components/gallery/gallery';

@import 'components/core/themes';
@import 'components/core/templates';

@import 'vendors/blazy/blazy';
@import 'vendors/jquery-slick/slick';

@import 'components/utils/utils';
@import 'components/core/authoring-mode';

@import 'components/page-404/page-404';
@import 'components/mini-cards/mini-cards';
@import 'components/cols-2/cols-2';
@import 'components/sidepanel/sidepanel';
@import 'components/action-bar/action-bar';
@import 'components/helpers/print';

#livechat-compact-container {
	display: none;
}
