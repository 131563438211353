// =============================================================================
// Carousel: Image
// =============================================================================

// ic prefix used for all image carousel variables
$ic-btn-height-mobile: 56px;

$ic-btn-height: 64px;
$ic-btn-height-hover: 72px;
$ic-bar-pad-top: 16px;

$ic-btn-in-line: ($ic-btn-height * 2) + $ic-btn-height;

%ic-btn {
    height: $ic-btn-height-mobile;
    line-height: $ic-btn-height-mobile;
    width: $ic-btn-height-mobile;

    @include respond-to(sm-min) {
        height: $ic-btn-height;
        line-height: $ic-btn-height;
        width: $ic-btn-height;
    }
}

.component--carousel {
    position: relative;
}

.carousel {
    background-color: $color-01;
    border-bottom: 1px solid $color-09;

    @include respond-to(xs-max) {
        padding-bottom: $ic-btn-height-mobile;
    }

    &--single-slide {
        border-bottom: none;
    }

    &__img {
        max-width: 100%;
        width: 100%;
    }

    &__bar {
        width: 100%;

        @include respond-to(sm-min) {
            width: calc(100% - #{$ic-btn-in-line});
        }
    }

    &__caption {
        color: $color-11;
        font-size: $font-size-mobile;
        line-height: $font-leading-mobile;
        padding: (($ic-btn-height-mobile - $font-leading-mobile) / 2) 16px;

        @include respond-to(sm-min) {
            padding: (($ic-btn-height - $font-leading-mobile) / 2) 16px;
        }
    }

    &__counter {
        @extend %font-primary-bold;
        @extend %ic-btn;

        background-color: $color-01;
        border-bottom: 1px solid $color-09;
        color: $color-11;
        display: block;
        font-size: $font-size-fine;
        text-align: center;

        position: absolute;
        right: 0;
        bottom: 0;

        @include respond-to(xs-max) {
            height: $ic-btn-height-mobile;
        }

        .carousel--single-slide & {
            display: none;
        }
    }

    &__current-slide {
        color: $color-03;
    }
}

// slick JS elements:
.slick {

    &-arrow {
        .component--carousel & {
            @extend %ic-btn;
            display: none;
            background-color: $color-01;
            border: 0;
            font-size: 0;
            padding: 0;

            position: absolute;

            @include respond-to(sm-min) {
                bottom: 0;
            }

            &:before {
                font-size: $base-font-size;

                position: relative;
                z-index: 7;
            }

            &:after {
                background-color: $color-06;
                content: '';
                height: $ic-btn-height-mobile;
                width: 0;
                position: absolute;
                z-index: 6;
                top: 0;
                left: 0;
                transition: width .3s;

                @include respond-to(sm-min) {
                    height: $ic-btn-height;
                }
            }

            &:hover {
                &:after {
                    width: $ic-btn-height-mobile;

                    @include respond-to(sm-min) {
                        width: $ic-btn-height;
                    }
                }
            }
        }
    }

    &-prev {
        .component--carousel & {
            z-index: 7;

            @include respond-to(xs-max) {
                right: $ic-btn-height-mobile * 2;
                bottom: 0;
            }

            @include respond-to(sm-min) {
                right: $ic-btn-height + $ic-btn-height;
            }
        }
    }

    &-next {
        .component--carousel & {
            @include respond-to(xs-max) {
                right: $ic-btn-height-mobile;
                bottom: 0;
            }

            @include respond-to(sm-min) {
                border-right: 1px solid rgba($color-01, .8);
                right: $ic-btn-height;
            }
        }
    }

    &-disabled { opacity: .3; }

}