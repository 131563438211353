$tt-day-height: 40px;

$tt-clock-height: 42.1875%;

.timetable {
    font-size: 0;
    position: relative;

    &__left,
    &__right {

        @include respond-to(sm-min) {
            display: inline-block;
            vertical-align: top;
            width: 50%;
        }
    }

    &__left {
        height: 100%;

        position: relative;

        @include respond-to(xs-max) {
            display: none;
        }

        &-inner {
            height: 100%;

            @include respond-to(sm-min) {
                display: block;
                width: 50%;

                position: fixed;
                top: 0;
                left: 0;
            }

            @include respond-to(xl-min) { // NOTE: was lg
                width: calc((100% - #{$gn-width}) / 2);

                left: $gn-width;
            }

            @include respond-to(xxl-min) {
                width: calc((100% - #{$gn-width-hd}) / 2);

                left: $gn-width-hd;
            }
        }
    }

    &__right {
        @include respond-to(xs-max) {
            background-color: $color-12;
            padding-top: $dc-pane;
        }

        @include respond-to(sm-min) {
            min-height: 100vh;

            position: relative;
        }

        &-inner {
            background-color: $color-01;
        }

        &:before {
            @include respond-to(sm-min) {
                box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
                content: '';
                height: 100%;
                width: 3px;

                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
            }
        }
    }

    &__clock {
        background-color: $color-02;
        color: $color-01;

        @include respond-to(sm-min) {
            padding-top: 63.28125%; // at 100% width

            position: relative;
        }

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &-frame {
            display: table;
            height: 100%;
            width: 100%;
        }

        &-face {
            display: table-cell;
            padding: scale-pixel-value($gutter * 4) scale-pixel-value($gutter * 6);
            vertical-align: bottom;

            &-inner {
                display: inline-block;
            }
        }

        &-caption {
            @include scale-typography($base-font-size, $base-leading);
            letter-spacing: .3px;
            text-transform: uppercase;
        }

        &-date {
            float: right;

            &-sup {
                text-transform: lowercase;
            }
        }

        &-hours,
        &-period {
            display: inline-block;
            vertical-align: top;
        }

        &-hours {
            @include scale-typography(120px, 144px);
        }

        &-period {
            @include scale-typography($h4-desktop, $h4-desktop-leading);
            padding-top: scale-pixel-value($gutter * 2);
        }
    }

    &__detail {

        &-inner {
            padding: scale-pixel-value($gutter * 4) scale-pixel-value($gutter * 6);
        }

        &-title {
            @include scale-typography($h4-desktop, $h4-desktop-leading);
            margin: 0 0 scale-pixel-value($gutter * 3);
        }

        &-duration,
        &-location,
        &-address {
            padding-left: scale-pixel-value($base-leading);

        }

        &-duration,
        &-location {
            @include scale-typography($base-font-size, $base-leading);
            display: block;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
            }
        }

        &-location {
            &:hover {
                text-decoration: none;
            }
        }

        &-address {
            @include scale-typography($font-size-mobile, $font-leading-mobile);
            width: 80%;
        }
    }

    &__block {
        font-size: $base-font-size;
        line-height: $base-leading;

        position: relative;

        &-head {
            height: $tt-day-height;
            width: 100%;

            // NOTE: for browsers that support 'sticky'
            .sticky-supported & {
                position: -webkit-sticky;
            	position: -moz-sticky;
            	position: -ms-sticky;
            	position: -o-sticky;
            	position: sticky;
                z-index: 9;
                top: 0;
            }
        }

        &-inner {
            background-color: $color-01;
            border-bottom: 1px solid $color-04;
            color: $color-11;
            line-height: $h3-desktop-leading;
            padding: (($tt-day-height - $h3-desktop-leading) / 2) ($gutter * 2) ((($tt-day-height - $h3-desktop-leading) / 2) - 1);
            width: 100%;
            position: relative;
            z-index: 7;

            &--fixed {
                position: fixed;
                z-index: 6;
                top: 0;
            }

            &--was-fixed {
                position: absolute;
                z-index: 6;
                bottom: 0;
            }
        }

        &-day {
            display: inline-block;
            text-transform: capitalize;

            position: relative;

            &:after {
                background-color: $color-11;
                content: '';
                height: 3px;
                width: 38px;

                position: absolute;
                left: 0;
                bottom: -2px;

                .timetable__block:first-child & {
                    background-color: $color-05;
                }
            }
        }

        &-date {
            text-transform: capitalize;
        }

        &-body {
            padding: 0 ($gutter * 2) $gutter;
        }

        &-link {
            @extend .cf;
            display: block;

            position: relative;

            &:before {
                background-color: $color-05;
                content: '';
                height: 100%;
                width: 0;

                position: absolute;
                top: 0;
                left: -20px;

                @include transition(width .3s ease);
            }

            &--selected {
                &:before {
                    width: 6px;

                    @include transition(width .3s ease);
                }
            }
        }

        &-time,
        &-details {
            float: left;
            padding-top: $gutter * 3;
            padding-bottom: $gutter * 3;
        }

        &-time {
            color: $color-11;
            width: 35%;

            @include respond-to(sm-min) {
                width: 15%;
            }
        }

        &-hours,
        &-period {
            display: inline-block;
            vertical-align: top;
        }

        &-hours {
            font-size: $lead-mobile;
        }

        &-period {
            font-size: $font-size-fine;
            line-height: $font-size-fine;
        }

        &-details {
            border-bottom: 1px solid $color-04;
            width: 65%;

            @include respond-to(sm-min) {
                width: 85%;
            }
        }

        &-title {
            color: $color-03;
            display: block;
            font-size: $lead-mobile;
            margin-bottom: 20px;
        }

        &-duration,
        &-building,
        &-address {
            @extend %has-icon;
            color: $color-11;
            display: block;
        }

        &-building {
            a.timetable__block-link & {
                color: $color-05;
            }

            a.timetable__block-link:hover & {
                color: $color-15;
            }
        }

        &-address {
            font-size: $font-size-mobile;
            line-height: $font-leading-mobile;
        }
    }

    &__map {
        background: $color-03;
        height: 100%;
        width: 100%;

        position: fixed;
        z-index: 99; // NOTE: needs to be higher than the global nav menu trigger
        bottom: -100%;
        left: 0;

        @include transition(bottom .6s ease);

        @include respond-to(sm-min) {
            width: 50%;
        }

        @include respond-to(xl-min) { // NOTE: was lg
            width: calc((100% - #{$gn-width}) / 2);

            left: $gn-width;
        }

        @include respond-to(xxl-min) {
            width: calc((100% - #{$gn-width-hd}) / 2);

            left: $gn-width-hd;
        }

        &--visible {
            bottom: 0;

            @include transition(bottom .6s ease);
        }

        &-area {
            height: 100%;
        }

        &-card {
            padding: $gutter-xs;
            width: 100%;

            position: absolute;
            top: 0;
            left: 0;

            @include respond-to(xs-max) {
                background-color: $color-04;
            }
        }

        &-close {
            background-color: $color-04;
            // background-color: rgba(#fc0, .2);
            border: 0;
            color: $color-02;
            // padding: 0;

            position: absolute;
            top: $gutter-xs;
            right: $gutter-xs;

            @include respond-to(sm-min) {
                border: 1px solid $color-08;
            }

            &:before {
                content: '\00d7';
                display: block;
                font-size: 25px;
            }
        }

        &-title {
            margin: 0 0 $gutter;

            @include respond-to(sm-min) {
                display: none;
            }
        }

        &-location {
            font-size: $font-size-mobile;
            line-height: $font-leading-mobile;

            @include respond-to(sm-min) {
                display: none;
            }
        }
    }
}
