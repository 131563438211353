// =============================================================================
// B-Lazy (Lazy loader)
// =============================================================================

// b-lazy: lzy prefix used for all b-lazy variables
$lzy-bg: #f1f1f1;

.b-lazy {
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;

    @include transition(opacity 800ms ease-in-out 100ms);

    &.b-loaded { opacity: 1; }
}

.loading {
    // background: $lzy-bg url('../images/loading.gif') no-repeat center;
    // background-size: 30px 20px;
}

.error {
    // background: $lzy-bg url('../images/broken-image.png') no-repeat center;
    // background-size: 32px 32px;
}
