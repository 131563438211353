// $dc prefix used for all dashboard card variables
$dc-cta: 36px;
$dc-pane: 50px;

$dc-half: 42.6%;
$dc-half-computed: 217px; // NOTE: based on iPad emulation in Chrome = 217.25px

// Full card with offset from top
$dc-full-offset-top: 34.8%; // use 35%?
$dc-full-offset-top-computed: 116px; // NOTE: based on iPad emulation in Chrome = 116.047px

$dc-clock: 63.28125%; // at 100% width

.component--dashboard-card {
    bottom: 0;
    left: 0;
    margin-top: 0;
    right: 0;
    top: 0;
    position: absolute;

    @include respond-to(sm-min) { border: $dash-border solid $color-04; }

    // When card is inside timetable
    .timetable & {
        position: relative;
        height: 100%;
    }
}

.dashboard {
    position: relative;

    &-card {
        background-position: center;
        background-size: cover;
        height: 100%;
        position: relative;

        // TODO: add this class if AJAX request is unsuccessful
        &.timetable-error,
        &.timetable-no-classes {
            &:before,
            &:after {
                position: absolute;
                z-index: 99999;
                top: 0;
                left: 0;
            }

            &:before {
                background-color: rgba($color-01, .7);
                content: '';
                height: 100%;
                width: 100%;
            }

            &:after {
                color: $color-03;
                font-size: $lead-mobile;
                line-height: $lead-mobile-leading;
                text-align: center;
                width: 80%;

                top: 50%;
                left: 10%;

                @include transform(translateY(-50%));
            }
        }

        &.timetable-error {
            &:after {
                // Adding line break in CSS 'content'
                // ref: http://stackoverflow.com/questions/17047694/add-line-break-to-before-or-after-pseudo-element-content
                content: 'We can\'t connect to your timetable right now. \A Please check back later.';
                white-space: pre-line;
            }
        }

        &.timetable-no-classes {
            &:after { content: 'You have no more classes for this session.'; }
        }

        &__image,
        &__clock {
            position: absolute;
            // z-index: 89;
            top: 0;
            right: 0;
            left: 0;

            .dashboard-card--full & { bottom: 0; }
            .dashboard-card--half & {
                height: scale-pixel-value($dc-half-computed);
            }
        }

        &__image {
            &:after {
                content: '';

                position: absolute;
                // z-index: 89;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                .tint--black & { background-color: rgba($color-03, .8); }
                .tint--crimson & { background-color: rgba($color-02, .8); }
                .tint--purple & { background-color: rgba($color-13, .8); }
                .tint--teal & { background-color: rgba($color-14, .8); }
            }
        }

        &__clock {
            color: $color-01;
            display: table;
            width: 100%;

            .timetable & {
                display: block;
                height: auto;
                padding-top: $dc-clock;

                position: relative;
            }

            &-face {
                display: table-cell;
                padding: scale-pixel-value($gutter * 2);
                vertical-align: bottom;

                .timetable & {
                    // background-color: rgba(#fc0, .2);
                    display: table;
                    padding: 0;
                    // padding: scale-pixel-value(77px) scale-pixel-value(65px);
                    // width: 100%;

                    position: absolute;
                    // bottom: 0;
                    // left: 0;
                    top: 50%;
                    left: 50%;

                    @include transform(translate(-50%, -50%));

                    // Firefox only styles
                    // ref: https://css-tricks.com/snippets/css/css-hacks-targeting-firefox/
                    @-moz-document url-prefix() {
                        width: 76%;

                        @include respond-to(lg) {
                            width: 68%;
                        }
                    }
                }

                &-inner {
                    display: inline-block;
                    width: 100%;

                    .timetable & {
                        display: table-cell;
                        vertical-align: bottom;
                    }
                }
            }

            &-caption {
                @include scale-typography($base-font-size, $base-leading);
                letter-spacing: .3px;
                text-transform: uppercase;

                .timetable & {
                    @include scale-typography($lead-desktop, $lead-desktop-leading);
                }
            }

            &-date {
                // float: right;
                margin-left: 10%;

                .timetable & {
                    float: right;
                }

                &-sup {
                    text-transform: lowercase;
                }
            }

            &-hours,
            &-period {
                display: inline-block;
                vertical-align: top;
            }

            &-hours {
                @include scale-typography(72px, 92px);

                .timetable & {
                    @include scale-typography(136px, 144px);
                }
            }

            &-period {
                @include scale-typography($h4-desktop, $h4-desktop-leading);
                padding-top: scale-pixel-value($gutter);

                .timetable & {
                    @include scale-typography(38px, 44px);
                }
            }
        }

        &__content {
            position: absolute;
            // z-index: 90;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            .dashboard-card--deck & {
                top: scale-pixel-value($gutter * 2);
                right: scale-pixel-value($gutter * 2);
                bottom: scale-pixel-value($gutter * 2);
                left: scale-pixel-value($gutter * 2);
            }
        }

        &__top,
        &__bottom {
            // background-color: rgba(#fc0, .2);
            height: scale-pixel-value($dc-pane);
            text-align: right;
            width: 100%;

            position: absolute;
            left: 0;

            .timetable & {
                display: none;
            }
        }

        &__top {
            top: 0;

            .dashboard-card--list & {
                border-bottom: 1px solid $color-04;

                @include respond-to(xs-max) {
                    background-color: $color-12;
                }
            }
        }

        &__bottom {
            // font-size: 0;
            padding-top: scale-pixel-value(($dc-pane - $dc-cta) / 2);
            padding-left: scale-pixel-value($gutter);
            padding-right: scale-pixel-value($gutter);

            bottom: 0;
        }

        &__middle {
            // background-color: rgba(green, .4);
            font-size: 0;
            padding-right: scale-pixel-value($gutter * 2);
            padding-left: scale-pixel-value($gutter * 2);

            .dashboard-card--full & {
                margin-top: scale-pixel-value($dc-pane);
            }

            .dashboard-card--full.dashboard-card--notification &,
            .dashboard-card--full.dashboard-card--facility & {
                margin-top: scale-pixel-value($dc-full-offset-top-computed + $dc-pane);
            }

            .dashboard-card--half & {
                margin-top: scale-pixel-value($dc-half-computed);
            }

            .dashboard-card--full.dashboard-card--list & {
                height: calc(100% - #{scale-pixel-value($dc-pane)} - #{scale-pixel-value($dc-pane)});
            }

            .timetable & {
                margin-top: $dc-clock;
            }
        }

        &__action {
            background: transparent;
            // background: rgba(#fc0, .4);
            border: 0;
            display: inline-block;
            font-size: scale-pixel-value(22px);
            line-height: scale-pixel-value($dc-pane);
            height: scale-pixel-value($dc-pane);
            padding: 0;
            text-align: center;
            width: scale-pixel-value($dc-pane);

            .theme--dark & { color: $color-01; }
        }

        &__counter {
            color: $color-01;

            position: absolute;
            top: scale-pixel-value(($dc-pane - 26px) / 2);
            right: scale-pixel-value($gutter * 2);


            @include respond-to(sm-min) {
                display: none;
            }
        }

        &__counter-values {
            float: left;
            font-size: scale-pixel-value(13px);
            margin-right: scale-pixel-value($gutter);
        }

        &__counter-icon {
            float: left;
            font-size: scale-pixel-value(18px);
        }

        &__signpost {
            @include respond-to(xl-max) {
                @include scale-typography($font-size-mobile, $font-leading-mobile);
                border-radius: scale-pixel-value(2px);
                // font-size: scale-pixel-value($gutter);
                // line-height: scale-pixel-value($gutter);
                margin-top: scale-pixel-value($gutter * 2);
                padding: scale-pixel-value(3px);
            }

            @include respond-to(xxl-min) {
                // NOTE: computed size at 1920px
                font-size: 22.6889px;
                line-height: 25.9302px;
                margin-top: 32.4127px;
                padding: 4.86191px;
            }

            &:nth-child(2) {
                @include respond-to(xl-max) {
                    margin-left: scale-pixel-value(5px);
                }

                @include respond-to(xxl-min) {
                    // NOTE: computed size at 1920px
                    margin-left: 8.10318px;
                }
            }


            // .theme--light & {
            //     background-color: rgba($color-03, .8);
            // }

            // .dashboard-card--event &,
            // .dashboard-card--news & {
            //     background-color: $color-03;
            //     color: $color-01;
            // }

            // .dashboard-card--news & {
            //     &:nth-child(2) {
            //         background-color: rgba($color-01, .8);
            //         color: $color-03;
            //     }
            // }

            // .dashboard-card--list .theme--light & {
            //     border: 2px solid $color-05;
            //     background-color: transparent;
            //     color: $color-05;
            //     margin-top: 0;
            //     margin-left: 0;
            // }
        }

        &__title {
            .theme--light & { color: $color-03; }

            .dashboard-card--full & {
                .dashboard__card-container:nth-child(10n + 1) &,
                .dashboard__card-container:nth-child(10n + 7) & {
                    @include respond-to(sm-min) {
                        @include scale-typography($h1-desktop, $h1-desktop-leading);
                    }
                }

                @include scale-typography($h3-desktop - 2, $h3-desktop-leading);
                padding-top: scale-pixel-value(15px);
                padding-bottom: scale-pixel-value(35px);
            }

            .dashboard-card--half & {
                @include scale-typography($h4-desktop, $h4-desktop-leading);

                .dashboard__card-container:nth-child(10n + 1) &,
                .dashboard__card-container:nth-child(10n + 7) & {
                    @include respond-to(sm-min) {
                        padding-top: scale-pixel-value(15px);
                        padding-bottom: scale-pixel-value(35px);
                    }
                }

                padding-top: scale-pixel-value(35px);
                padding-bottom: scale-pixel-value(15px);
            }

            .dashboard-card__signpost + & {
                padding-top: scale-pixel-value($gutter + 5px);
            }

            .dashboard-card--notification &,
            .dashboard-card--facility & {
                padding-bottom: scale-pixel-value($gutter);
            }

            .dashboard-card--event &,
            .dashboard-card--news & {
                @include scale-typography($h3-mobile, $h2-mobile-leading);
                width: 80%; // NOTE: actual calc from Zeplin is 62.5% but 80% makes the text wrap as per design
            }

            .dashboard-card--event & {
                padding-bottom: scale-pixel-value($h2-desktop);
            }

            .dashboard-card--news & {
                padding-bottom: scale-pixel-value($gutter);
            }

            .dashboard-card--full.dashboard-card--list & {
                @include scale-typography($base-font-size, $h5-mobile-leading);
                @extend %font-primary-bold;
                margin: 0;
                padding-top: scale-pixel-value(14px);
                padding-bottom: scale-pixel-value(10px);
            }

            .dashboard-card--timetable & {
                margin: 0;
            }
        }

        &__description {
            @include scale-typography($base-font-size, $base-leading);
            margin: 0 0 scale-pixel-value($gutter);

            .dashboard-card--news & { @include scale-typography($font-size-fine, $h2-mobile-leading); }

            .dashboard-card--list & {
                // @include scale-typography($font-size-fine, $base-leading);
                @include scale-typography($font-size-mobile, $lead-mobile);
            }

            .dashboard-card--timetable & {
                @extend %has-icon;
                @include scale-typography($font-size-fine, $font-leading-mobile);
                width: 75%;
            }
        }

        &__location,
        &__opening-hours,
        &__duration {
            @include scale-typography($font-size-mobile, $base-leading);
            padding-left: scale-pixel-value($base-leading);

            position: relative;

            &:before {
                position: absolute;
                left: 0;
            }
        }

        &__location {

            // &:before {
            //     .theme--light & {
            //         color: $color-05;
            //     }
            //
            //     .theme--dark & {
            //         color: $color-01;
            //     }
            // }

            a {
                text-decoration: underline;
            }
        }

        &__opening-hours,
        &__location {
            .theme--dark & {
                color: $color-01;
            }
        }

        &__cta {
            @extend %font-primary-bold;
            // background: rgba(#ccc, .3);
            display: inline-block;
            font-size: scale-pixel-value($font-size-mobile);
            line-height: scale-pixel-value($font-leading-mobile);
            padding: scale-pixel-value(($dc-cta - $font-leading-mobile) / 2) scale-pixel-value($gutter);
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;

            &--sibling-list {
                float: left;
            }

            .theme--dark & {
                color: $color-01;
            }
        }

        &__list {
            // background: rgba(#fc0, .8);
            margin: 0;
            padding: 0;
            height: 100%;

            &-item {
                border-bottom: 1px solid $color-04;
                list-style: none;
                margin: 0;
                padding: 0;
                // height: scale-pixel-value(115px);
                height: 33.333333%;
            }

            &-link {
                display: block;
                height: 100%;
                padding-right: $gutter * 2;

                position: relative;

                &:before {
                    font-size: $base-font-size;
                    color: $color-03;

                    position: absolute;
                    right: 0;
                    top: 50%;

                    @include transform(translateY(-50%) rotate(-90deg));
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
