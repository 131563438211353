%fc-label {
    color: $color-10;

    position: absolute;
    top: $base-leading + (($fc-input-height - $base-leading) / 2);

    @include transition(all .1s linear);
}

%fc-label--active {
    @extend %font-primary-bold;

    color: $color-03;
    font-size: $font-size-mobile;
    line-height: $font-leading-mobile;
    text-transform: uppercase;

    top: 0;
}


.fc {
    &__label {
        @extend %fc-label;

        .fc--datepicker-open &,
        .fc--focus &,
        .fc--active & {
            @extend %fc-label--active;
        }
    }
}


