// =============================================================================
// Title
// =============================================================================

.component--title {
    .template--news-detail .col-sm-9 & {
        @include respond-to(md-min) {
            margin-right: $col-md-1;
        }

        @include respond-to(lg-min) {
            margin-right: $col-lg-1;
        }
    }

    .template--event-detail & {
        @include respond-to(md-min) {
            width: $col-06;
        }
    }

    .rct--solid & {
        @include respond-to(xs-max) {
            min-height: $h3-mobile-leading * 4;
        }

        @include respond-to(sm-min) {
            position: absolute;
            bottom: 0;
        }
    }
}

.title {
    &__text {
        .pb & { // NOTE: when inside page banner component
            font-size: $h2-mobile;
            line-height: $h2-mobile-leading;

            @include respond-to(md-min) {
                font-size: $h2-desktop;
                line-height: $h2-desktop-leading;
            }

            @include respond-to(xxl-min) {
                font-size: $h1-desktop;
                line-height: $h1-desktop-leading;
            }
        }

        .rct--hollow & {
            @include respond-to(xs-max) {
                display: none;
            }
        }
    }
}

