// Custom styles for jQuery Date Range Picker
%drp-cell {
    height: 32px;
    line-height: 32px;
    text-align: center;
    // max-width: 32px;

    @include respond-to(sm-min) {
        height: 40px;
        line-height: 40px;
        // max-width: 40px;
    }
}

.date-picker-wrapper {
    background-color: $color-04;
    padding: 5.813953%;
    width: 100%;

    position: absolute;
    z-index: 10; // TODO: check if this needs to be changed

    .month-wrapper {
        width: 100% !important;
    }

    table {
        font-size: $font-size-mobile;
        line-height: $font-leading-mobile;
        width: 100%;

        .week-name {
            th {
                @extend %drp-cell;
            }
        }

        th,
        td {
            text-align: center;
            width: (100% / 7);
        }

        th {
            &.month-name {
                text-transform: uppercase;
            }

            // <span> with below class
            .prev,
            .next {
                @extend %drp-cell;
                cursor: pointer;
                display: inline-block;
                width: 100%;

                i {
                    font-size: 0;

                    &:before {
                        font-size: $base-font-size;
                    }
                }
            }
        }

        td {
            > div {
                @extend %drp-cell;
                cursor: pointer;
                display: inline-block;
                width: 100%;

                &:hover,
                &.real-today // current date
                {
                    background-color: $color-09;
                }

                &.hovering,
                &.checked {
                    background-color: $color-06;
                }

                // hiding dates not relevant to current month
                &.lastMonth,
                &.nextMonth {
                    cursor: default;
                    display: none;
                }
            }
        }
    }

    .footer {
        display: none;
    }
}



