// =============================================================================
// Responsive manager
// =============================================================================
// Repetitive code block
@mixin rm-css-display($display, $bpt, $prop) {
    .#{$display}-#{$bpt},
    .#{$display}-#{$bpt}-inline-block {
        display: #{$prop} !important;
    }
}
// usage: @include rm-css-display(visible, $bp, block);
// - OR -
// usage: @include rm-css-display(hidden, $bp, none);
// ------------------------------------

.rm {
    height: 0;

    @each $breakpoint, $min, $max in $min-max-map {
        $val: map-get($min-max-map, $breakpoint);
        $min: map-get($val, min);
        $max: map-get($val, max);

        @if $breakpoint != 'ipad' {
            @if $min == null and $max != null {
                &[data-breakpoint='#{$breakpoint}'] { width: 160px; }
            } @else {
                &[data-breakpoint='#{$breakpoint}'] { width: $min; }
            }
        }
    }
}

// Responsive-Manager-CSS (hidden / visible classes needed for responsive manager to work)
@each $breakpoint, $min, $max in $min-max-map {
    $val: map-get($min-max-map, $breakpoint);
    $min: map-get($val, min);
    $max: map-get($val, max);

    @if $breakpoint != 'ipad' {
        @if $min != null or $max != null {
            @include rm-css-display(visible, $breakpoint, none);
        }

        @if $min != null and $max != null {
            @media (min-width: $min) and (max-width: $max - 1px) {
                @include rm-css-display(visible, $breakpoint, block);

                @include rm-css-display(hidden, $breakpoint, none);
            }
        }

        @if $min != null and $max == null {
            @media (min-width: $min) {
                @include rm-css-display(visible, $breakpoint, block);

                @include rm-css-display(hidden, $breakpoint, none);
            }
        }

        @if $min == null and $max != null {
            @media (max-width: $max - 1px) {
                @include rm-css-display(visible, $breakpoint, block);

                @include rm-css-display(hidden, $breakpoint, none);
            }
        }
    }
}
