// =============================================================================
// Background modifiers
// Added to 'section' OR 'column controls'
// =============================================================================

// NOTE: color classes are in alphabetical order
.bg {
    &--black { background-color: $color-03; }
    &--crimson { background-color: $color-02; }
    &--dark-grey { background-color: $color-12; }
    &--light-grey { background-color: $color-08; }
    &--mid-grey { background-color: $color-10; }
    &--off-white { background-color: $color-04; }
    &--orange { background-color: $color-06; }
    &--purple { background-color: $color-13; }
    &--red { background-color: $color-05; }
    &--teal { background-color: $color-14; }
    &--white { background-color: $color-01; }
}
