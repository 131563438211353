.fc {
    // shell for select box
    &__dropdown {
        @extend %drop-down-caret;

        &:after {
            margin-top: 0;

            top: 41.5px; // NOTE: calc: 24px (margin-top) + 17.5px (40px (height of select) - 5px (height of caret) / 2)
        }
    }

    &__select {
        @extend %clear-default-appearance;
        @extend %fc-input;
        @extend %fc-text-padding;

        color: $color-08;
        padding-right: 24px;

        &::-ms-expand {
            display: none;
        }

        .fc--focus &,
        .fc--active & {
            color: $color-03;
        }

        &:focus {
            outline: 0;
        }
        
        .fc--error & {
            border-bottom-color: $color-18;
        }
    }
}
