%icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
	&:before {
		@extend %icon;
	}
}