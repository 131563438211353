$tile-side-padding: 8px;

.tile {
	&-filters {
		border-bottom: 1px solid $color-09;
		margin: 0 -16px 40px -16px;
		position: relative;
		@extend %clear;

	    @include respond-to(sm-min) {
			margin: 0 0 40px 0;
	    }

	    &__wrapper {
		    position: relative;
		    overflow-x: auto;
		    overflow-y: hidden;
		    -webkit-overflow-scrolling: touch;
		    white-space: nowrap;
		    -ms-overflow-style: -ms-autohiding-scrollbar;

		    &::-webkit-scrollbar {
		        display: none;
		    }
	    }

	    &__content {
		    float: left;
		    transition: transform .2s ease-in-out;

		    @include respond-to(xs-max) {
				padding: 0 ($gutter-xs / 2);
		    }

		    &-no-transition {
		    	transition: none;
		    }
	    }

	    &__button {
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
		    appearance: none;
		    background-color: $color-04;
		    padding: 0;
		    border: 0;
		    font-size: 24px;
			width: 32px;

		    @include respond-to(sm-min) {
		    	width: 54px;
		    }

		    &:before {
			    @include respond-to(xs-max) {
					display: none;
			    }
		    }

		    &:hover {
		        cursor: pointer;
		    }

		    &-left {
			    left: 0;
		        background: -webkit-linear-gradient(right, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: -o-linear-gradient(left, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: -moz-linear-gradient(left, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: linear-gradient(to left, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);

			    @include respond-to(sm-min) {
			        background: -webkit-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: -o-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: -moz-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: linear-gradient(to left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
			    }

			    [data-overflowing="both"] ~ &,
			    [data-overflowing="left"] ~ & {
			        opacity: 1;
                    visibility: visible;
			    }
		    }

		    &-right {
			    right: 0;
		        background: -webkit-linear-gradient(left, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: -o-linear-gradient(right, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: -moz-linear-gradient(right, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);
			    background: linear-gradient(to right, rgba($color-04,0.6) 0%, rgba($color-04,0.9) 100%);

			    @include respond-to(sm-min) {
			        background: -webkit-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: -o-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: -moz-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
				    background: linear-gradient(to right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
			    }

			    [data-overflowing="both"]  ~ &,
			    [data-overflowing="right"] ~ & {
			        opacity: 1;
                    visibility: visible;
			    }
		    }
	    }

		.filter {
		    display: inline-flex;
		    align-items: center;
		    min-height: $h6-desktop-leading;

			a {
			    font-size: $h6-desktop;
			    line-height: $h6-desktop-leading;
			    letter-spacing: 1px;
			    text-transform: uppercase;
			    font-weight: bold;
				display: block;
				float: left;
				padding: 17px ($gutter-xs / 2) 21px ($gutter-xs / 2);	
				position: relative;
				color: $color-11;
				text-decoration: none;

			    @include respond-to(sm-min) {
					padding: 17px 24px 21px 24px;	
			    }

				&:hover {
					color: $color-03;
				}
			}

			&-active {
				a {
					color: $color-03;

					&:before {
						content: '';
						position: absolute;
						left: ($gutter-xs / 2);
						right: ($gutter-xs / 2);
						bottom: 0;
						box-sizing: border-box;
						height: 2px;
						background-color: $color-06;

					    @include respond-to(sm-min) {
							left: 24px;
							right: 24px;
					    }
					}
				}
			}
		}
	}

	&-lists {
		&__tools {
			display: none;
		}
	}

	&-list {
		@extend %clear;
		display: none;

		&-dynamic,
		&-static {
		    @include respond-to(sm-min) {
				margin: 0 (-$tile-side-padding);
		    }

			.component--tile {
			    @include respond-to(sm-min) {
					float: left;
					margin: 0 $tile-side-padding;
			    }
			}
		}

		&-static {
			display: block;
		}

		&.active {
			display: block;
		}

		&__filter {
			background-color: $color-01;
			margin: -40px -16px 40px -16px;

		    @include respond-to(sm-min) {
				margin: -40px 8px 40px 8px;
		    }

			&-controls {
				@extend %clear;
				padding: 16px 0;
			}

			&-content {
				padding: 20px 0 40px 0;
				display: none;

				.row {
				    @include respond-to(xs-max) {
				    	margin: 0;
				    }
				}
			}

			&-item {
			    @include respond-to(xs-max) {
					position: relative;
					padding-top: 80px;
			    }

				&:before {
				    @include respond-to(xs-max) {
				    	content: '';
				    	position: absolute;
				    	left: 32px;
				    	right: 32px;
				    	top: 40px;
				    	height: 1px;
				    	background-color: $color-09;
				    }
				}

			    &:first-child {
				    @include respond-to(xs-max) {
						padding-top: 0;
				    }

					&:before {
					    @include respond-to(xs-max) {
					    	display: none;
					    }
					}
			    }
			}

			&-column {
				padding: 0 16px;

			    @include respond-to(sm-min) {
					padding: 0 32px;
			    }
			}

			&-search {
				padding: 0 16px 32px 16px;
				box-sizing: border-box;
				float: left;
				width: 100%;

			    @include respond-to(sm-min) {
					width: 296px;
					padding: 0 32px;
			    }

				.fc {
					margin-top: 0;
					position: relative;

					&__text {
						margin-top: 0;
						padding-left: 32px;
					}

					&__icon {
						position: absolute;
						top: 0;
						left: 0;
					    font-size: 24px;
    					line-height: 40px;
					}
				}
			}

			&-reset,
			&-toggle {
				padding: 0 16px;
				float: right;

			    @include respond-to(sm-min) {
					padding: 0 32px;
			    }

				a {
				    font-size: $h6-desktop;
				    line-height: 40px;
				    letter-spacing: 1px;
				    text-transform: uppercase;
				    font-weight: bold;
					display: block;
					color: $color-03;
					position: relative;
					padding-right: 32px; 
					text-decoration: none;

					&:hover {
						color: $color-02;
					}

					span {
						position: absolute;
						top: 0;
						right: 0;
					    font-size: 24px;
    					line-height: 40px;
					}
				}
			}

			&-reset {
				float: left;

			    @include respond-to(sm-min) {
					float: right;
			    }
			}

			.component--title {
				padding-bottom: 16px;

			    @include respond-to(sm-min) {
					padding-top: 32px;
					padding-bottom: 12px;
			    }

			    @include respond-to(lg-min) {
			    	padding-top: 0;
					padding-bottom: 24px;
			    }
			}
		}

		.tile-no-results {
			margin: 0 $tile-side-padding;
			padding: 32px 0;

		    @include respond-to(sm-min) {
				margin: 0 $tile-side-padding * 2;
		    }
		}
	}
}