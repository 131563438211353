// =============================================================================
// Author
// =============================================================================
$author-tn-mobile: 70px;
$author-tn-desktop: 100px;

.component--author {
    .template--news-detail & {
        // @extend .col-sm-3;
        // @extend .col-md-2;
        @extend .col-md-offset-1;



        @include respond-to(sm-min) {
            margin-top: 0;
            padding-left: $gutter-sm;
            padding-right: $gutter-sm;
            width: $col-03;
            // width: calc(#{$col-03} - #{$gutter-sm / 2});

            position: absolute;
            top: 0;
            left: 0;
            // left: $gutter-sm;
        }

        @include respond-to(md-min) {
            width: $col-02;
            // width: calc(#{$col-02} - #{$gutter-sm / 2});
        }

        // @include respond-to(xs-max) {
        //     padding-left: 0;
        //     padding-right: 0;
        // }
    }
}

.author {
    // NOTE: was sm-max
    @include respond-to(xs-max) { display: table; }

    // NOTE: was md
    @include respond-to(sm-min) { text-align: center; }

    &__img,
    &__details {
        // NOTE: was sm-max
        @include respond-to(xs-max) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__img {
        border: 2px solid $color-08;
        border-radius: 50%;
        height: $author-tn-mobile;
        width: $author-tn-mobile;

        // NOTE: was md
        @include respond-to(sm-min) {
            height: $author-tn-desktop;
            margin: 0 auto ($gutter * 2);
            width: $author-tn-desktop;
        }
    }

    &__details {
        .author__img ~ & {
            @include respond-to(xs-max) {
                padding-left: $gutter * 2;
            }
        }

        @include respond-to(md-min) {
            padding: 0 ($gutter * 2);
        }
    }

    &__by,
    &__name {
        @include respond-to(md-min) { display: block; }
    }

    &__date {
        font-size: $font-size-fine;
        display: block;
        line-height: $h3-desktop;
    }
}
