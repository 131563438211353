// =============================================================================
// 	Animations
// =============================================================================

// ------------------------------------
// loader animation
// ------------------------------------

@-webkit-keyframes load6 {
    0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


// ------------------------------------
// bounce animation
// ------------------------------------
@mixin bounc-animation {
    0%, 20%, 50%, 80%, 100% { @include transform(translateY(0)); }
    40% { @include transform(translateY(-20px)); }
    60% { @include transform(translateY(-15px)); }
}

@keyframes bounce {
    @include bounc-animation;
}

@-webkit-keyframes bounce {
    @include bounc-animation;
}

// ------------------------------------
// bounce right animation
// ------------------------------------
@mixin bounce-right-animation {
    0%, 40%, 80%, 100% { @include transform(translateX(0)); }
    20%, 60% { @include transform(translateX(5px)); }
}

@keyframes bounce-right {
    @include bounce-right-animation;
}

@-webkit-keyframes bounce-right {
    @include bounce-right-animation;
}

// ------------------------------------
// stretch delay (loading bars)
// ------------------------------------
@mixin sk-stretchdelay-animation {
    0%, 40%, 100% { @include transform(scaleY(.4)); }
    20% { @include transform(scaleY(1)); }
}

@keyframes sk-stretchdelay {
    @include sk-stretchdelay-animation;
}

@-webkit-keyframes sk-stretchdelay {
    @include sk-stretchdelay-animation;
}

// @keyframes sk-stretchdelay {
//   0%, 40%, 100% {
//     transform: scaleY(0.4);
//     -webkit-transform: scaleY(0.4);
//   }  20% {
//     transform: scaleY(1.0);
//     -webkit-transform: scaleY(1.0);
//   }
// }

// ------------------------------------
// button spin
// ------------------------------------
@mixin button-spin-animation {
    // from { @include rotate(0); }
    // to { @include rotate(360); }
    0% { @include rotate(0); }
    100% { @include rotate(360); }
}

@-webkit-keyframes button-spin {
    @include button-spin-animation;
}

@keyframes button-spin {
    @include button-spin-animation;
}

// ------------------------------------
// fade out
// ------------------------------------
@mixin fade-out-animation {
    0% { opacity: 1; @include transform(scale(1)); }
    // 85% { opacity: 1; @include transform(scale(1.05)); }
    100% { opacity: 0; @include transform(scale(.1)); }
}

@-webkit-keyframes fade-out {
    @include fade-out-animation;
}

@keyframes fade-out {
    @include fade-out-animation;
}

// ------------------------------------
// fade in
// ------------------------------------
@mixin fade-in-animation {
    0% { opacity: 0; @include transform(scale(.1)); }
    // 85% { opacity: 1; @include transform(scale(1.05)); }
    100% { opacity: 1; @include transform(scale(1)); }
}

@-webkit-keyframes fade-in {
    @include fade-in-animation;
}

@keyframes fade-in {
    @include fade-in-animation;
}


// ------------------------------------
// ripple
// ------------------------------------
@mixin ripple-animation {
    0% { opacity: 0; @include transform(scale(0)); }
    50% { opacity: .5; @include transform(scale(2.4)); }
    100% { opacity: 0; @include transform(scale(2.4)); }
}


@-webkit-keyframes ripple {
    @include ripple-animation;
}

@keyframes ripple {
    @include ripple-animation;
}

// ------------------------------------
// ripple & move
// ------------------------------------
@mixin ripple-move-animation {
    0% {
        opacity: 0;
        // right: 20px;
        @include transform(scale(0));
        // @include transform(translate(-20px, 2px) scale(0));
    }

    50% {
        opacity: .5;
        // right: 10px;
        @include transform(scale(2.4));
        // @include transform(translate(-10px, 2px) scale(2.4));
    }

    100% {
        opacity: 0;
        // right: 0;
        @include transform(scale(2.4));
        // @include transform(translate(0, 2px) scale(2.4));
    }
}


@-webkit-keyframes ripple-move {
    @include ripple-move-animation;
}

@keyframes ripple-move {
    @include ripple-move-animation;
}

// ------------------------------------
// easings
// ------------------------------------
$easing: (
    'easeInSine':     cubic-bezier(0.47, 0, 0.745, 0.715),
    'easeOutSine':    cubic-bezier(0.39, 0.575, 0.565, 1),
    'easeInOutSine':  cubic-bezier(0.445, 0.05, 0.55, 0.95),
    'easeInQuad':     cubic-bezier(0.55, 0.085, 0.68, 0.53),
    'easeOutQuad':    cubic-bezier(0.25, 0.46, 0.45, 0.94),
    'easeInOutQuad':  cubic-bezier(0.455, 0.03, 0.515, 0.955),
    'easeInCubic':    cubic-bezier(0.55, 0.055, 0.675, 0.19),
    'easeOutCubic':   cubic-bezier(0.215, 0.61, 0.355, 1),
    'easeInOutCubic': cubic-bezier(0.645, 0.045, 0.355, 1),
    'easeInQuart':    cubic-bezier(0.895, 0.03, 0.685, 0.22),
    'easeOutQuart':   cubic-bezier(0.165, 0.84, 0.44, 1),
    'easeInOutQuart': cubic-bezier(0.77, 0, 0.175, 1),
    'easeInQuint':    cubic-bezier(0.755, 0.05, 0.855, 0.06),
    'easeOutQuint':   cubic-bezier(0.23, 1, 0.32, 1),
    'easeInOutQuint': cubic-bezier(0.86, 0, 0.07, 1),
    'easeInExpo':     cubic-bezier(0.95, 0.05, 0.795, 0.035),
    'easeOutExpo':    cubic-bezier(0.19, 1, 0.22, 1),
    'easeInOutExpo':  cubic-bezier(1, 0, 0, 1),
    'easeInCirc':     cubic-bezier(0.6, 0.04, 0.98, 0.335),
    'easeOutCirc':    cubic-bezier(0.075, 0.82, 0.165, 1),
    'easeInOutCirc':  cubic-bezier(0.785, 0.135, 0.15, 0.86),
    'easeInBack':     cubic-bezier(0.6, -0.28, 0.735, 0.045),
    'easeOutBack':    cubic-bezier(0.175, 0.885, 0.32, 1.275),
    'easeInOutBack':  cubic-bezier(0.68, -0.55, 0.265, 1.55)
);

@mixin prefix($name, $argument) {
    -webkit-#{$name}: #{$argument};
       -moz-#{$name}: #{$argument};
        -ms-#{$name}: #{$argument};
         -o-#{$name}: #{$argument};
            #{$name}: #{$argument};
}

@mixin animate($property, $options...){

    $duration:  '250ms' !default;
    $ease:      map-get($easing, easeInOutExpo)  !default;

    @each $option in $options{
        @if type_of($option) == number{
            $duration:$option + 'ms';
        }
        @else {
            @if map-has-key($easing, $option) {
                $ease:  map-get($easing, $option);
            }
        }
    }

    //if you have prefixer in your build task you won't need a prefix mixin
    @include prefix(transition-property, $property);
    @include prefix(transition-duration, $duration);
    @include prefix(transition-timing-function, $ease);
}

