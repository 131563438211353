// =============================================================================
//  Typography rules
// =============================================================================

// ------------------------------------
//  Html
// ------------------------------------
html {
    font-size: $base-font-size;

    @include respond-to(sm-min) {
        font-size: calc(100vw * #{to-number($base-font-size / 1024px)});
    }

    @include respond-to(lg-min) {
        font-size: calc((100vw - #{$gn-width}) * #{to-number($base-font-size / 1024px)});
    }

    @include respond-to(xxl-min) {
        font-size: calc((100vw - #{$gn-width-hd}) * #{to-number($base-font-size / 1024px)});
    }
}

// ------------------------------------
//  Body
// ------------------------------------
body {
    @extend %font-primary;
    @extend %font-primary-normal;

    color: $color-03;
    font-size: $base-font-size;
    line-height: $base-leading;
    // line-height: $base-computed-leading; // TODO: switch to OR remove?
}

// ------------------------------------
//  Headings
// ------------------------------------
// TODO: Change typography color based on different backgrounds?
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @extend %heading-default;
    @extend %typography-last;
}

h1,
.h1 {
    @extend %h1;
    @extend %font-primary-black;
    @extend %heading-uppercase;

    &.large {
        @extend %h0;
    }

    &.small {
        @extend %h3;
        @extend %font-primary-medium;
        @extend %heading-lowercase;
    }

    &.secondary {
        @extend %font-secondary;
        @extend %heading-lowercase;
    }
}

h2,
.h2 {
    @extend %h2;
    @extend %font-primary-medium;

    &.large {
        @extend %h1;
        @extend %font-primary-black;
        @extend %heading-uppercase;

        &.secondary {
            @extend %heading-lowercase;
        }
    }

    &.secondary {
        @extend %font-secondary;
    }
}

h3,
.h3 {
    @extend %h3;
    @extend %font-primary-medium;

    &.large {
        @extend %h2;
        @extend %font-primary-medium;
    }

    &.secondary {
        @extend %font-secondary;
    }
}

h4,
.h4 {
    @extend %h4;

    &.large {
        @extend %h3;
        @extend %font-primary-book;
    }

    &.secondary {
        @extend %font-secondary;
    }
}

h5,
.h5 {
    border-top: 1px solid $color-03;
    font-size: $h5-mobile;
    line-height: $h5-mobile-leading;
    margin: 0 0 ($gutter + 5px);
    padding: ($gutter + 5px) 0 0;

    @include respond-to(md-min) {
        font-size: $h5-desktop;
        line-height: $h5-desktop-leading;
    }

    &.color--crimson {
        border-color: $color-02;
    }
}

h6,
.h6 {
    @extend %h6;
    @extend %font-primary-bold;
    margin: 0 0 $vertical-gutter-mobile;
    padding: 0;
}

// .secondary {
//     @extend %font-secondary;
// }


.break {
    word-break: break-all;
}

// ------------------------------------
//  Blockquote
// ------------------------------------
.blockquote,
blockquote {
    .component--wysiwyg & {
        @extend %blockquote;
    }
}
