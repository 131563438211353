// =============================================================================
// Accordion
// =============================================================================
// acdn prefix used for all accordion variables
$acdn-head: 60px;

$acdn-icon-height: 10px;
$acdn-icon-width: 2px;

.accordion {
    &__title {
        @include background-context-dark { color: $color-01; }
    }

    &__item {
        @include background-context-dark { border-color: $color-01; }
        @include background-context-light { border-color: $color-09; }
        border-bottom: 1px solid $color-08;


        &:first-child {
            @include background-context-dark { border-color: $color-01; }
            @include background-context-light { border-color: $color-09; }
            border-top: 1px solid $color-08;
        }
	}

    &__head {
        @include background-context-dark { color: $color-01; }
        background: transparent;
        border: 0;
        margin: 0;
        padding: (($acdn-head - $base-leading) / 2) 24px (($acdn-head - $base-leading) / 2) 0;
        text-align: left;
        width: 100%;
        position: relative;

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            @include transition(opacity .3s, transform .3s);
            background-color: $color-03;

            @include background-context-light {
                background-color: $color-03;
            }

            @include background-context-dark {
                background-color: $color-01;
            }

            .accordion--open & {
                @include transform(rotate(90deg));
            }
        }

        &:before {
            height: $acdn-icon-height;
            margin-left: -($acdn-icon-width / 2);
            margin-top: -($acdn-icon-height / 2);
            width: $acdn-icon-width;

            right: (($acdn-icon-height - $acdn-icon-width) / 2) + 7px;
        }

        &:after {
            height: $acdn-icon-width;
            margin-left: -($acdn-icon-height / 2);
            margin-top: -($acdn-icon-width / 2);
            width: $acdn-icon-height;

            right: 7px;

            .accordion--open & {
                opacity: 0;
            }
        }
    }

    &__inner {
        margin-bottom: 30px;
    }

    @media print {
      &__body {
         display: block !important;
      }
    }
}
