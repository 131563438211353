.cc {
    overflow: hidden;
    position: relative;
    min-height: 390px;

    &-empty {
        .cc__head {
            display: none;
        }

        .cc__body{
            width: 100%;
            padding: 0 8px;
        }

        .cc__column {
            width: 100%;
        }

        .cc__cell-wrapper {
            min-height: 300px;
        }
    }

    &__title {
        >div {
            display: none;
            padding: ($gutter-xs * 2) $gutter-xs;

            @include respond-to(sm-min) {
                padding: ($gutter-xs * 2) 160px ($gutter-xs * 2) ($gutter-xs / 2);
            }

            &.active {
                display: block;
            }
        }
    }

    &__copy {
        padding: 0 $gutter-xs 32px $gutter-xs;
        display: none;

        @include respond-to(sm-min) {
            padding: 0 160px 64px ($gutter-xs / 2);
        }

        p {
            font-size: $h3-mobile;
            line-height: $h3-mobile-leading;
            font-weight: 300;

            @include respond-to(md-min) {
                font-size: $h3-desktop;
                line-height: $h3-desktop-leading;
                margin-bottom: 32px;
            }
        }
    }

    &__loader {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 390px;
        background-color: $color-04;
        z-index: 5;

        .loader {
            color: $color-10;
            font-size: 90px;
            text-indent: -9999em;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            margin: 150px auto;
            position: relative;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
            animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        }  
    }

    &__table {
        @extend %clear;
        padding-top: ($gutter-xs * 2);
        display: none;
    }

    &__head {
        float: left;
        width: $col-sm-1 * 6;
        padding: 0 ($gutter-xs / 2);
        box-sizing: border-box;
        position: relative;
        z-index: 3;
        display: none;

        @include respond-to(md-min) {
            width: $col-md-1 * 4;
        }

        @include respond-to(lg-min) {
            width: $col-lg-1 * 3;
        }

        @include respond-to(xxl-min) {
            width: $col-xxl-1 * 3;
        }

        &.active {
            @include respond-to(sm-min) {
                display: block;
            }
        }

        .cc__cell {
            &:first-child {
                padding-top: 3px;
            }

            &-title {
                padding-right: ($gutter-xs * 3);
                position: relative;

                &.collapsed {
                    a {
                        &:after,
                        &:before {
                            @include transform(rotate(0deg));
                        }

                        &:after {
                            opacity: 1;
                        }

                    }
                }

                a {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: $h3-desktop-leading + ($gutter-xs * 2);
                    width: ($gutter-xs * 2);

                    &:hover {
                        opacity: 0.7;
                    }

                    &:after,
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        @include transition(opacity .3s, transform .3s);
                        background-color: $color-03;
                        @include transform(rotate(90deg));
                    }

                    &:before {
                        height: 14px;
                        margin-left: -1px;
                        margin-top: -7px;
                        width: 2px;
                        right: 13px;
                    }

                    &:after {
                        height: 2px;
                        margin-left: -7px;
                        margin-top: -1px;
                        width: 14px;
                        right: 7px;
                        opacity: 0;

                        .collapsed & {
                            opacity: 1;
                        }
                    }
                }

            }
        }
    }

    &__body {
        padding: 0 ($gutter-xs * 1.5);

        @include respond-to(sm-min) {
            float: left;
            padding: 0;
            width: $col-sm-1 * 6;
            position: relative;
            z-index: 2;
        }

        @include respond-to(md-min) {
            width: $col-md-1 * 8;
        }

        @include respond-to(lg-min) {
            width: $col-lg-1 * 9;
        }

        @include respond-to(xxl-min) {
            width: $col-xxl-1 * 9;
        }

        &:after {
            @include respond-to(sm-min) {
                content: '';
                position: absolute;
                top: -8px;
                bottom: -8px;
                width: 50vw;
                left: -50vw;
                padding-right: 8px;
                box-sizing: content-box;
                background-color: $color-04;
                z-index: 1;
            }
        }

        .slick-list {
            overflow: initial;
        }

        .slick-slide {
            opacity: 0.5;
            @include transition(opacity .3s);

            &.slick-active {
                opacity: 1;
            }
        }

        .slick {

            &-arrow {
                width: 56px;
                height: 56px;
                line-height: 56px;
                display: none;
                border: none;
                font-size: 0;
                padding: 0;
                position: absolute;
                top: -90px;
                background-color: $color-01;

                @include respond-to(sm-min) {
                    display: block;
                }

                &:before {
                    font-size: 20px;
                    position: relative;
                    z-index: 7;
                }

                &:after {
                    @include respond-to(sm-min) {
                        background-color: $color-06;
                        content: '';
                        height: 56px;
                        width: 0;
                        position: absolute;
                        z-index: 6;
                        top: 0;
                        left: 0;
                        @include transition((width .3s));
                    }
                }

                &:hover {
                    &:after {
                        @include respond-to(sm-min) {
                            width: 56px;
                        }
                    }
                }
            }

            &-prev {
                right: 65px;

                @include respond-to(xs-max) {
                    display: none!important;
                }
            }

            &-next {
                right: 8px;

                @include respond-to(xs-max) {
                    display: none!important;
                }
            }

            &-disabled { 
                opacity: .3; 


                &:hover {
                    &:after {
                        @include respond-to(sm-min) {
                            width: 0;
                        }
                    }
                }
            }
        }
    }

    &__column {
        padding: 0 ($gutter-xs / 2);

        @include respond-to(sm-min) {
            float: left;
            width: $col-sm-1 * 6;
            box-sizing: border-box;
        }

        @include respond-to(md-min) {
            width: $col-md-1 * 4;
        }

        @include respond-to(lg-min) {
            width: $col-lg-1 * 3;
        }

        @include respond-to(xxl-min) {
            width: $col-xxl-1 * 3;
        }

        &-template {
            display: none;
        }

        .cc__cell {
            background-color: $color-01;

            @include respond-to(xs-max) {
                height: auto!important;
            }

            &-wrapper {
                font-size: $h4-mobile;
                line-height: $h4-mobile-leading;
                padding: $gutter-xs;

                @include respond-to(xs-max) {
                    visibility: visible!important;
                    opacity: 1!important;
                }
            }

            &:first-child {
                border-top: 4px solid $color-03;
            }

            &.cc__cell-more {
                border-top: none;
                padding-top: 4px;

                .cc__cell-wrapper {
                    padding: 0;
                    position: relative;
                }
            }

            &.cc__cell-error {
                border: 1px dashed $color-10;
                border-image-source: url('../images/border-dark.png');
                border-image-width: 1;
                border-image-slice: 1;
                border-image-repeat: round;
                background-color: $color-09;

                a {
                    background-color: $color-03;
                    color: $color-01;

                    &:hover {
                        color: $color-03;
                    }

                    &:after {
                        background-color: $color-06;
                    }
                }

                .cc__links {
                    padding-top: 91px;
                }
            }

            &-title {
                padding-bottom: ($gutter-xs * 2);
                
                @include respond-to(sm-min) {
                    display: none;
                }

            }
        }
    }

    &__cell {
        min-height: 80px;
        border-top: 1px solid $color-09;
        box-sizing: border-box;
        overflow: hidden;
        @include transition(height .3s);

        &-static {
            overflow: visible;
        }

        &-wrapper {
            box-sizing: border-box;
            min-height: 80px;
            @include transition(opacity .1s, visibility .3s);
        }

        &-content {
            position: relative;
        }

        &-title {
            font-size: $h3-desktop;
            line-height: $h3-desktop-leading;
            padding: $gutter-xs 0;
        }

        h2 {
            font-size: $h3-desktop;
            line-height: $h3-desktop-leading;
            @extend %font-primary-medium;
            @extend %font-secondary;
            padding: $gutter-xs 0;
            margin: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .message {
            font-size: 16px;
            line-height: 24px;
            padding: 120px 0;
        }

        &-add {
            min-height: 120px;

            a {
                display: block;
                position: absolute;
                top: -4px;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px dashed $color-09;
                border-image-source: url('../images/border.png');
                border-image-width: 1;
                border-image-slice: 1;
                border-image-repeat: round;
                box-sizing: border-box;

                &:hover {
                    background-color: $color-09;
                    border: none;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -28px 0 0 -60px;
                    width: 120px;
                    height: 56px;
                    display: block;
                    text-align: center;
                    @extend %h6;
                    @extend %font-primary-bold;
                    color: $color-03;
                    padding-top: 40px;

                    &:before {
                        @extend %icon;   
                        content: "\e918";   
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        font-size: 24px;        
                    }
                }
            }
        }

        dl {
            @extend %clear;
            padding: 8px 0;
            margin: 0;

            dt {
                float: left;
            }

            dd {
                float: right;
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding: 8px 0;
                margin: 0;

            }
        }

        p {
            margin: 0;
            padding: 8px 0;
        }

        img {
            width: 100%;
        }
    }

    &__card {
        position: relative;
        height: (($h3-desktop-leading * 6) + ($gutter-xs * 5));

        @include respond-to(sm-min) {
            height: (($h3-desktop-leading * 5) + ($gutter-xs * 5));
        }

        @include respond-to(lg-min) {
            height: (($h3-desktop-leading * 6) + ($gutter-xs * 5));
        }

        &-wrapper {
            top: 0;
        }

        &.sticky {
            .cc__card-wrapper {
                position: absolute;
                top: 0;
                left: (- $gutter-xs);
                right: (- $gutter-xs);
                height: (($h4-desktop-leading * 4) + ($gutter-xs * 2) + 5);
                border-top: 4px solid $color-03;
                background-color: $color-01;
                border-bottom: 1px solid $color-09;
                z-index: 99;
                @include transition(top .3s);                
            }

            h2 {
                font-size: $h4-desktop;
                line-height: $h4-desktop-leading;
                padding-right: ($gutter-xs * 3);
                padding-left: $gutter-xs;
            }

            .message {
                font-size: $h4-desktop;
                line-height: $h4-desktop-leading;
                padding: $gutter-xs ($gutter-xs * 3) $gutter-xs $gutter-xs;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;                
            }

            button {
                top: 0;
                right: 0;
            }

            .component--tags {
                display: none;
            }
        }

        &-title {
            font-size: $featured-mobile;
            line-height: $featured-mobile-leading;
            padding-top: ($gutter-xs * 2);
        }

        button {
            position: absolute;
            top: (- $gutter-xs);
            right: (- $gutter-xs);
            width: 50px;
            height: 64px;
            padding: 0;
            background-color: transparent;
            @include transition((unset));
            border: none;

            &:before {
                @extend %icon;    
                content: "\e906";   
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;    
                font-size: 24px;
                line-height: 64px;
                text-align: center;
                color: $color-03;         
            }

            &:hover {
                &:before {
                    opacity: 0.6;
                }
            }

            &.active {
                &:before {
                    content: "\e92e";
                }
            }
        }

        .tag-wrap {
            padding-right: 34px;
            
            .tag {
                margin-bottom: 5px;
            }
        }
    }

    &__link {
        padding: ($gutter-xs / 2) 0;

        &s {
            padding-top: ($gutter-xs / 2);

            @include respond-to(sm-min) {
                padding-top: $gutter-xs;
            }

            @include respond-to(xxl-min) {
                padding-top: ($gutter-xs * 2);
            }
        }

        a {
            display: block;
        }
    }
}