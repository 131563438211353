// =============================================================================
// Divider (<hr>)
// =============================================================================
.component {
    &--divider {
        .template--news-detail .col-sm-9 & {
            @include respond-to(md-min) {
                margin-right: $col-md-1;
            }

            @include respond-to(lg-min) {
                margin-right: $col-lg-1;
            }
        }

        .ss__top + & {
            margin-top: 0;
        }

        hr {
            @include background-context-dark { background-color: $color-01; }

            background-color: $color-09;
            border: 0;
            height: 1px;
            margin: 0;
            padding: 0;
            width: 100%;

            &.divider--thick {
                @include background-context-dark { background-color: $color-01; }

                background-color: $color-03;
                height: 4px;
            }
        }
    }
}
