.notification {
    background-color: $color-16;
    font-size: 18px;
    position: relative;
    transition: height .5s cubic-bezier(0.77, 0, 0.175, 1), visibility .5s;

    &--dismissed {
        height: 0 !important;
        overflow: hidden;
        visibility: hidden;

        .notification__inner {
            opacity: 0;
        }
    }

    &__inner {
        padding: $gutter-xs * 1.5 $gutter-xs;
        transition: opacity .5s;
    }

    p {
        &.notification__content {
            line-height: 1.2;
            margin-bottom: $gutter-xs;

            @include respond-to(sm-min) {
                width: 50%;
            }
        }
    }

    &__btn {
        @extend %clear-default-appearance;
        @extend %font-primary;
        @extend %font-primary-bold;
        background: transparent;
        color: $color-03;
        border: 0;
        display: inline-block;
        font-size: 12px;
        margin-right: 15px;
        padding: 0;
        text-transform: uppercase;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-05;
        }
    }

    &__close-btn {
        @extend %clear-default-appearance;
        background: transparent;
        border: 0;
        color: inherit;
        position: absolute;
        right: 11px;
        top: 11px;

        &:hover {
            color: $color-05;
        }

        &:before {
            content: '\00d7';
            display: block;
            font-size: 25px;
        }
    }
}
