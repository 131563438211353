// =============================================================================
// Banner
// =============================================================================
// ban prefix used for all banner variables
$ban-width: 80%;

.banner {
    background-position: center; // TODO: vertical align top?
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0 20px;
    text-align: center;

    position: relative;

    @include respond-to(sm-min) {
        padding: 35px 0 20px;
    }

    &--fullscreen {
        height: 100vh;

        @include respond-to(xs-max) { height: $xs-min; }

        @include respond-to(xxs-max) { height: 100vh; }
    }

    &--tinted {
        &:before {
            background: rgba($color-03, .7);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    // background position (mobile only)
    &-pos {
        &--right {
            @include respond-to(xxs-max) { background-position: right 5% center; }

            &:not(.banner--tinted) {
                @include respond-to(xxs-max) { background-position: left 5% center; }
            }
        }

        &--left {
            @include respond-to(xxs-max) { background-position: left 5% center; }

            &:not(.banner--tinted) {
                @include respond-to(xxs-max) { background-position: right 5% center; }
            }
        }
    }

    &__container {
        .banner--color &,
        .banner--image &,
        .banner--video & {
            display: table;
            height: 100%;
            text-align: center;
            width: 100%;

            position: relative;
        }
    }

    &__inner {
        display: table-cell;
        vertical-align: middle;
    }

    &__video {
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        // @include respond-to(ipad-max) {
        //     background-image: none !important;
        // }

        &-player {
            // display: block;
            min-height: 100%;
            height: auto;
            min-width: 100%;
            width: auto;

            position: absolute;
            left: 50%;

            @include transform(translateX(-50%));

            @include respond-to(ipad-max) {
                display: none;
            }
        }

        &-frame {
            border: 0;
            display: block;
            min-height: 110%;
            height: auto;
            min-width: 280%;
            width: auto;

            position: absolute;
            // left: -70%;
            left: -90%; // calc: (min-width - 2) - 50%
            top: 0;

            @include respond-to(ipad-max) {
                display: none;
            }

            @include respond-to(xxl-min) {
                min-height: 120%;
                min-width: 200%;

                // left: -50%;
                left: -50%; // calc: (min-width - 2) - 50%
            }
        }
    }

    &__logo {
        height: 25px;
        margin-left: -84px; // NOTE: width of image is 168px

        position: absolute;
        top: 0;
        left: 50%;

        @include respond-to(xxl-min) {
            height: 40px;
            margin-left: -134px; // NOTE: width of image is 268px
        }
    }

    &__sub-title {
        border-top-width: 0;
        letter-spacing: 6.3px;
        margin-bottom: 30px;
        padding-top: 0;

        @include respond-to(xxl-min) {
            font-size: $lead-desktop;
            line-height: $lead-desktop-leading;
        }
    }

    &__title {

        @include respond-to(sm-min) {
            margin-left: auto;
            margin-right: auto;
            width: 60%;
        }

        @include respond-to(lg-min) { width: 50%; }

        @include respond-to(xxl-min) {
            font-size: $h0-desktop;
            line-height: $h0-desktop-leading;
        }

        &--large { @extend .h1; }
    }

    &__body {

        @include respond-to(sm-min) {
            margin-left: auto;
            margin-right: auto;
            width: 60%;
        }

        @include respond-to(lg-min) { width: 40%; }

        @include respond-to(xxl-min) {
            font-size: 18px;
            line-height: 27px; // NOTE: check with creative?
        }

        p:last-child {
            margin-bottom: 30px;
        }
    }

    &__btn {
        // Title - Small
        .banner__title + & {
            margin-top: 25px;

            @include respond-to(md-min) { margin-top: 8px; }
        }

        // Title - Large
        .banner__title--large + & {
            @include respond-to(sm-max) { margin-top: 30px; }

            @include respond-to(xs-max) { margin-top: 30px; }
        }

        .banner__body + & { margin-top: 7px; }
    }

    &__jump {
        @extend %font-primary-bold;
        background: transparent;
        border: 0;
        letter-spacing: 1px;
        margin-left: -40%;
        padding-bottom: 34px; // calc: 24px (computed height of down-arrow) + 10px
        text-transform: uppercase;
        width: 80%;

        position: absolute;
        bottom: 20px; // TODO: check if this is final
        left: 50%;

        @include respond-to(sm-min) {
            margin-left: -125px;
            max-width: 250px;
        }

        &:before {
            text-align: center;
            width: 100%;

            position: absolute;
            bottom: 0;
            left: 0;
        }

        // @include respond-to(xs-max) { bottom: 16.824196%; } // TODO: check if this is final

        &:hover {
            &:before {
                @include animation(bounce 1.2s infinite);
            }
        }

        .theme--dark & {
            color: $color-01;
        }
    }
}
