// =============================================================================
// Buttons
// =============================================================================
// button: btn prefix used for all button variables
$btn-border: 1px;
$btn-height: 56px;
$btn-padding: 24px;

$btn-desktop: 14px;
$btn-desktop-leading: 16px;
$btn-mobile: 14px;
$btn-mobile-leading: 16px;

$btn-height-text: 36px;

$btn-shadow: 72px; //NOTE: 72px = computed height of button spanning 2 lines

.button {
    font-size: 0;
    margin-top: -16px;
    margin-left: -16px;

    &:after {
        content: '';
        clear: both;
        display: block;
    }

    &__wrap {
        display: inline-block;
        padding-top: 16px;
        padding-left: 16px;


        @include respond-to(xs-max) { width: 100%; }

        .orientation--stacked & {
            display: block;
        }
    }

    &__item {
        @include respond-to(xs-max) { width: 100%; }
    }

    &.align--right {
        text-align: right;
    }

    &.align--center {
        text-align: center;
    }
}

button,
.btn {
    @extend %clear-default-appearance;
    @extend %hover;

    cursor: pointer;
    overflow: visible;

    &:focus,
    &:hover {
        text-decoration: none; // NOTE: use case <a>


    }
}

input {
    &[type='button'],
    &[type='reset'],
    &[type='submit'] {
        @extend %clear-default-appearance;
    }
}

.btn {
    @extend %font-primary;
    @extend %font-primary-bold;

    border-color: transparent;
    border-style: solid;
    border-width: $btn-border;
    display: inline-block;
    letter-spacing: 1px;
    text-align: center; // NOTE: use case <a>
    text-decoration: none !important; // NOTE: use case <a>
    text-transform: uppercase;

    position: relative;

    transition: all .3s;

    @include respond-to(xs-max) { width: 100%; }

    @include respond-to(sm-min) {
        min-width: 120px;
    }

    &--solid,
    &--outline {

        color: $color-03;
        font-size: $btn-mobile;
        line-height: $btn-mobile-leading;

        @include respond-to(sm-min) {
            font-size: $btn-desktop;
            line-height: $btn-desktop-leading;
        }

        &:after {
            background-color: $color-03;
            content: '';
            height: 100%;
            width: 0;

            position: absolute;
            z-index: 6;
            top: 0;
            left: 0;

            transition: all .3s;
        }

        &:focus,
        &:hover {

            &:after {
                width: 100%;
            }
        }

        // &.disabled {
        //     background: $color-09;
        //     border-color: $color-09;
        //     color: $color-01;
        //
        //     &:focus,
        //     &:hover {
        //         background: $color-09;
        //         box-shadow: inset 0 0 0 0 $color-03;
        //         color: $color-01;
        //     }
        // }
    }

    &--outline {
        // background-image: linear-gradient(to right, $color-01 50%, $color-03 50%), linear-gradient(to right, $color-03 50%, $color-01 50%);
        background-color: transparent;
        border-color: $color-03;
        padding: ($btn-height - ($btn-border * 2) - $btn-mobile-leading) / 2 $btn-padding;

        @include respond-to(sm-min) {
            padding: ($btn-height - ($btn-border * 2) - $btn-desktop-leading) / 2 $btn-padding;
        }

        &:focus,
        &:hover {
            color: $color-01;
        }

        .bg--black &,
        .bg--crimson &,
        .bg--dark-grey &,
        .bg--purple &,
        .bg--teal & {
            border-color: $color-01;
            color: $color-01;

            &:after {
                background-color: $color-01;
            }

            &:focus,
            &:hover {
                color: $color-03;
            }

            &.disabled {
                border-color: rgba($color-01, .6);

                &:focus,
                &:hover {
                    color: $color-01;
                }
            }
        }

        &.disabled {
            border-color: rgba($color-03, .6);
            opacity: .6;


            &:focus,
            &:hover {
                color: $color-03;
                outline: 0;
            }
        }
    }

    &--solid {
        // background-image: linear-gradient(to right, $color-06 50%, $color-03 50%), linear-gradient(to right, $color-03 50%, $color-06 50%);
        background-color: $color-06;
        border-width: 0;
        padding: ($btn-height - $btn-mobile-leading) / 2 $btn-padding;

        @include respond-to(sm-min) {
            padding: ($btn-height - $btn-desktop-leading) / 2 $btn-padding;
        }

        &:focus,
        &:hover {
            color: $color-06;
        }

        .bg--black &,
        .bg--dark-grey & {

            &:after {
                background-color: $color-01;
            }

            &:focus,
            &:hover {
                color: $color-03;
            }
        }

        .bg--orange &,
        .bg--purple &,
        .bg--red &,
        .bg--teal & {
            background-color: $color-01;
        }

        .bg--orange & {
            &:focus,
            &:hover {
                color: $color-01;
            }
        }

        &.disabled {
            opacity: .6;


            &:focus,
            &:hover {
                color: $color-03;
                outline: 0;
            }
        }
    }

    &--text {
        @extend %font-primary-book;

        background-color: transparent;
        border-width: 0;
        color: $color-03;
        font-size: $btn-mobile;
        line-height: $btn-mobile-leading;
        padding: (($btn-height-text - $btn-mobile-leading) / 2) 0;

        @include respond-to(sm-min) {
            // NOTE: below are in case there changes to mobile font sizes
            // font-size: $btn-mobile;
            // line-height: $btn-mobile-leading;
            // padding: (($btn-height-text - $btn-mobile-leading) / 2) 0;
            // NOTE: above are in case there changes to mobile font sizes
        }

        &:focus,
        &:hover {
            color: $color-03;

            &:after {
                width: 100%;
            }
        }

        &:before,
        &:after {
            content: '';
            height: 2px;

            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:before {
            background-color: $color-03;
            width: 100%;
        }

        &:after {
            background-color: $color-06;
            width: 0;

            transition: all .3s;
        }

        .bg--black &,
        .bg--crimson &,
        .bg--dark-grey &,
        .bg--purple &,
        .bg--teal & {
            color: $color-01;

            &:before {
                background-color: $color-01;
            }
        }

        .bg--orange &,
        .bg--red & {
            &:after {
                background-color: $color-01;
            }
        }
    }

    &__text {
        position: relative;
        z-index: 7;
    }
}

.disabled {
    &:focus,
    &:hover {
        cursor: default;

        &:after {
            width: 0;
        }
    }
}

.component--button {
    // @extend %two-cols-first-col;
    // @extend %two-cols-first-col-padding;
    //
    // @extend %two-cols-second-col;
    // @extend %two-cols-second-col-padding;
}


