// =============================================================================
// Checklist
// =============================================================================
.component--checklist {

}

.checklist {
    background-color: $color-04;
    padding: $gutter * 2;

    @include respond-to(sm-min) {
        max-width: 456px;
    }

    &__title {
        margin: 0 0 12px;
    }

    &__list {
        @extend %list;
        margin: 0 0 27px;

        li:before {
            color: $color-11;
        }
    }

    &__btn-pane {
        font-size: 0;
        margin-top: -$gutter;
        margin-bottom: 30px;
        margin-left: -$gutter;
    }

    &__btn-wrap {
        display: block;
        padding-top: $gutter;
        padding-left: $gutter;

        @include respond-to(sm-min) {
            display: inline-block;
        }
    }

    &__btn {
        @include respond-to(xs-max) { display: block; }
    }
}
