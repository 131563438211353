.fc {
    &__button {
    	margin-top: 24px;
    	float: right;

    	&-left {
    		float: left;
    	}
    }
}
