// =============================================================================
// Step Status
// =============================================================================
// $ss prefix used for all step status variables
$ss-icon: 20px;

// .component {
//     &--step-status {
//
//     }
// }

.ss {
    &__text {
        // background: rgba(#fc0, .2);
        display: block;
        line-height: $h3-mobile-leading;
        padding-left: 30px;

        position: relative;

        @include respond-to(sm-min) {
            font-size: $lead-desktop;
        }

        &:before {
            border-radius: 50%;
            font-size: $font-size-fine;
            line-height: $ss-icon;
            height: $ss-icon;
            text-align: center;
            width: $ss-icon;

            position: absolute;
            left: 0;
            top: ($h3-mobile-leading - $ss-icon) / 2;
        }
    }

    &--default {
        color: $color-11;

        &:before {
            color: $color-03;
            font-size: $ss-icon;
        }
    }

    &--complete {
        color: $color-17;

        &:before {
            background-color: $color-17;
            color: $color-01;
        }
    }

    &--overdue {
        color: $color-18;

        &:before {
            background-color: $color-18;
            color: $color-01;
        }
    }

    &--lock {
        color: $color-11;

        &:before {
            background-color: $color-09;
            color: $color-01;
        }
    }
}


