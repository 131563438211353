.tabs {
    &__label {
        display: none;
    }

    &__dropdown {
        @extend %drop-down-caret;

        @include background-context-dark {
            &:after {
                border-top-color: $color-01;
            }
        }

        display: inline-block;
        width: 100%;

        position: relative;

        @include respond-to(sm-min) {
            width: auto;
            // max-width: 224px;
        }
    }

    &__select {
        @extend %font-primary-bold;

        @include background-context-dark {
            border-bottom-color: $color-01;
            color: $color-01;
        }

        // change background of select based on the background of column or band (container)
        @include background-context-match;


        background-color: transparent;
        border-bottom: 1px solid $color-03;
        border-radius: 0;
        font-size: $font-size-mobile;
        line-height: $font-leading-mobile;
        letter-spacing: 1px;
        padding: 8px 24px 7px 0;
        text-transform: uppercase;

        position: relative;

        // Remove default dropdown arrow from IE
        &::-ms-expand {
            display: none;
        }
    }

    &__content {
        @include background-context-dark { color: $color-01; }

        padding-top: 24px;
    }

    &__pane {
        @extend %inpage-link;
        display: none;
    }
}

.tab--active {
    display: block;
}
