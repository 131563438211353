$an-tray-icon-size: 56px;
$an-tray-icon-size-md: 72px;
$an-nav-width: 100vw;
$an-nav-width-md: 500px;
$an-nav-width-xxl: 768px;
$an-item-height: 64px;
$an-item-height-md: 96px;
$an-item-height-xxl: 128px;

// Font sizes
$an-primary-item-font-size: 20px;
$an-primary-item-font-size-md: 24px;
$an-secondary-item-font-size: 20px;
$an-secondary-item-font-size-md: 24px;
$an-tertiary-item-font-size: 16px;
$an-tertiary-item-font-size-md: 18px;

.acc-nav {
    color: $color-11;
    position: fixed;
    width: 100%;
    z-index: 99;

    &:before {
        @include transition((width .3s, opacity .3s, visibility .3s));
        background: $color-01;
        content: '';
        height: $an-tray-icon-size;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 0;

        @include respond-to(md-min) {
            background: $color-03;
            height: 100vh;
            width: 100%;
        }
    }

    &__root--open {
        overflow: hidden;
    }

    &--primary-open {
        width: 100%;
        height: 100vh;

        &:before {
            @include transition((width .3s, opacity .3s));
            opacity: 1;
            visibility: visible;
            width: 100%;

            @include respond-to(md-min) {
                opacity: .5;
            }
        }
    }

    // Tray
    &__tray {
        @include transition(top .3s, left .3s);
        height: $an-tray-icon-size;
        left: 0;
        position: absolute;
        top: 0;

        @include respond-to(md-min) {
            height: $an-tray-icon-size-md;
        }

        &-nav {
            width: $an-tray-icon-size;

            @include respond-to(md-min) {
                width: $an-tray-icon-size-md;
            }

            .acc-nav--primary-open & {
                @include respond-to(md-min) {
                    left: $an-nav-width-md;
                }

                @include respond-to(xxl-min) {
                    left: $an-nav-width-xxl;
                }
            }

            .acc-nav--secondary-open & {
                @include respond-to(md-min) {
                    left: $an-nav-width-md;
                }

                @include respond-to(lg-min) {
                    left: $an-nav-width-md * 2;
                }

                @include respond-to(xxl-min) {
                    left: $an-nav-width-xxl * 2;
                }
            }
        }

        &-bar {
            left: $an-tray-icon-size;
            right: 0;

            @include respond-to(md-min) {
                left: $an-tray-icon-size-md;
            }

            .acc-nav--primary-open & {
                @include respond-to(md-min) {
                    left: $an-nav-width-md + $an-tray-icon-size-md;;
                }

                @include respond-to(xxl-min) {
                    left: $an-nav-width-xxl + $an-tray-icon-size-md;;
                }
            }

            .acc-nav--secondary-open & {
                @include respond-to(md-min) {
                    left: $an-nav-width-md + $an-tray-icon-size-md;
                }

                @include respond-to(lg-min) {
                    left: $an-nav-width-md * 2 + $an-tray-icon-size-md;
                }

                @include respond-to(xxl-min) {
                    left: $an-nav-width-xxl * 2 + $an-tray-icon-size-md;
                }
            }
        }

        .acc-nav--retracted & {
            top: -$an-tray-icon-size;

            @include respond-to(md-min) {
                top: -$an-tray-icon-size-md;
            }
        }
    }

    &__nav-controls {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
    }

    &__toggle-button,
    &__logo,
    &__search-button {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: $an-tray-icon-size;

        @include respond-to(md-min) {
            width: $an-tray-icon-size-md;
        }
    }

    &__toggle-button,
    &__search-button {
        background: $color-03;
        border-radius: 0;
        border: 0;
        color: $color-01;
        font-size: 24px;
        padding: 0;
        margin: 0;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            color: $color-01;
            text-decoration: none;
        }

        &:before,
        &:after {
            left: ($an-tray-icon-size - 24px) / 2;
            position: absolute;
            text-align: center;
            top: ($an-tray-icon-size - 24px) / 2;
            width: 22px;

            @include respond-to(md-min) {
                left: ($an-tray-icon-size-md - 24px) / 2;
                top: ($an-tray-icon-size-md - 24px) / 2;
            }
        }
    }

    &__toggle-button {
        left: 0;

        &:before,
        &:after {
            display: inline-block;
            transition: opacity 0s linear, transform .3s cubic-bezier(.77, 0, .175, 1);
            -webkit-transition: opacity 0s linear, transform .3s cubic-bezier(.77, 0, .175, 1);
        }

        &:before {
            content: '\e921';

            .acc-nav--primary-open & {
                @include transform(rotateX(180deg));
                opacity: 0;
            }
        }

        &:after {
            content: '\e916';
            opacity: 0;

            .acc-nav--primary-open & {
                @include transform(rotateX(180deg));
                opacity: 1;
            }
        }
    }

    &__logo {
        background: $color-02;
    }

    &__logo-image {
        display: block;
        width: 100%;
    }

    &__search-button {
        right: 0;
        @include transition(opacity .3s);

        .acc-nav--primary-open & {
            opacity: 0;
            pointer-events: none;

            @include respond-to(md-min) {
                opacity: .5;
            }
        }
    }

    // Containers
    &__container {
        background: $color-01;
        height: calc(100vh - #{$an-tray-icon-size});
        overflow-y: auto;

        @include respond-to(md-min) {
            height: 100vh;
        }
    }

    // Primary Container
    &__container--primary {
        @include transition(left .3s);
        left: -$an-nav-width;
        position: absolute;
        top: $an-tray-icon-size;
        width: $an-nav-width;

        @include respond-to(md-min) {
            left: -$an-nav-width-md;
            top: 0;
            width: $an-nav-width-md;
        }

        @include respond-to(xxl-min) {
            left: -$an-nav-width-xxl;
            width: $an-nav-width-xxl;
        }

        .acc-nav--primary-open & {
            left: 0;
        }

        .acc-nav--secondary-open & {
            left: -$an-nav-width;

            @include respond-to(md-min) {
                left: -$an-nav-width-md;
            }

            @include respond-to(lg-min) {
                left: 0;
            }
        }
    }

    // Secondary Container
    &__container--secondary {
        @include transition((width .3s, left .3s, visibility .3s));
        left: 0;
        overflow-x: hidden;
        position: fixed;
        top: $an-tray-icon-size;
        visibility: hidden;
        width: 0;
        z-index: 1;

        @include respond-to(md-min) {
            top: 0;
        }

        .acc-nav--primary-open & {
            left: $an-nav-width;

            @include respond-to(md-min) {
                left: $an-nav-width-md;
            }

            @include respond-to(xxl-min) {
                left: $an-nav-width-xxl;
            }
        }

        .acc-nav__item--open & {
            left: 0;
            width: $an-nav-width;
            visibility: visible;
            @include transition((width .3s, left .3s, visibility .3s));

            @include respond-to(md-min) {
                width: $an-nav-width-md;
            }

            @include respond-to(lg-min) {
                left: $an-nav-width-md;
            }

            @include respond-to(xxl-min) {
                left: $an-nav-width-xxl;
                width: $an-nav-width-xxl;
            }
        }

        &:after {
            @include respond-to(lg-min) {
                background: $color-04;
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        &--secondary {
            bottom: 0;
            margin-top: $an-item-height;
            position: absolute;
            left: 0;
            overflow-x: hidden;
            overflow-y: auto;
            right: 0;
            top: 0;

            @include respond-to(md-min) {
                margin-top: $an-item-height-md;
            }

            @include respond-to(lg-min) {
                margin-top: 0;
            }
        }

        &--tertiary {
            margin: 12px 0;

            @include respond-to(md-min) {
                margin: 24px 0;
            }
        }
    }

    &__item-heading {
        margin: 0;

        @include respond-to(lg-min) {
            display: none;
        }
    }

    &__item {
        margin: 0;
        padding: 0;

        &--primary {
            position: relative;
        }

        &--heading {
            position: relative;

            @include respond-to(lg-min) {
                display: none;
            }
        }
    }

    &__anchor {
        @include flex;
        @include transition((background .3s, color .3s, border .3s));
        align-items: center;
        background: $color-01;
        border: 0;
        color: inherit;
        font-family: $font-family-secondary;
        height: $an-item-height;
        margin: 0;
        padding: 0 0 0 18px;
        position: relative;
        text-align: left;
        text-decoration: none;
        vertical-align: top;
        width: $an-nav-width;

        @include respond-to(md-min) {
            border-top: 1px solid $color-04;
            height: $an-item-height-md;
            padding: 0 0 0 56px;
            width: $an-nav-width-md;
        }

        @include respond-to(xxl-min) {
            height: $an-item-height-xxl;
            width: $an-nav-width-xxl;
        }

        .acc-nav__item:first-child & {
            @include respond-to(md-min) {
                border-top: 0;
            }
        }

        &:focus,
        &:hover,
        &:active {
            background: $color-04;
            color: inherit;
            text-decoration: none;
        }

        // Anchor's open item styles.
        .acc-nav__item--open > & {
            background: $color-04;
        }

        // Open primary item's anchors and secondary list headings have same style.
        .acc-nav__item--open.acc-nav__item--primary > & {
            @include respond-to(lg-min) {
                background: $color-03;
                border-top: 1px solid $color-03;
                color: $color-01;
                font-weight: normal;
            }
        }

        .acc-nav__item--heading & {
            background: $color-03;
            color: $color-01;
            font-weight: normal;
            // position: absolute;
            // top: 0;

            @include respond-to(md-min) {
                border-top: 1px solid $color-12;
            }
        }

        // Primary anchors and heading items
        .acc-nav__item--heading > &,
        .acc-nav__item--primary > & {
            font-size: $an-primary-item-font-size;

            @include respond-to(md-min) {
                font-size: $an-primary-item-font-size-md;
            }
        }

        // Secondary anchors
        .acc-nav__item--secondary > & {
            font-size: $an-secondary-item-font-size;

            @include respond-to(md-min) {
                font-size: $an-secondary-item-font-size-md;
            }
        }

        // Tertiary anchors.
        .acc-nav__item--tertiary > & {
            border: 0;
            font-size: $an-tertiary-item-font-size;
            height: $an-item-height;
            padding: 0 0 0 56px;

            @include respond-to(md-min) {
                font-size: $an-tertiary-item-font-size-md;
                padding: 0 0 0 88px;
            }
        }
    }

    // Icons
    &__anchor-icon {
        border: 0;
        border-radius: 0;
        margin: 0;

        // height: calc(100% + 1px); // NOTE: 1px is to overlap the border on <a>
        // height: 100%;
        width: $an-item-height;

        position: absolute;
        right: 0;
        top: 0;

        @include respond-to(md-min) {
            width: $an-item-height-md;
        }

        @include respond-to(xxl-min) {
            width: $an-item-height-xxl;
        }

        .acc-nav__item--primary > .acc-nav__anchor + &,
        .acc-nav__item--heading > .acc-nav__anchor + & {
            height: 100%;
            // height: calc(100% + 1px); // NOTE: 1px is to overlap the border on <a>
        }

        .acc-nav__item--secondary > .acc-nav__anchor + & {
            height: $an-item-height;

            @include respond-to(md-min) {
                height: $an-item-height-md;
            }

            @include respond-to(xxl-min) {
                height: $an-item-height-xxl;
            }
        }

        &:focus,
        &:hover {
            background-color: $color-06;
        }

        &--child-arrow {
            background: $color-04;
            @include transition(background .3s);

            // @include respond-to(md-min) {
            //     margin-top: -1px;
            // }

            &:before {
                font-size: 24px;
                left: 50%;
                line-height: 24px;
                margin-left: -12px;
                margin-top: -12px;
                position: absolute;
                top: 50%;
                transition: transform .3s;
                -webkit-transition: -webkit-transform .3s;
            }

            &:after {
                bottom: -1px;
                content: '';
                height: 1px;
                position: absolute;
                width: 100%;
                @include transition(background .3s);
            }

            .acc-nav__item--heading &,
            .acc-nav__item--open > .acc-nav__anchor & {
                background: $color-06;
                color: $color-03;

                &:before {
                    @include transform(rotate(180deg));
                }

                &:after {
                    @include respond-to(md-min) {
                        background: $color-06;
                    }
                }
            }
        }

        &--child-plus {
            background: $color-04;
            @include transition(background .3s);

            // @include respond-to(md-min) {
            //     margin-top: -1px;
            // }

            &:after,
            &:before {
                background: $color-03;
                content: '';
                display: block;
                height: 16px;
                left: 50%;
                margin-left: -1px;
                margin-top: -8px;
                position: absolute;
                top: 50%;
                width: 2px;
                transition: opacity .3s, transform .3s;
                -webkit-transition: opacity .3s, -webkit-transform .3s;
            }

            &:after {
                height: 2px;
                margin-left: -8px;
                margin-top: -1px;
                width: 16px;
            }

            // .acc-nav__item--heading > .acc-nav__anchor + & {
            //     &:before,
            //     &:after {
            //         @include transform(rotate(90deg));
            //     }
            //
            //     &:after {
            //         opacity: 0;
            //     }
            // }

            .acc-nav__item--open .acc-nav__container .acc-nav__item--heading > .acc-nav__anchor + &,
            .acc-nav__item--open > .acc-nav__anchor + & {
                background: $color-06;

                &:before,
                &:after {
                    @include transform(rotate(90deg));
                }

                &:after {
                    opacity: 0;
                }
            }
        }
    }
}
