%fc-text {
    @extend %clear-default-appearance;


    // Chrome/Opera/Safari
    &::-webkit-input-placeholder {
        color: $color-08;
    }

    // Firefox 19+
    &::-moz-placeholder {
        color: $color-08;
    }

    // IE 10+
    &:-ms-input-placeholder {
        color: $color-08;
    }

    // Firefox 18-
    &:-moz-placeholder {
        color: $color-08;
    }

    &:focus {
        outline: 0;
    }
}

.fc {
    &__text,
    &__number,
    &__tel,
    &__email {
        @extend %fc-input;
        @extend %fc-text-padding;
        @extend %fc-text;

        .fc--error & {
            border-bottom-color: $color-18;
        }
    }
}

