
// $gn prefix used for all global nav variables

$gn-footer-pad-top: 75px;
$gn-footer-logo: 25px;
$gn-footer-logo-pad-bottom: 30px;

$gn-account-info: 172px; // NOTE: this is computed height

.component--global-nav {
    background-color: $color-01;

    float: left;
    height: 100%;
    width: $gn-width;

    position: fixed;
    z-index: 99;
    left: -$gn-width;
    top: 0;
    // bottom: 0;

    @include transition(left .3s ease-in-out);

    @include respond-to(xl-min) {
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
        left: 0;
    }

    @include respond-to(xxl-min) {
        width: $gn-width-hd;
    }

    &.panel--expanded {
        @include respond-to(lg-max) {
            box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
        }

        @include respond-to(xl-max) {
            left: 0;

            @include transition(left .3s ease-in-out);
        }
    }
}

.gn {
    &__wrap {
        // background: rgba(#ccc, .6);
        overflow-y: auto;

        position: absolute;
        top: $gn-account-info;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__inner {
        min-height: calc(100vh - #{$gn-account-info});
        padding-bottom: $gn-footer-pad-top + $gn-footer-logo + $gn-footer-logo-pad-bottom;

        position: relative;
    }

    // &__nav {
    //
    // }

    &__account-information {
        border-bottom: 1px solid $color-09;
        padding: 41px 0 38px;
    }

    &__initials {
        background-color: $color-02;
        border-radius: 50%;
        color: $color-01;
        height: 65px;
        font-size: 26px;
        // letter-spacing: 3px;
        line-height: 65px;
        margin: auto;
        text-align: center;
        width: 65px;
    }

    &__name {
        color: $color-11;
        font-size: 16px;
        line-height: 22px;
        margin-top: 5px;
        text-align: center;
    }

    &__secondary {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            border-top: 1px solid $color-09;
            font-size: $font-size-fine;
            letter-spacing: .5px;
            margin: 0;
            padding: 13px 0 12px;

            &:first-child {
                border-top: 0;
            }

            a {
                color: $color-11;
                text-decoration: none;
            }
        }
    }

    &__list {
        padding: 45px 0 0;
        margin: 0;
        list-style: none;
    }

    &__item {
        opacity: 0;
        transform: translateY(100%);
        transition: transform .7s, opacity .7s;

        &--visible {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__item-link {
        border-left: 3px solid transparent;
        display: inline-block;
        position: relative;
        width: 100%;
        padding-left: 37px;
        padding: 10px 0 10px 70px;
        color: $color-11;
        line-height: 24px;
        text-decoration: none;

        @include transition(all .35s linear);

        &:focus,
        &:hover {
            background-color: $color-04;
            color: $color-11;
            text-decoration: none;
            outline: 0;

            @include transition(all .35s linear);
        }

        &.active {
            border-left-color: $color-05;
            color: $color-11;
        }

        &:before {
            position: absolute;
            left: 33px;
            top: 50%;
            width: 18px;
            margin-top: -12px;
            color: $color-10;
            text-align: center;
        }
    }

    &__item-text {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
    }

    &__item-notification {
        position: absolute;
        right: 21px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        border-radius: 10px;
        background-color: $color-05;
        color: $color-01;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        transform: scale(0);
        transition: transform .5s cubic-bezier(.64, 1.81, .7, .91);
    }

    &__item-notification--visible {
        transform: scale(1);
    }

    &__trigger {
        background: transparent;
        // background: rgba($color-03, .7);
        border: 0;
        height: scale-pixel-value($nav-trigger);
        padding: scale-pixel-value($nav-trigger-pad-top) scale-pixel-value(($nav-trigger - $nav-trigger-width) / 2);
        width: scale-pixel-value($nav-trigger);

        position: absolute;
        z-index: 98;
        top: 0;
        left: 0;

        @include respond-to(sm-min) {
            top: $dash-border;
            left: $dash-border;
        }

        @include respond-to(xl-min) { display: none; }

        &:hover
        // ,&:focus
        {
            outline: 0;
        }

        span {
            background: $color-01;
            float: left;
            font-size: 0;
            height: $nav-trigger-line;
            width: 100%;

            position: relative;
            top: 0;

            &:before,
            &:after {
                content: '';
                background: $color-01;
                height: $nav-trigger-line;
                width: 100%;

                position: absolute;
                left: 0;
    		}

            &:before { top: - ($nav-trigger-line-distance + $nav-trigger-line); }
            &:after  { top: ($nav-trigger-line-distance + $nav-trigger-line); }
        }
    }

    &__footer {
        text-align: center;
        width: 100%;

        position: absolute;
        bottom: 0;

        min-height: $gn-footer-pad-top + $gn-footer-logo + $gn-footer-logo-pad-bottom;

        .iphone & {
            // background-color: rgba(#fc0, .2);

            // &:after {
            //     background-color: rgba($color-10, .3);
            //     content: '';
            //     height: 44px;
            //     width: 100%;
            //
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            // }
        }

        &-logo {
            height: $gn-footer-logo;
            margin-top: $gn-footer-pad-top;

            // .iphone & {
                // margin-top: $gn-footer-pad-top - $iphone-safari-nav-height;
                // margin-top: $gutter * 2;
            // }
        }
    }
}
