$mini-card-padding: 16px;

.component--mini-cards {
    width: 100%;
    padding-top: $vertical-gutter-mobile;

    @include respond-to(sm-min) { 
        padding-top: 0;
    }

    @include respond-to(sm-min) {
        width: $col-sm-1 * 6 - $gutter-sm * 2;
    }   

    @include respond-to(md-min) {
        width: $col-md-1 * 6 - $gutter-sm * 2;
    }   

    @include respond-to(lg-min) {
        width: $col-lg-1 * 6 - $gutter-sm * 2;
    }   

    @include respond-to(xxl-min) {
        width: $col-xxl-1 * 6 - $gutter-sm * 2;
    }

    .mini-cards__wrapper {
        @extend %clear;
    }   

    .component--title {
        padding-bottom: $vertical-gutter-mobile;

        @include respond-to(sm-min) { 
            padding-bottom: $vertical-gutter-desktop;
        }
    }
}

.mini-card {
    position: relative;
    perspective: 1000;
    cursor: pointer;
    margin-top: $mini-card-padding;
	width: 100%;
	height: $h3-desktop-leading * 4 + $mini-card-padding * 3;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @include respond-to(lg-min) {
		width: $col-lg-1 * 2 - $gutter-sm * 2;
		height: $col-lg-1 * 2 - $gutter-sm * 2;
    	margin-top: 0;
    	margin-left: $mini-card-padding;
    	float: left;
    }

    @include respond-to(xxl-min) {
		width: $col-xxl-1 * 2 - $gutter-sm * 2;
		height: $col-xxl-1 * 2 - $gutter-sm * 2;
    }

    &:first-child {
    	margin-top: 0;

    	@include respond-to(lg-min) {
    		margin-left: 0;
    	}
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        @include animate('all', 500, easeInOutCubic);

    }

    &__front{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        @include animate('all', 500, easeInOutCubic);
        z-index: 2;
        transform: rotateY(0deg);
        background-color: $color-04;
        border: none;
		border-top: 4px solid $color-03;
        text-align: left;
		padding: $mini-card-padding;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

		&:before {
			position: absolute;
			right: $mini-card-padding;
			bottom: $mini-card-padding;
		    font-size: 24px;
			line-height: $h3-desktop-leading;

		    @include respond-to(lg-min) {
				line-height: $lead-mobile-leading;
		    }

		    @include respond-to(xxl-min) {
				line-height: $h3-desktop-leading;
		    }
		}
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        @include animate('all', 500, easeInOutCubic);
        transform: rotateY(-180deg);
        background-color: $color-06;
		border-top: 4px solid $color-03;
		padding: $mini-card-padding;

		&:before {
			position: absolute;
			right: $mini-card-padding;
			bottom: $mini-card-padding;
		    font-size: 24px;
            line-height: $h3-desktop-leading;

            @include respond-to(lg-min) {
                line-height: $lead-mobile-leading;
            }

            @include respond-to(xxl-min) {
                line-height: $h3-desktop-leading;
            }
		}
    }

    &__flipped {
	    z-index: 2;

	    .mini-card__front {
	        transform: rotateY(180deg);
	    }

	    .mini-card__back {
	        transform: rotateY(0deg);
	    }
    }

    &__label {
        @extend %font-primary-medium;
        font-size: $font-size-fine;
        line-height: $font-leading-mobile;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__info {
    	position: absolute;
    	bottom: $mini-card-padding;
    	left: $mini-card-padding;
    	right: $mini-card-padding * 2;
    }

    &__points {
    	@extend %font-primary-medium;
        font-weight: 300;
        font-size: $h2-desktop;
        line-height: $h2-desktop-leading;
    	padding-top: 6px;
    }

    &__note {
    	font-size: $font-size-mobile;
    	line-height: $font-leading-mobile;
        padding-top: 6px;
	}

    &__title {
    	position: absolute;
    	left: $mini-card-padding;
    	right: $mini-card-padding * 2;
    	bottom: $mini-card-padding;
    }

    &__title-wrapper {
        @extend %font-secondary;
        font-size: $h3-desktop;
        line-height: $h3-desktop-leading;
        max-height: $h3-desktop-leading * 4;
        overflow: hidden;

        @include respond-to(lg-min) {
            font-size: $lead-mobile;
            line-height: $lead-mobile-leading;
            max-height:$lead-mobile-leading * 7;
        }

        @include respond-to(xxl-min) {
            font-size: $h3-desktop;
            line-height: $h3-desktop-leading;
            max-height: $h3-desktop-leading * 6;
        }
    }
}