// =============================================================================
// 	Themes
// =============================================================================

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
// a,
blockquote, .blockquote,
.list--unordered,
.list--ordered,
.list--linked,
.list--icon {
    // Light Theme:
    .theme--light & { color: $color-03; }

    // .theme--light.bg--white & {
    //     &.color--mid-grey-2 { color: $color-11; }
    //     &.color--crimson { color: $color-02; }
    //     &.color--red { color: $color-05; }
    //     &.color--orange-2 { color: $color-07; }
    //     &.color--orange { color: $color-06; }
    // }

    // Dark Theme:
    .theme--dark & { color: $color-01; }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
    // Light Theme:
    .theme--light & {
        &.color--crimson { color: $color-02; }
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {
    // Light Theme:
    .theme--light & {
        &.color--orange { color: $color-06; }
    }

    // Dark Theme:
    .theme--dark & {
        &.color--orange { color: $color-06; }
    }
}

h5, .h5 {
    // Light Theme:
    .theme--light & {
        &.color--crimson { color: $color-02; }
        &.color--orange { color: $color-06; }
    }

    // Dark Theme:
    .theme--dark & {
        &.color--orange { color: $color-06; }
    }
}

h5, .h5,
blockquote, .blockquote {
    // Dark Theme:
    .theme--dark & {
        border-color: $color-01;
    }
}

p {
    .small,
    &.small {
        // Light Theme:
        .theme--light & {
            color: $color-11;
        }

        // Dark Light:
        .theme--dark & {
            color: $color-01;
        }
    }
}

// a {
//     // Dark Theme:
//     .theme--dark & {
//         text-decoration: underline;
//
//         &:focus,
//         &:hover {
//             color: $color-01;
//         }
//     }
//
//     // Light Theme:
//     .theme--light & {
//         color: $color-05;
//
//         &:focus,
//         &:hover {
//             color: $color-15;
//         }
//     }
// }

.list--linked {
    // Dark Theme:
    .theme--dark & {
        li {
            a {
                color: $color-01;

                &:hover {
                    color: $color-01;
                    text-decoration: underline;
                }
            }
        }
    }
}

.list--linked,
.list--icon {
    // Dark Theme:
    .theme--dark & {
        li {
            &:before {
                color: $color-01;
            }
        }
    }
}