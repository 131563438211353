// =============================================================================
// Contact
// =============================================================================

// .component--contact {
//
// }

.contact {
    &__title {
        border-top: 1px solid $color-03;
        padding: 15px 0 0;
        text-transform: uppercase;

        &.color--crimson {
            border-color: $color-02;
        }
    }

    &__name {
        margin-bottom: 0;
    }

    &__details {
        @extend %list;

        li {
            margin-bottom: 0;

            a {
                text-decoration: underline;

                &:hover {
                    color: $color-05;
                }
            }
        }
    }
}