.cols-2 {
	&__head {
		.col-sm-6:nth-child(2) & {
			@include respond-to(xs-max) {
				margin-top: 48px;
			}
		}
	}

	&__body {
		margin-top: 32px;

		.cols-2__head + & {
			margin-top: 48px;
		}

		a {
			color: $color-03;
			text-decoration: none;

			&:hover {
				color: $color-03;
				text-decoration: underline;
			}
		}
	}

	&__label {
		margin-bottom: 0;
	}
}