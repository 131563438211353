
$fc-track-height: 2px;
$fc-track-padding-right: 16px;

$fc-handle-border: 2px;
$fc-handle: 20px;
$fc-handle-active: $fc-handle + 4;
$fc-distance-width: 32px;


.fc {
    &__slider {
        @extend .cf;

        &-area,
        &-distance {
            float: left;
        }

        &-area {
            padding-right: 16px;
            width: calc(100% - #{$fc-distance-width});

            position: relative;

            @include respond-to(xs-max) {
                padding-left: $fc-handle-border;
                padding-right: $fc-handle-border;
            }
        }

        &-track {
            background-color: $color-09;
            height: $fc-track-height;
            margin: (($fc-input-height - $fc-track-height) / 2) 0;
            width: 100%;

            position: relative;
        }

        &-fill {
            background-color: $color-06;
            height: $fc-track-height;
        }

        &-handle {
            background-color: $color-01;
            border: $fc-handle-border solid $color-09;
            border-radius: 50%;
            height: $fc-handle;
            width: $fc-handle;

            position: absolute;
            left: 0;
            top: -(($fc-handle - $fc-handle-border) / 2);

            transition: background-color .35s, border-color .35s;

            touch-action: pan-y;
            -ms-touch-action: pan-y;

            .fc__slider-track--active & {
                background-color: $color-06;
                border-color: $color-06;
            }

            &:before {
                background-color: $color-06;
                border-radius: 50%;
                content: '';
                height: $fc-handle;
                opacity: 0;
                width: $fc-handle;

                position: absolute;
                z-index: 9;
                left: - $fc-handle-border;
                top: - $fc-handle-border;

                transition: opacity .25s, transform .25s;

                @include transform(scale(1));

                // .fc__slider-range:focus + .fc__slider-track &
                .fc__slider-track--focus & {
                    opacity: .25;

                    @include transform(scale(2.4));
                }
            }
        }

        &-distance {
            overflow: hidden;
            width: $fc-distance-width;

            position: relative;
        }

        &-label {
            position: absolute;
            left: -9999px;
        }

        &-range,
        &-input {
            @extend %fc-input;
            @extend %fc-text-padding;
            margin-top: 0;
            text-align: center;
        }

        &-input {
            &:disabled {
                opacity: 1;
                color: $color-03;
                -webkit-text-fill-color: $color-03;
            }
        }
    }
}
