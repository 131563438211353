// =============================================================================
// Component (for all 'drag n drop' components)
// =============================================================================

.component {
    margin-top: $vertical-gutter-mobile;

    @include respond-to(sm-min) { margin-top: $vertical-gutter-desktop; }

    &:first-child { margin-top: 0; }

    &--fullscreen,
    &--video {
        + .component {
            &--fullscreen,
            &--video {
                margin-top: 0;
            }

        }
    }

    &--video  {
        .component--wysiwyg + & {
            margin-top: $vertical-gutter-desktop - 5px; // NOTE: 5px is the line height bottom of text
        }

        // TODO: .component--list + &
    }

    &--wysiwyg {
        .component--video + & {
            margin-top: $vertical-gutter-desktop - 5px - 6px; // NOTE: 5px is the line height bottom of text - 6 line height top of text
        }

        .component--divider + & {
            margin-top: $vertical-gutter-desktop;
        }

        .component--button + & {
            @include respond-to(sm-min) {
                margin-top: $vertical-gutter-desktop - 10px;
            }
        }
    }

    &--step-status {
        .component--title + & {
            margin-top: $vertical-gutter-mobile + 5px;

            @include respond-to(sm-min) { margin-top: $gutter; }
        }
    }

    &--divider {
        .component--step-status + & {
            margin-top: $vertical-gutter-mobile + 5px;

            @include respond-to(sm-min) { margin-top: $vertical-gutter-desktop * 2; }
        }
    }

    &--pagination {
        .component--map + & {
            margin-top: 0;
        }
    }

    &--date {
        .component--title + & {
            @include respond-to(sm-min) {
                margin-top: $vertical-gutter-mobile;
            }

            @include respond-to(md-min) {
                margin-top: 0;
            }
        }
    }

    &--map {
        .component--band + & {
            margin-top: 0;
        }
    }

    &--global-nav,
    &--sibling-nav {
        margin-top: 0;
    }

    &--button {
        .component--form & {
            text-align: right;
        }
    }

    // Phase 2 items below:
    // ------------------------------------
    // &--breadcrumb {
    //     + .component--tags {
    //         margin-top: 56px;
    //     }
    // }

    // TODO: move under page-banner namespace
    &--title {
        .component--tags + & {
            margin-top: 24px;
        }
    }

    &--cols-2,
    &--title {
        .component--divider + & {
            margin-top: 48px;
        }
    }

    &--tile-carousel {
        .component--title + & {
            @include respond-to(lg-min) {
                margin-top: 40px;
            }
        }
    }

    // offsets:

    &--divider:first-child {
        // 2 cols: 33% (gets -ve margin only when col has background color) + 65%
        // OR
        // 2 cols: 65% + 33% (gets -ve margin only when col has background color)
        .col-sm-4:nth-child(1):nth-last-child(2)[class*='bg--'] &,
        .col-sm-4:nth-child(2):nth-last-child(1)[class*='bg--'] & {
            margin-left: -$gutter-xs;
            margin-right: -$gutter-xs;

            @include respond-to(sm-min) {
                margin-left: -$gutter-sm;
                margin-right: -$gutter-sm;
            }
        }
   }

    &--title,
    &--wysiwyg,
    &--list {

        // 2 cols: 33% (gets padding only when col has background color) + 65%
        // OR
        // 2 cols: 65% + 33% (gets padding only when col has background color)
        .col-sm-4:nth-child(1):nth-last-child(2)[class*='bg--'] &,
        .col-sm-4:nth-child(2):nth-last-child(1)[class*='bg--'] & {
            @include respond-to(sm-min) {
                padding-left: $gutter-sm;
                padding-right: $gutter-sm;
            }
        }
    }
}
