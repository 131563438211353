// =============================================================================
//  Typography - shared in authoring mode
// =============================================================================

// ------------------------------------
//  Link
// ------------------------------------
a {
    @extend %hover;
    color: $color-05;
    text-decoration: none;


    &:focus,
    &:hover {
        color: $color-15;
        text-decoration: underline;
        // outline: 0;
    }

    // &:visited {
    //     color: $color-02;
    //     text-decoration: none;
    // }
}

// ------------------------------------
//  Paragraph
// ------------------------------------
p {
    margin: 0 0 24px;
    padding: 0;

    @extend %typography-last;

    strong {
        @extend %font-primary-bold;
    }

    .lead,
    &.lead {
        @extend %font-primary-book;
        @include background-context-dark { color: $color-01; }

        font-size: $lead-mobile;
        line-height: $lead-mobile-leading;

        @include respond-to(md-min) {
            font-size: $lead-desktop;
            line-height: $lead-desktop-leading;
        }

        .bg--white & {
            color: $color-11;
        }
    }

    .small,
    &.small {
        @include background-context-dark { color: $color-01; }
        
        color: $color-11;
        font-size: $font-size-mobile;
        line-height: $font-leading-mobile;
    }

    .small {
        display: inline-block;
    }
}



// ------------------------------------
//  TODO: Fine print / small
// ------------------------------------