// =============================================================================
// Vertical Gutter (padding)
// Added to 'band' OR 'column controls'
// =============================================================================
//vertical gutter: vg prefix used for all vertical gutter variables

$vg-small-mobile: 16px;
$vg-small-desktop: 24px;

$vg-medium-mobile: 32px;
$vg-medium-desktop: 48px;

$vg-large-mobile: 48px;
$vg-large-desktop: 96px;

$vg-x-large-mobile: $vg-large-mobile;
$vg-x-large-desktop: 144px;

.vg {
    &--small {
        padding-top: $vg-small-mobile;
        padding-bottom: $vg-small-mobile;

        @include respond-to(sm-min) {
            padding-top: $vg-small-desktop;
            padding-bottom: $vg-small-desktop;
        }
    }

    &--medium {
        padding-top: $vg-medium-mobile;
        padding-bottom: $vg-medium-mobile;

        @include respond-to(sm-min) {
            padding-top: $vg-medium-desktop;
            padding-bottom: $vg-medium-desktop;
        }
    }

    &--large {
        padding-top: $vg-large-mobile;
        padding-bottom: $vg-large-mobile;

        @include respond-to(sm-min) {
            padding-top: $vg-large-desktop;
            padding-bottom: $vg-large-desktop;
        }
    }

    &--x-large {
        padding-top: $vg-x-large-mobile;
        padding-bottom: $vg-x-large-mobile;

        @include respond-to(sm-min) {
            padding-top: $vg-x-large-desktop;
            padding-bottom: $vg-x-large-desktop;
        }
    }

    &-top--small {
        padding-top: $vg-small-mobile;

        @include respond-to(sm-min) { padding-top: $vg-small-desktop; }
    }

    &-top--medium {
        padding-top: $vg-medium-mobile;

        @include respond-to(sm-min) { padding-top: $vg-medium-desktop; }
    }

    &-top--large {
        padding-top: $vg-large-mobile;

        @include respond-to(sm-min) { padding-top: $vg-large-desktop; }
    }

    &-top--x-large {
        padding-top: $vg-x-large-mobile;

        @include respond-to(sm-min) { padding-top: $vg-x-large-desktop; }
    }

    &-bottom--small {
        padding-bottom: $vg-small-mobile;

        @include respond-to(sm-min) { padding-bottom: $vg-small-desktop; }
    }

    &-bottom--medium {
        padding-bottom: $vg-medium-mobile;

        @include respond-to(sm-min) { padding-bottom: $vg-medium-desktop; }
    }

    &-bottom--large {
        padding-bottom: $vg-large-mobile;

        @include respond-to(sm-min) { padding-bottom: $vg-large-desktop; }
    }

    &-bottom--x-large {
        padding-bottom: $vg-x-large-mobile;

        @include respond-to(sm-min) { padding-bottom: $vg-x-large-desktop; }
    }

    &-clear--sm {
        @include respond-to(sm-min) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-clear--md {
        @include respond-to(md-min) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-clear--lg {
        @include respond-to(lg-min) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
