$tile-padding: 16px;
$tag-height: 34px;

.component--tile {
	padding: ($tile-padding / 2) 0;
	margin: 0;

	&.hidden {
		display: none;
	}
}

.tile {
	width: 100%;
	position: relative;

	&:hover {
		.tile__wrapper {
			top: -$tile-padding;
			bottom: -$tile-padding;
			left: -$tile-padding;
			right: -$tile-padding;	

			.tile__content{
				padding-right: $tile-padding * 2;
			}		
		}	

		.component--tags {
			margin-right: $tile-padding * 2;
		}

		button {
			top: (4 - $tile-padding);
			right: -$tile-padding;
		}
	}

	button {
		position: absolute;
		top: 4px;
		right: 0;
		width: 50px;
		height: 64px;
		padding: 0;
		background-color: transparent;
		@include transition((right .3s, top .3s));
		border: none;

        &:before {
            @extend %icon;    
            content: "\e906";   
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;    
            font-size: 24px;
            line-height: 64px;
            text-align: center;
            color: $color-03;         
        }

        &:hover {
        	&:before {
  				opacity: 0.6;
        	}
        }

		&.active {
        	&:before {
  				content: "\e92e";
        	}
		}
	}

    >a {
    	color: $color-03;
    	display: block;
		padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */

	    @include respond-to(xxs-max) {
			padding-bottom: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 5 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
	    }

	    @include respond-to(lg-min) {
			padding-bottom: 100%;
	    }

	    &:focus{
		    outline: 1px dotted;
		    outline: auto -webkit-focus-ring-color;
	    }
    }

	&__wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: $tile-padding;
		border-top: 4px solid $color-03;
		background-color: $color-01;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		@include transition((left .3s, right .3s, top .3s, bottom .3s));
	}

	&__info {
		position: absolute;
		bottom: $tile-padding;
		left: $tile-padding;
		right: $tile-padding;
    	max-height: $featured-mobile-leading * 4;
    	overflow: hidden;
    	margin: 0;

        @include respond-to(xxs-max) {
            max-height: $featured-mobile-leading * 5;
        }

        @include respond-to(xxl-min) {
            max-height: $featured-desktop-leading * 4;
        }
	}

	&__content {
		@include transition((padding .3s));		
	}

	&__text {
		padding-top: $base-leading;		
	}

	&__author {
		width: 100%;
		font-size: $font-size-mobile;
		line-height: $font-leading-mobile;
		padding-top: 24px;
	}

	.component--tags {
    	margin: 0;
		@include transition((margin .3s));

    	.tag-wrap {    		
    		.tag {
    			margin-bottom: 5px;

    			&:last-child {
    				margin-right: 0;
    			}
    		}
    	} 
    }

	h3 {
		font-size: $featured-mobile;
		line-height: $featured-mobile-leading;

		@include respond-to(xxl-min) {
			font-size: $featured-desktop;
			line-height: $featured-desktop-leading;
		}
	}

	&.tile__course {
    	.tag-wrap {    	
			padding-right: 48px;
		}
	}

	&.tile__standard {
		.tile__info {
	        @include respond-to(lg-min) {
	            max-height: $h2-mobile-leading * 6;
	        }

	        @include respond-to(xxl-min) {
	            max-height: $h2-desktop-leading * 6;
	        }
		}
	}

	&.tile__theme {
		.tile__wrapper {
			border-color: $color-06;

			&.tile__default {
				background-color: $color-02;
			}
		}

		.tile__info {
			border-top: 4px solid $color-03;
			background-color: #fff;
			bottom: $tile-padding * 2;
			left: $tile-padding * 2;
			right: 0;
    		height: $featured-mobile-leading * 3 + $tile-padding * 2; /* 3 lines of title + padding top, bottom */

	        @include respond-to(xxl-min) {
	            height: $featured-desktop-leading * 3 + $tile-padding * 2; /* 3 lines of title + padding top, bottom */
	        }
		}

        .tile__content {
        	position: absolute;
        	left: $tile-padding;
        	right: $tile-padding;
        	bottom: $tile-padding;
        	max-height: $featured-mobile-leading * 3;
        	overflow: hidden;

	        @include respond-to(xxl-min) {
	            max-height: $featured-desktop-leading * 3;
	        }
        }

        .tile__text {
	        @include respond-to(sm-max) {
	            display: none;
	        }        	
        }

		&.tile__2x1 {
			.tile__wrapper {
				&:hover {
					.tile__content{
				    	@include respond-to(sm-min) {
							padding-right: 0;
						}
					}		
				}
			}

			.tile__info {
		    	@include respond-to(sm-min) {
					width: 450px;
					left: auto;
				}

		    	@include respond-to(xxl-min) {
					width: 600px;
				}
			}			
		}

		&.tile__2x2 {
			.tile__wrapper {
				&:hover {
					.tile__content{
				    	@include respond-to(sm-min) {
							padding-right: 0;
						}
					}		
				}
			}

			.tile__info {
		    	@include respond-to(sm-min) {
					width: 450px;
					left: auto;
				}

		    	@include respond-to(md-min) {
	        		height: $h2-desktop-leading * 2 + $base-leading * 3 + $tile-padding * 4; /* 2 lines of title + 3 lines of copy + padding top x2, bottom x2 */
	        		max-height: $h2-desktop-leading * 2 + $base-leading * 3 + $tile-padding * 4; /* 2 lines of title + 3 lines of copy + padding top x2, bottom x2 */
	        	}

		    	@include respond-to(lg-min) {
	        		height: $col-lg-1 * 3 - $gutter-sm * 2 - $tile-padding * 2;
	        		max-height: $col-lg-1 * 3 - $gutter-sm * 2 - $tile-padding * 2;
				}

		    	@include respond-to(xxl-min) {
	        		height: $col-xxl-1 * 3 - $gutter-sm * 2 - $tile-padding * 2;
	        		max-height: $col-xxl-1 * 3 - $gutter-sm * 2 - $tile-padding * 2;
				}

				h3 {
	    			@include respond-to(md-min) {
						font-size: $h2-desktop;
						line-height: $h2-desktop-leading;
						overflow: hidden;
					}

	    			@include respond-to(xxl-min) {
						font-size: $h1-desktop;
						line-height: $h1-desktop-leading;
					}
				}
			}

	        .tile__content {
		    	@include respond-to(md-min) {
	        		left: $tile-padding * 2;
	        		right: $tile-padding * 2;
	        		bottom: $tile-padding * 2;
	        	}

		    	@include respond-to(md-min) {
	        		max-height: $h2-desktop-leading * 2 + $base-leading * 4;
	        	}

		    	@include respond-to(xxl-min) {
	        		max-height: $h1-desktop-leading * 2 + $base-leading * 4;
	        	}
	        }
		}
	}

	&.tile__featured {
    	&.undergraduate {
    		.tile__wrapper {
    			background-color: $color-02;
    		}
    	}

    	&.postgraduate {
    		.tile__wrapper {
    			background-color: $color-14;
    		}
    	}

    	&.research {
    		.tile__wrapper {
    			background-color: $color-13;
    		}
    	}

		.tile__wrapper {
			&:hover {
				.tile__content{
			    	@include respond-to(sm-min) {
						padding-right: 0;
					}
				}		
			}
		}

    	.tag {
    		background-color: $color-01;
    		color: $color-03;
    		font-weight: bold;
    	}

		.tile__info {
    		color: $color-01;

	    	@include respond-to(sm-min) {
				max-width: 450px;
			}

	    	@include respond-to(xxl-min) {
				max-width: 600px;
			}
		}	

    	a {
			.tile__info {
    			color: $color-01;
			}
    	}	

    	button {
    		&:before {
    			color: $color-01;
    		}
    	}	
	}

	&.tile__quote {
		padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */

	    @include respond-to(xxs-max) {
			padding-bottom: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
	    }

	    @include respond-to(lg-min) {
			padding-bottom: calc(50% - 8px);
	    }

    	.tile__wrapper {
    		border: none;
    		background-color: transparent;
			display: flex;
			align-items: center;
		    flex-direction: column;
    		justify-content: center;

	    	@include respond-to(sm-min) {
	    		padding-left: 40px;
	    		padding-right: 40px;
	    	}

			&:hover {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;	

				.tile__content{
					padding-right: 0;
				}		
			}	
    	}

		.tile__info {
			position: relative;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			max-height: inherit;
			width: 100%;
		}	
	}

	&.tile__split {
		a {
			padding-top: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
			padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */

		    @include respond-to(xxs-max) {
				padding-top: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
				padding-bottom: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
		    }

		    @include respond-to(sm-min) {
				padding-top: 0;
				padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
		    }

		    @include respond-to(lg-min) {
				padding-top: 0;
				padding-bottom: calc(50% - 8px);
		    }			
		}

	    .tile__wrapper {
			background-color: $color-01;

			&:hover {
				.tile__content{
					padding-right: $tile-padding;
				}		
			}
	    }

	    .tile__image,
	    .tile__default {
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	right: 0;
	    	bottom: ($featured-mobile-leading * 4 + $tile-padding * 2 + 24);
			padding: $tile-padding;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

		    @include respond-to(xxs-max) {
				bottom: ($featured-mobile-leading * 5 + $tile-padding * 2 + 24);
		    }

		    @include respond-to(sm-min) {
				bottom: 0;
				right: 50%;
		    }
	    }

	    .tile__default {
	    	background-color: $color-02;
	    }

		.tile__info {
	    	@include respond-to(sm-min) {
				margin-left: 50%;
			}
		}

		.component--tags {
			position: absolute;
			top: $tile-padding;
			left: $tile-padding;

	    	@include respond-to(sm-min) {
				margin-left: 50%;
				margin-right: $tile-padding;
			}
		}
	}

	&.tile__cta {
		padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */

	    @include respond-to(xxs-max) {
			padding-bottom: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
	    }

	    @include respond-to(lg-min) {
			padding-bottom: 100%;
	    }

    	.tile__wrapper {
    		border: none;
    		background-color: transparent;

			&:hover {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;	

				.tile__content{
					padding-right: 0;
				}		
			}	
    	}

		.tile__info {
	    	max-height: $h3-mobile-leading * 4 + 72;

	        @include respond-to(xxs-max) {
	            max-height: $h3-mobile-leading * 5 + 72;
	        }

	        @include respond-to(xxl-min) {
	            max-height: $h3-desktop-leading * 4 + 72;
	        }
		}	

		h3 {
			font-size: $h3-mobile;
			line-height: $h3-mobile-leading;

			@include respond-to(md-min) {
				font-size: $h3-desktop;
				line-height: $h3-desktop-leading;
			}

			@include respond-to(xxl-min) {
				font-size: $h3-desktop;
				line-height: $h3-desktop-leading;
			}
		}
	}

	&.tile__intro {
		padding-bottom: ($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4); /* 4 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */

	    @include respond-to(xxs-max) {
			padding-bottom: ($featured-mobile-leading * 5 + $tile-padding * 3 + $tag-height * 2 + 4); /* 5 lines of title + padding top, bottom, in between tile and text + 2 tags + top border */
	    }

	    @include respond-to(lg-min) {
			padding-bottom: calc(50% - 8px);
	    }

    	.tile__wrapper {
    		border: none;
    		background-color: transparent;
			display: flex;
			align-items: center;
		    flex-direction: column;
    		justify-content: center;

			&:hover {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;	

				.tile__content{
					padding-right: 0;
				}		
			}	    	
    	}

		.tile__info {
			position: relative;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			max-height: inherit;
			width: 100%;
		}	
	}

	&.tile__1x1 {
		width: 100%;

	    @include respond-to(sm-min) {
			width: $col-sm-1 * 6 - $gutter-sm * 2;
	    }

	    @include respond-to(md-min) {
			width: $col-md-1 * 6 - $gutter-sm * 2;
	    }

	    @include respond-to(lg-min) {
			width: $col-lg-1 * 3 - $gutter-sm * 2;
	    }

	    @include respond-to(xxl-min) {
			width: $col-xxl-1 * 3 - $gutter-sm * 2;
	    }
	}

	&.tile__2x1 {
		width: 100%;

	    @include respond-to(sm-min) {
			width: $col-sm-1 * 12 - $gutter-sm * 2;
	    }

	    @include respond-to(md-min) {
			width: $col-md-1 * 12 - $gutter-sm * 2;
	    }

	    @include respond-to(lg-min) {
			width: $col-lg-1 * 6 - $gutter-sm * 2;
	    }

	    @include respond-to(xxl-min) {
			width: $col-xxl-1 * 6 - $gutter-sm * 2;
	    }

		a {
		    @include respond-to(lg-min) {
				padding: 0 0 calc(50% - 8px) 0;
			}
		}
	}

	&.tile__2x2 {
		width: 100%;

	    @include respond-to(sm-min) {
			width: $col-sm-1 * 12 - $gutter-sm * 2;
	    }

	    @include respond-to(md-min) {
			width: $col-md-1 * 12 - $gutter-sm * 2;
	    }

	    @include respond-to(lg-min) {
			width: $col-lg-1 * 6 - $gutter-sm * 2;
	    }

	    @include respond-to(xxl-min) {
			width: $col-xxl-1 * 6 - $gutter-sm * 2;
	    }

		a {
			padding-bottom: (($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4) * 2);

		    @include respond-to(xxs-max) {
				padding-bottom: (($featured-mobile-leading * 4 + $tile-padding * 3 + $tag-height * 2 + 4) * 2);
		    }

		    @include respond-to(lg-min) {
				padding-bottom: 100%;
		    }
		}
	}
}