// Search
$search-mobile: 58px;
$search-desktop: 108px;

.template--search-results-page {
	.filter-tabs {                                        
        @include respond-to(xs-max) {
		    background-color: $color-01;
		    margin-top: -48px;
        }
	}
}

.search {
	&__form {
		font-family: $font-family-secondary;
		position: relative;
	}

	&__input {
	    &:after {
	        background-color: $color-01;
	        content: '';
	        height: 4px;
	        width: 0;
	        z-index: 10;

	        position: absolute;
	        bottom: 0;
	        left: 0;

	        @include transition(width .3s);

	        .search__form-inverse & {
	        	background-color: $color-03;
	        }
	    }

	    &.active {
	        &:after {
	            width: 100%;
	        }
	    }

		input {
		    @extend %clear-default-appearance;
			background-color: transparent;
			color: $color-01;
		    border: 0;
		    border-bottom: 4px solid $color-11;
		    border-radius: 0;
		    width: 100%;
		    position: relative;
		    z-index: 9;
		    font-size: $h1-mobile;
		    line-height: $search-mobile - 4;
		    height: $search-mobile;
		    padding: 0 40px 0 0;

	        @include respond-to(md-min) {
			    font-size: 70px;
			    line-height: $search-desktop - 4;
			    height: $search-desktop;
		    	padding: 0 60px 0 0;
	        }

		    // Chrome/Opera/Safari
		    &::-webkit-input-placeholder {
		        color: $color-11;
		    }

		    // Firefox 19+
		    &::-moz-placeholder {
		        color: $color-11;
		    }

		    // IE 10+
		    &:-ms-input-placeholder {
		        color: $color-11;
		    }

		    // Firefox 18-
		    &:-moz-placeholder {
		        color: $color-11;
		    }

		    &::-ms-clear {
			    display: none;
			    width: 0; 
			    height: 0;
			}

		    &:focus {
		        outline: 0;
		    }

		    .search-white & {
		    	border-color: $color-01;

		    	// Chrome/Opera/Safari
                &::-webkit-input-placeholder {
                    color: $color-01;
                }

                // Firefox 19+
                &::-moz-placeholder {
                    color: $color-01;
                }

                // IE 10+
                &:-ms-input-placeholder {
                    color: $color-01;
                }

                // Firefox 18-
                &:-moz-placeholder {
                    color: $color-01;
                }
		    }

		    .search-small & {
			    font-size: 24px;
		        
		        @include respond-to(md-min) {
				    font-size: 48px;
				    line-height: 88px;
				    height: 92px;
				}
		    }

	        .search__form-inverse & {
	        	color: $color-03;
		    	border-color: $color-03;
	        }
		}
	}

	&__submit {
	    @extend %clear-default-appearance;
		background-color: transparent;
		color: $color-01;
		width: 40px;
		font-size: 24px;
	    height: $search-mobile;
	    line-height: $search-mobile;
	    border: none;
	    position: absolute;
	    top: 0;
	    right: 0;
        text-align: right;
        z-index: 10;

        @include respond-to(md-min) {
			font-size: 48px;
			width: 48px;
		    height: $search-desktop;
		    line-height: $search-desktop;
        }

        &:before {
            @extend %icon;   
            content: "\e924";   
            position: absolute;
            top: 0;
            bottom: 0;
            right: -3px;
            left: 0;            
        }

	    .search-small & {
	        @include respond-to(md-min) {
			    line-height: 92px;
			    height: 92px;
			}
	    }

        .search__form-inverse & {
        	color: $color-03;
        }
	}

	&__predictive-list {
		position: absolute;
	    top: $search-mobile;
		left: 0;
		right: 0;

        @include respond-to(md-min) {
			top: $search-desktop;
		}

        .search__form-inverse & {
        	background-color: $color-01;
        	z-index: 99;

        	li {
        		a {
        			color: $color-03;
	            	padding: 8px 16px;

			        &:hover,
			        &:focus {
				        @include respond-to(sm-min) {
				        	background-color: rgba($color-03, 0.25);
				        }
			        }
        		}
        	}
        }

		ul {
			padding: 16px 0;
			margin: 0;
			list-style-type: none;

	        @include respond-to(md-min) {
				padding: 24px 0;
			}
		}

		li {
			a {
	            font-size: $h3-mobile;
	            line-height: $h3-mobile-leading;
	            font-style: italic;
	            color: $color-01;
	            padding: 8px 0;
	            text-decoration: none;
	            display: block;

		        @include respond-to(md-min) {
		            font-size: $h2-desktop;
		            line-height: $h2-desktop-leading;
	            	padding: 8px 16px;
		        }

		        &:hover,
		        &:focus {
			        @include respond-to(sm-min) {
			        	background-color: rgba($color-01, 0.25);
			        	outline: none;
			        }
		        }

		        span {
		        	font-style: normal;
		        	font-weight: bold;
		        }
			}
		}
	}
}

.search-page {
	&__title {
		padding: 0 0 16px 0;

        @include respond-to(sm-min) {
			padding: 8px 16px 0 0;
			float: left;
        }
	}

	&__collections {
		padding: 8px 0 24px 0;

        @include respond-to(sm-min) {
			float: left;
        }
	}

	&__input {
        @include respond-to(sm-min) {
			float: left;
			width: 100%;
        }

	}

	&__no-results {
        padding-top: 40px;
        display: none;

        @include respond-to(sm-min) {
			float: left;
			width: 100%;
        }		

        .text {
        	padding-top: 24px;
        }
	}

	&__facets {  
        @include respond-to(sm-max) {
			margin: -($gutter-xs) (-$gutter-xs) 0 (-$gutter-xs);
			background-color: $color-01;
			padding: 0 $gutter-xs;
        }    

        @include respond-to(sm-min) {
			margin: 0 0 (-$gutter-xs) 0;
        }

        @include respond-to(md-min) {
			margin: 0;
        }

        .empty & {
        	display: none;
        }

		&-toggle {
			width: 100%;
			text-align: right;

	        @include respond-to(md-min) {
				display: none;
	        }

			a {
				font-size: $h6-desktop;
			    line-height: 40px;
			    letter-spacing: 1px;
			    text-transform: uppercase;
			    font-weight: bold;
				display: inline-block;
				color: $color-03;
				position: relative;
				padding: 10px 32px 10px 0; 
				text-decoration: none;
				text-align: right;

				span {
					position: absolute;
					top: 10px;
					right: 0;
				    font-size: 24px;
					line-height: 40px;
				}
			}
		}
	}

	&__featured {
		padding-top: 40px;

        @include respond-to(sm-max) {
			display: none;
        }

        .tile {

        	&.tile__1x1 {                                               
		        @include respond-to(md-max) {
					width: 100%;
		        }
        	}   
        }
	}
}

.facets {
	padding-bottom: $gutter-xs;

    @include respond-to(sm-max) {
		display: none;
    }

    @include respond-to(md-min) {
		padding-top: 94px;
    }

    &.active {
        @include respond-to(sm-max) {
			display: block;
        }
    }

	&__group {
		border-bottom: 1px solid $color-09;
		
		@include respond-to(md-min) {
			border-bottom: none;
			padding-bottom: 32px;
		}

		&:first-child {
			border-top: 1px solid $color-09;
		}
	}

	&__title {
		position: relative;

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            @include transition(opacity .3s, transform .3s);
            background-color: $color-03;
            @include transform(rotate(0deg));

			@include respond-to(md-min) {
				display: none;
			}
        }

        &:before {
            height: 10px;
            margin-left: -1px;
            margin-top: -5px;
            width: 2px;
            right: 4px;

            .toggle-open & {
                @include transform(rotate(90deg));
            }
        }

        &:after {
            height: 2px;
            margin-left: -5px;
            margin-top: -1px;
            width: 10px;
            right: 0;

            .toggle-open & {
                opacity: 0;
                @include transform(rotate(90deg));
            }
        }
		
		&-text {
			font-size: $h4-mobile;
			line-height: $h4-mobile-leading;
			padding: 14px 0;

			@include respond-to(md-min) {
				padding-top: 24px;
				font-size: $h3-desktop;
				line-height: $h3-desktop-leading;
			}
		}

		&-toggle {
			background-color: transparent;
			border: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 0;
			display: block;

	        @include respond-to(md-min) {
				display: none;
	        }
		}
	}

	&__items {
        @include respond-to(sm-max) {
			display: none;
        }

        .open & {
	        @include respond-to(sm-max) {
				display: block;
	        }
        }

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				list-style-type: none;
				padding: 0;
				margin: 0;

				&.active {
					a {
						font-weight: bold;
						color: $color-03;
						position: relative;

				        &:after,
				        &:before {
				            content: '';
				            display: block;
				            position: absolute;
				            top: 50%;
				            background-color: $color-03;
				            @include transform(rotate(45deg));
				        }

				        &:before {
				            height: 10px;
				            margin-left: -1px;
				            margin-top: -5px;
				            width: 2px;
				            right: 4px;
				        }

				        &:after {
				            height: 2px;
				            margin-left: -5px;
				            margin-top: -1px;
				            width: 10px;
				            right: 0;
				        }

					}
				}

				a {
					display: block;
					color: $color-11;
					font-size: $h4-mobile;
					line-height: $h4-mobile-leading;
					padding: 14px 0 14px 24px;

    				@include respond-to(md-min) {
						padding-left: 0;
					}

					&:hover,
					&:focus {
						text-decoration: none;

        				@include respond-to(sm-min) {
							color: $color-02;
						}
					}
				}				
			}
		}
	}
}