// =============================================================================
// Tags
// =============================================================================
// $tag-padding: 3px;

$fs-tag-height: 32px; // NOTE: fs = future students

.component--tags {
    .template--news-detail .col-sm-9 & {
        @include respond-to(md-min) {
            margin-right: $col-md-1;
        }

        @include respond-to(lg-min) {
            margin-right: $col-lg-1;
        }
    }
}

.tag {
    border-radius: 2px;
    display: inline-block;
    text-transform: uppercase;
    background-color: $color-03;
    border: 1px solid $color-03;
    color: $color-01;

    .pb--image .tag-wrap &,
    .cc .tag-wrap &,
    .tile .tag-wrap &,
    .tile-carousel .tag-wrap &,
    .default .tag-wrap &,
    .undergraduate .tag-wrap &,
    .postgraduate .tag-wrap &,
    .research .tag-wrap &,
    .college .tag-wrap & {
        @extend %font-primary-medium;
        border-radius: 0;
        font-size: $font-size-fine;
        line-height: $font-leading-mobile - 2;
        letter-spacing: 1px;
        padding: 8px;
    }

    .college &,
    .undergraduate & {
        background-color: $color-02;
        border-color: $color-02;
    }

    .postgraduate & {
        background-color: $color-14;
        border-color: $color-14;
    }

    .research & {
        background-color: $color-13;
        border-color: $color-13;
    }

    &:nth-child(2) {
        background-color: rgba($color-04, .8);
        border: 1px solid $color-08;
        color: $color-03;
    }

    .tag-wrap & {
        font-size: $font-size-mobile;
        line-height: $lead-mobile;
        margin-right: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.tag-wrap {
    font-size: 0;
}