// =============================================================================
// Color modifiers
// =============================================================================
.color {
    &--white { color: $color-01; }
    &--crimson { color: $color-02; }
    &--black { color: $color-03; }

    &--red { color: $color-05; }
    &--orange { color: $color-06; }
    &--orange-2 { color: $color-07; }

    &--light-grey-2 { color: $color-09; }

    &--mid-grey-2 { color: $color-11; }
}