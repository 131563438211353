$footer-icon: 56px;
$footer-link-item-height: 48px;

%footer-list {
    font-size: 0;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: (($footer-link-item-height - $base-leading) / 2) ($gutter * 2) (($footer-link-item-height - $base-leading) / 2) 0;
        vertical-align: top;

        a {
            @extend %reset-font;
            display: block;
            color: $color-01;
        }
    }
}

// TODO: make footer 'stick' to bottom of page if less content?
.component--footer {
    @extend %cf-dashboard-nav;
    margin-top: 0;

}

.footer {
    @extend %has-dashboard-nav;
    color: $color-01;
    background-color: $color-03;
    padding: 32px 0;
    width: 100%;

    @include respond-to(md-min) {
        padding: 48px 0;
    }

    [class^='col-'] {
        &:not([class^='col-xs-']):not(:first-child) {
            @include respond-to(sm-max) {
                margin-top: 32px;
            }
        }
    }

    &__top {
        // overflow: hidden; // TODO: check if needed

        // @include respond-to(xs-max) {
        //     font-size: $font-size-fine;
        // }
    }

    &__bottom {
        padding-top: 60px;

        // @include respond-to(sm-max) { // TODO: move to .footer?
        //     text-align: center;
        // }

        @include respond-to(md-min) {
            padding-top: 24px;
        }
    }

    &__title {
        margin: 0 0 24px;

        @include respond-to(sm-max) {
            padding-top: 32px;
        }

        &--ghost {
            @include respond-to(md-min) {
                opacity: 0;
            }

            @include respond-to(sm-max) {
                display: none;
            }
        }
    }

    &__text {
        font-size: $font-size-fine;
        line-height: $font-leading-mobile;
    }

    &__links {

        &-list {
            @extend %footer-list;

            li {
                width: 50%;
            }
        }
    }

    &__contact {
        &-list {
            @extend %footer-list;

            li {
                width: 100%;

                @include respond-to(xs-max) {
                    padding: (($footer-link-item-height - $base-leading) / 2) 0;
                }

                a {
                    &:before {
                        font-size: 18px;
                    }
                }
            }
        }

        &-links {
            @extend %has-icon;

            color: $color-03;
            display: block;
        }

        &-address {
            @extend %has-icon;
            font-style: normal;
            padding-top: (($footer-link-item-height * 2) - ($base-leading * 3)) / 2;

            &:before {
                font-size: 18px;
            }
        }
    }

    &__social {

        &-list {
            font-size: 0;
            line-height: 0;
            margin: 0 -4px;
            padding: 0;

            // @include respond-to(sm-max) { text-align: center; }

            li {
                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 4px;

                a {
                    background-color: $color-01;
                    display: block;
                    height: $footer-icon;
                    width: $footer-icon;

                    position: relative;

                    @include transition(opacity .25s linear);

                    &:hover,
                    &:focus {
                        &:after {
                            width: 100%;
                        }
                    }

                    &:after {
                        background-color: $color-06;
                        content: '';
                        height: 100%;
                        width: 0;

                        position: absolute;
                        z-index: 6;
                        top: 0;
                        left: 0;

                        transition: width .3s;
                    }

                    img {
                        width: 40px;

                        position: absolute;
                        z-index: 7;
                        top: 50%;
                        left: 50%;

                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

}

.image-links {
    font-size: 0;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 16px 16px 0 0;
        text-align: left;
        width: 100%;

        @include respond-to(sm-min) {
            padding: 0 16px 0 0;
            width: 50%;
        }

        @include respond-to(xxl-min) {
            padding-right: $col-xxl-1 + $gutter;
        }

        a {
            color: $color-01;
        }
    }

    &__img,
    &__txt {
        display: inline-block !important;
        vertical-align: middle;
    }

    &__img {
        // padding-top: 5px; // NOTE: to align the image to the top of the text
        width: 35px;
    }

    &__txt {
        font-size: $font-size-fine;
        line-height: $font-leading-mobile;
        padding-left: 10px;
        width: calc(100% - 35px);

        @include respond-to(sm-min) {
            font-size: $base-font-size;
            line-height: $base-leading;
        }
    }
}

// NOTE: move this to grid?
.has-divider {
    overflow: visible;

    > [class^='col-'] {
        @include respond-to(sm-max) {
            // padding-top: $vg-medium-mobile;
            // padding-bottom: $vg-medium-mobile;

            position: relative;
        }

        &:not(:first-child) {
            &:before {
                @include respond-to(sm-max) {
                    background-color: $color-11;
                    content: '';
                    height: 1px;

                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                }

                @include respond-to(xs-max) {
                    right: 16px;
                    left: 16px;
                }
            }
    	}
    }
}
