.filter {
    &-tabs {
        border-bottom: 1px solid $color-09;
        margin: 0 -16px 16px -16px;
        position: relative;
        @extend %clear;

        @include respond-to(sm-min) {
            margin: 0 0 36px 0;
        }

        &__wrapper {
            position: relative;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            white-space: nowrap;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__content {
            float: left;
            transition: transform .2s ease-in-out;

            @include respond-to(xs-max) {
                padding: 0 ($gutter-xs / 2);
            }

            &-no-transition {
                transition: none;
            }
        }

        &__button {
            position: absolute;
            top: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
            appearance: none;
            background-color: $color-04;
            padding: 0;
            border: 0;
            font-size: 24px;
            width: 32px;

            @include respond-to(sm-min) {
                width: 54px;
            }

            &:before {
                @include respond-to(xs-max) {
                    display: none;
                }
            }

            &:hover {
                cursor: pointer;
            }

            &-left {
                left: 0;
                background: -webkit-linear-gradient(right, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: -o-linear-gradient(left, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: -moz-linear-gradient(left, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: linear-gradient(to left, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);

                @include respond-to(sm-min) {
                    background: -webkit-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: -o-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: -moz-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: linear-gradient(to left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                }

                [data-overflowing="both"] ~ &,
                [data-overflowing="left"] ~ & {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-right {
                right: 0;
                background: -webkit-linear-gradient(left, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: -o-linear-gradient(right, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: -moz-linear-gradient(right, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);
                background: linear-gradient(to right, rgba($color-01,0.6) 0%, rgba($color-01,0.9) 100%);

                @include respond-to(sm-min) {
                    background: -webkit-linear-gradient(left, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: -o-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: -moz-linear-gradient(right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                    background: linear-gradient(to right, rgba($color-04,0) 0%, rgba($color-04,1) 25%);
                }

                [data-overflowing="both"]  ~ &,
                [data-overflowing="right"] ~ & {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .filter {
            display: inline-flex;
            align-items: center;
            min-height: $h6-desktop-leading;

            a {
                font-size: $h6-desktop;
                line-height: $h6-desktop-leading;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
                display: block;
                float: left;
                padding: 17px ($gutter-xs / 2) 21px ($gutter-xs / 2);   
                position: relative;
                color: $color-11;
                text-decoration: none;

                @include respond-to(sm-min) {
                    padding: 17px 24px 21px 24px;   
                }

                &:hover {
                    color: $color-03;
                }
            }

            &-active {
                a {
                    color: $color-03;

                    &:before {
                        content: '';
                        position: absolute;
                        left: ($gutter-xs / 2);
                        right: ($gutter-xs / 2);
                        bottom: 0;
                        box-sizing: border-box;
                        height: 2px;
                        background-color: $color-06;

                        @include respond-to(sm-min) {
                            left: 24px;
                            right: 24px;
                        }
                    }
                }
            }
        }
    }

    &-list {
        @extend %clear;
        display: none;

        &.active {
            display: block;
        }
    }
}