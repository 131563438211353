// =============================================================================
// Hero (Page hero)
// Used:
//     * News detail
//     * Event detail
//     * Article?
// =============================================================================

// .component--hero {
//
// }

.hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // padding-top: 65.625%;

    position: relative;

    // max-height: calc(100vh - 200px);
    overflow: hidden;

    // @include respond-to(sm-min) {
    //     height: 480px;
    //     padding-top: 0;
    // }

    // NOTE: change requested from client
    height: 172px; // NOTE: computed height of dashboard nav header

    // .template--guide & {
    //     height: 172px; // NOTE: computed height of dashboard nav header
    //     padding-top: 0;
    // }


    // &--image {
    //
    // }

    &__content {
        display: none;

        .template--guide & {
            display: block;
        }
    }

    &__title,
    &__steps {
        color: $color-01;
        font-size: $font-size-mobile;
        line-height: $h3-desktop-leading;
        padding-bottom: $gutter + 5px;

        position: relative;
    }

    &__title {
        text-transform: uppercase;
    }

    &--tinted {
        &:before {
            background: rgba($color-03, .5);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            .template--guide &,
            .template--event-detail & {
                background: rgba($color-02, .8);
            }
        }
    }


}
